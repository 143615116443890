import { React, useEffect, useState } from "react";
import "./Account.css";
import config from "../../../config";
import ErrorPanel from "../../../components/utils/ErrorPanel";

const Account = () => {
  const [accessToken, setAccessToken] = useState("");
  const [changeAccessTokenSuccess, setChangeAccessTokenSuccess] =
    useState(false);
  const [accessTokenError, setAccessTokenError] = useState(false);
  const [accessTokenErrorText, setAccessTokenErrorText] = useState("");

  const getAccessToken = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/payment/getAccessToken.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        setAccessToken(data.value);
      } else {
        setAccessTokenErrorText("Erro ao obter token de acesso");
        setAccessTokenError(true);
        setTimeout(() => {
          setAccessTokenError(false);
        }, 2000);
      }
    } catch (error) {
      setAccessTokenErrorText("Erro ao obter token de acesso");
      setAccessTokenError(true);
      setTimeout(() => {
        setAccessTokenError(false);
      }, 2000);
      console.error(error);
    }
  };

  const updateAccessToken = async (newAccessToken) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("access_token", newAccessToken);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/payment/setAccessToken.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        setAccessToken(newAccessToken);
        setChangeAccessTokenSuccess(true);
        setTimeout(() => {
          setChangeAccessTokenSuccess(false);
        }, 2000);
      } else {
        setAccessTokenErrorText("Erro ao alterar token de acesso");
        setAccessTokenError(true);
        setTimeout(() => {
          setAccessTokenError(false);
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [pixelId, setPixelId] = useState("");
  const [changePixelIdSuccess, setChangePixelIdSuccess] = useState(false);
  const [pixelIdError, setPixelIdError] = useState(false);
  const [pixelIdErrorText, setPixelIdErrorText] = useState("");

  const getPixelId = async () => {
    try {
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/home/utils/getPixelId.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        setPixelId(data.id);
      } else {
        setPixelIdErrorText("Erro ao obter pixel");
        setPixelIdError(true);
        setTimeout(() => {
          setPixelIdError(false);
        }, 4000);
      }
    } catch (error) {
      setPixelIdErrorText("Erro ao obter pixel");
      setPixelIdError(true);
      setTimeout(() => {
        setPixelIdError(false);
      }, 4000);
      console.error(error);
    }
  };

  const updatePixelId = async (newPixelId) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("pixel_id", newPixelId);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/setPixelId.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        setPixelId(newPixelId);
        setChangePixelIdSuccess(true);
        setTimeout(() => {
          setChangePixelIdSuccess(false);
        }, 4000);
      } else {
        setPixelIdErrorText("Erro ao alterar pixel");
        setPixelIdError(true);
        setTimeout(() => {
          setPixelIdError(false);
        }, 4000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    await updateAccessToken(accessToken);
    await updatePixelId(pixelId);
  };

  useEffect(() => {
    getAccessToken();
    getPixelId();
  }, []);

  return (
    <div className="main-account">
      {changePixelIdSuccess && (
        <ErrorPanel
          errorText={"Pixel alterado com sucesso!"}
          backgroundColor={"#4caf08"}
        />
      )}

      {changeAccessTokenSuccess && (
        <ErrorPanel
          errorText={"Token de acesso alterado com sucesso!"}
          backgroundColor={"#4caf08"}
        />
      )}

      {accessTokenError && (
        <ErrorPanel errorText={accessTokenErrorText} backgroundColor={"red"} />
      )}

      {pixelIdError && (
        <ErrorPanel errorText={pixelIdErrorText} backgroundColor={"red"} />
      )}
      <div className="title_text_main_account">
        <h1>Conta</h1>
      </div>
      <div className="main_container_account">
        <div className="sub_container_1_account">
          <label>Token de acesso do mercado pago</label>
          <input
            value={accessToken}
            onChange={(e) => setAccessToken(e.target.value)}
          ></input>
        </div>
        <div className="sub_container_2_account">
          <label>Token de acesso do pixel do facebook</label>
          <input
            value={pixelId}
            onChange={(e) => setPixelId(e.target.value)}
          ></input>
        </div>
        <div className="save_button_container">
          <button onClick={() => handleSave()}>salvar</button>
        </div>
      </div>
    </div>
  );
};

export default Account;
