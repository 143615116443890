import React, { useState, useEffect, useRef } from "react";
import { format, isValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import config from "../config";
import copy from "clipboard-copy";
import { formatNumber, formatNumberThousands } from "./utils/Utils";
import { MdSettingsEthernet } from "react-icons/md";

const CampaignDetails = ({
  campaign,
  onEdit,
  onDelete,
  onRedirect,
  imageLoading,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef(null);

  const handlePopupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    if (popupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [popupOpen]);

  const navigate = useNavigate();

  const handleRedirect = () => {
    const linkToCopy = `${config.backendDomain}/?id=${campaign.id}`;

    copy(linkToCopy)
      .then(() => {
        alert("Link copiado para a área de transferência");
        onRedirect(campaign.id);
      })
      .catch((err) => console.error("Erro ao copiar link: ", err));
  };

  const [maxTitleLength, setMaxTitleLength] = useState(50); // Initial max length, change as needed

  useEffect(() => {
    const handleResize = () => {
      // Get parent div width
      const parentWidth = document
        .querySelector(".sub-container-1-div-1-left")
        .getBoundingClientRect().width;

      // Set max title length based on parent width
      setMaxTitleLength(Math.floor(parentWidth / 9)); // Adjust the divisor (10) based on your font size and style
    };

    // Chamando o manipulador de redimensionamento para calcular o tamanho inicial
    handleResize();

    // Adicionando o listener de redimensionamento à janela
    window.addEventListener("resize", handleResize);

    // Removendo o listener de redimensionamento quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // a array vazia de dependências garante que o efeito seja chamado apenas uma vez após a montagem inicial

  // Função para formatar a data no formato brasileiro
  const formatBrazilianDate = (date) => {
    const parsedDate = new Date(date);

    // Verifica se a data é válida antes de formatá-la
    if (isValid(parsedDate)) {
      return format(parsedDate, "dd/MM/yyyy");
    } else {
      return "Indefinida";
    }
  };

  // Função para limitar o título
  const limitTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.slice(0, maxLength) + "...";
    }
    return title;
  };

  return (
    <div className="campaign-item-main">
      <div className="campaign-item">
        <div className="img-container">
          {popupOpen && (
            <div className="popup-other-actions" ref={popupRef}>
              <button
                className="edit-button"
                onClick={() => onEdit(campaign.id)}
              >
                Ver campanha
              </button>
              <button className="edit-button" onClick={handleRedirect}>
                Compartilhar
              </button>
            </div>
          )}
          <div className="other-actions">
            <MdSettingsEthernet className="other-actions-btn-icon" />
            <button
              className="other-actions-btn"
              onClick={handlePopupToggle}
            ></button>
          </div>
          {imageLoading && (
            <div className="loading-main-image-campaign">
              <div className="loading-circle-image-campaign"></div>
            </div>
          )}
          {!imageLoading && <img src={campaign.image} />}
        </div>

        <div className="info-container">
          {/* <h2 className="title">{limitTitle(campaign.title, maxTitleLength)}</h2>
          {/* <p className="description">{"Descrição: " + campaign.description}</p>
          <h4>{"Quantidade de cotas: " + campaign.shares}</h4>
          <h4>{"Preço por cotas: " + "R$ " + formatNumber(campaign.priceShare / 100)}</h4> */}
          <div className="container-div-1">
            <div className="sub-container-1-div-1">
              <div className="sub-container-1-div-1-left">
                <h4 className="title">
                  {limitTitle(campaign.title, maxTitleLength)}
                </h4>
              </div>
              <div className="sub-container-1-div-1-right">
                <h4 className="finish_date">
                  {"Data do sorteio: " +
                    (String(campaign.finish_date) !== ""
                      ? campaign.finish_date
                      : "Indefinido")}
                </h4>
              </div>
            </div>
            <div className="sub-container-2-div-1">
              <div className="progress-bar">
                <div
                  className="progress-bar-handle"
                  style={{ width: campaign.campaing_percent + "%" }}
                ></div>
              </div>
            </div>
            <div className="sub-container-3-div-1">
              <div className="sub-container-3-div-1-left"></div>
              <div className="sub-container-3-div-1-right">
                <h4>
                  {campaign.campaing_percent}% de{" "}
                  {formatNumberThousands(campaign.shares)} bilhetes
                </h4>
              </div>
            </div>
          </div>
          <div className="container-div-2">
            <div className="sub-container-1-div-2">
              <h4>Pagos {campaign.purchase_shares}</h4>
            </div>
            <div className="sub-container-2-div-2">
              <h4>Reservados {campaign.reserved_shares}</h4>
            </div>
            <div className="sub-container-3-div-2">
              <h4>
                {"Arrecadou " + "R$ " + formatNumber(campaign.amount_raised)}{" "}
                {/* collected_price */}
              </h4>
            </div>
          </div>
          {/* <div className="container-div-3">
            <div className="sub-container-div-3-left">
              <button className="edit-button" onClick={() => onEdit(campaign.id)}>
                Ver campanha
              </button>
            </div>
            <div className="sub-container-div-3-right">
              <button className="share-button" onClick={handleRedirect}>
                Compartilhar
              </button>
            </div>
          </div> */}
        </div>
        {/*<div className="finish_date-container">
          <div className="finish_date_sub-container">
          <h4 className="finish_date">
            {"Data do sorteio: " +
            String(formatBrazilianDate(campaign.finish_date))}
          </h4>
          </div>
          <div className="edit-button-main">
            <button className="edit-button" onClick={() => onEdit(campaign.id)}>
              Ver campanha
            </button>
            <button className="edit-button" onClick={handleRedirect}>
              Compartilhar
            </button>
          </div>
          </div> */}

        {/* <button className="edit-button" onClick={() => onDelete(campaign)}>
        Excluir
      </button> */}
      </div>
    </div>
  );
};

export default CampaignDetails;
