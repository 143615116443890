import React, { useState, useEffect } from "react";
import "./FindTickets.css";
import FindTicketsDetails from "./FindTicketsDetails";
import { FaFilter, FaEye, FaEyeSlash } from "react-icons/fa";
import config from "../../../config";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../Sol_E_Mar_Logo.webp";
import FindTicketsFilterPanel from "../../../components/FindTicketsFilterPanel";

const FindTickets = () => {
  const navigate = useNavigate();

  const [divSize, setDivSize] = useState({
    width: "100%",
    height: "100%",
  });

  const [showData, setShowData] = useState(true);

  const toggleShowData = () => {
    setShowData((prevShowData) => !prevShowData);
  };

  // CronJob
  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendDomain = config.backendDomain;
        const url = `${backendDomain}/backend/src/view/utils/execCronJob.php`;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
          throw new Error("Falha ao chamar o endpoint do cronjob");
        }
      } catch (error) {
        console.error("Erro ao chamar o endpoint do cronjob", error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000); // 60 segundos

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const adjustDivSize = () => {
      const isDesktop = window.innerWidth > 768;
      if (isDesktop) {
        setDivSize({
          width: "400px",
          height: "100%",
        });
      } else {
        setDivSize({
          width: "100%",
          height: "100%",
        });
      }
    };

    window.addEventListener("resize", adjustDivSize);

    adjustDivSize();

    return () => {
      window.removeEventListener("resize", adjustDivSize);
    };
  }, []);

  const [isLoadingBuyer, setLoadingBuyer] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [disablePagesButtons, setDisablePagesButtons] = useState(false);
  const itemsPerPage = 2;
  const [buyers, setBuyers] = useState([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("lastId");
  const backToPage = () => {
    if (!id) {
      navigate(-1);
    } else {
      navigate(`/?id=${id}`);
    }
  };
  const phoneNumber = params.get("telefone");

  const [filters, setFilters] = useState({
    paid: true,
    pending: true,
    expired: true,
    canceled: true,
  });
  const [showFilterPanel, setShowFilterPanel] = useState(false);

  // Filtrar os compradores com base nos filtros selecionados
  const filteredBuyers = buyers.filter((buyer) => {
    // Se nenhum filtro estiver selecionado, exibe todos os compradores
    if (
      !filters.paid &&
      !filters.pending &&
      !filters.expired &&
      !filters.canceled
    ) {
      return true;
    }
    // Verifica se o comprador corresponde a pelo menos um filtro selecionado
    return (
      (filters.paid && buyer.purchase.status === 1) ||
      (filters.pending && buyer.purchase.status === 0) ||
      (filters.expired && buyer.purchase.status === 2) ||
      (filters.canceled && buyer.purchase.status === 3)
    );
  });

  const handleFilterChange = (filterName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  const applyFilters = () => {
    setShowFilterPanel(false);
    // Lógica para aplicar os filtros
  };

  const resetFilters = () => {
    setFilters({
      paid: true,
      pending: true,
      expired: true,
      canceled: true,
    });
    // Lógica para redefinir os filtros
  };

  useEffect(() => {
    // Renderizar novamente os detalhes dos compradores quando os dados forem atualizados
    // (quando `buyers` mudar)
    console.log("Compradores atualizados:", buyers);
    /* console.log(selectedCampaign);
    console.log(currentPage);
    console.log(totalPages); */
  }, [buyers]);

  useEffect(() => {
    // Função para buscar campanhas do backend
    const fetchCampaigns = async () => {
      try {
        const formData = new URLSearchParams();
        formData.append("phone_number", phoneNumber);
        formData.append("email", "");
        const response = await fetch(
          `${config.backendDomain}/backend/src/view/home/purchase/getPurchaseCampaings.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
          }
        );
        if (!response.ok) {
          throw new Error("Não foi possível obter as campanhas");
        }
        const data = await response.json();

        if (data.value != null) setCampaigns(data.value);
        else setCampaigns([]);

        console.log("purchase campaings", data.value);
      } catch (error) {
        console.error("Erro ao buscar campanhas:", error);
      }
    };

    fetchCampaigns(); // Chame a função fetchCampaigns quando o componente montar
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    const page = params.get("pagina");

    setSelectedCampaign(id || "");
    setCurrentPage(parseInt(page) || 1);
  }, [location.search]);

  useEffect(() => {
    // Buscar o número total de páginas para a campanha selecionada
    const fetchTotalPages = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const formData = new URLSearchParams();
        formData.append("phone_number", phoneNumber);
        formData.append("campaing_id", selectedCampaign);
        formData.append("email", "");

        const response = await fetch(
          `${config.backendDomain}/backend/src/view/home/purchase/getNumberPagesUser.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
          }
        );

        if (!response.ok) {
          throw new Error("Não foi possível obter o número de páginas");
        }
        const data = await response.json();
        setTotalPages(data.pages);
      } catch (error) {
        console.error("Erro ao buscar o número de páginas:", error);
      }
    };

    if (selectedCampaign) {
      fetchTotalPages();
    } else {
      setLoadingBuyer(false);
    }
  }, [selectedCampaign, currentPage]);

  // Buscar lista de compradores para a campanha selecionada e a página atual
  const fetchBuyers = async () => {
    const token = localStorage.getItem("accessToken");

    try {
      const formData = new URLSearchParams();
      formData.append("phone_number", phoneNumber);
      formData.append("campaing_id", selectedCampaign);
      formData.append("page", currentPage);
      formData.append("email", "");

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/purchase/getElementsPageUser.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (!response.ok) {
        throw new Error("Não foi possível obter os compradores");
      }
      const data = await response.json();
      setBuyers(data.value);
      setLoadingBuyer(false);
    } catch (error) {
      console.error("Erro ao buscar compradores:", error);
    }
  };

  useEffect(() => {
    if (selectedCampaign) {
      fetchBuyers();
    }
  }, [selectedCampaign, currentPage]);

  const handleCampaignChange = (event) => {
    const campaignId = event.target.value;
    setSelectedCampaign(campaignId);
    setCurrentPage(1); // Resetar a página para 1 quando a campanha mudar
    const url = buildURL(campaignId, 1);
    window.history.pushState({}, "", url); // Atualizar a URL no histórico do navegador
    setLoadingBuyer(campaignId !== ""); // Define isLoadingBuyer como true se a campanha for selecionada
  };

  const buildURL = (campaignId, currentPage) => {
    const baseURL = "/buscaDeBilhetes";
    const params = new URLSearchParams();
    if (campaignId) {
      params.append("lastId", campaignId);
    }
    if (phoneNumber) {
      params.append("telefone", phoneNumber);
    }
    params.append("pagina", currentPage);
    return `${baseURL}?${params.toString()}`;
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const url = buildURL(selectedCampaign, pageNumber);
    window.history.pushState({}, "", url); // Atualizar a URL no histórico do navegador
    setLoadingBuyer(true);
    // setBuyers([]);
    // fetchBuyers();
  };

  return (
    <div className="main-history-find-tickets">
      <div className="background-find-tickets"></div>
      <div
        className="sub-sub-find-tickets"
        style={{
          width: divSize.width,
          height: divSize.height,
        }}
      >
        <div className="nav-bar-find-tickets">
          <div className="nav-bar-find-tickets-left">
            <img className="logo-img-bar-left" src={Logo}></img>
          </div>
          <div className="nav-bar-find-tickets-right">
            <button onClick={() => backToPage()}>Voltar</button>
          </div>
        </div>
        <div className="sub-main-history-find-tickets">
          <div className="sub-main-content-history-find-tickets">
            <div className="top-content-find-tickets">
              <div className="top-text-find-tickets">
                <h2 className="history-text-find-tickets">
                  Bilhetes comprados
                </h2>
              </div>
              <div className="select-campaigns-find-tickets">
                <div className="select-campaigns-list-find-tickets">
                  <label>Selecione uma campanha</label>

                  <select
                    className="selection-find-tickets"
                    onChange={handleCampaignChange}
                    value={selectedCampaign}
                  >
                    <option value="">Escolha uma opção</option>
                    {campaigns &&
                      campaigns.map((campaign) => (
                        <option key={campaign.id} value={campaign.id}>
                          {campaign.title}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="data-find-tickets">
                  {showFilterPanel && (
                    <FindTicketsFilterPanel
                      filters={filters}
                      onChange={handleFilterChange}
                      onApply={applyFilters}
                      onReset={resetFilters}
                      onClose={() => setShowFilterPanel(false)}
                    />
                  )}
                  <button onClick={() => toggleShowData()}>
                    {showData ? (
                      <FaEye className="data-icon-find-tickets" />
                    ) : (
                      <FaEyeSlash className="data-icon-find-tickets" />
                    )}
                    Dados
                  </button>
                </div>
                <div className="filters-find-tickets">
                  <button
                    className="filters-button-find-tickets"
                    onClick={() => setShowFilterPanel(true)}
                  >
                    <FaFilter className="filter-icon-find-tickets" /> Filtros
                  </button>
                  {/* Renderização condicional do painel de filtros */}
                </div>
              </div>
            </div>
            {selectedCampaign && (
              <div className="pages-count-find-tickets">
                <div className="pages-count-content-find-tickets">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Anterior
                  </button>
                  {/* Botões de página */}
                  {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <button
                        key={pageNumber}
                        className="pageNumbers-find-tickets"
                        onClick={() => paginate(pageNumber)}
                        disabled={currentPage === pageNumber}
                      >
                        {pageNumber}
                      </button>
                    );
                  })}
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Próxima
                  </button>
                </div>
              </div>
            )}

            <div className="campaigns-information-history-find-tickets">
              {isLoadingBuyer && (
                <div className="loading-main-buyer-find-tickets">
                  <div className="loading-circle-buyer-find-tickets"></div>
                </div>
              )}

              {buyers.length === 0 && !isLoadingBuyer && (
                <div className="info-buyers-find-tickets">
                  {" "}
                  Selecione uma campanha{" "}
                </div>
              )}

              {selectedCampaign && !isLoadingBuyer && (
                <FindTicketsDetails
                  campaign={selectedCampaign}
                  buyers={buyers}
                  filters={filters}
                  phoneNumber={phoneNumber}
                  showData={showData}
                  /* startIndex={0}
              endIndex={totalPages} */
                  /* itemsPerPage={itemsPerPage} */
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindTickets;
