import React from "react";
import { formatNumber } from "../../components/utils/Utils";
import "./HomeLayout2.css";
import { FaFacebook } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { BsLightningChargeFill } from "react-icons/bs";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const HomeLayout2 = ({
  logoImage,
  image,
  imageLoaded,
  handleImageLoaded,
  title,
  topMarginTitle,
  date,
  price,
  description,
  definedDate,
  campaignEnded,
  campaignEndedText,
  fullName,
  setFullName,
  email,
  setEmail,
  termsAgreed,
  setTermsAgreed,
  quantity,
  setQuantity,
  formattedFindTicketsPhone,
  handlePhoneFindTickets,
  formattedPhone,
  handlePhoneChange,
  reservationPanelRef,
  localLoading,
  showFindTicketsPopup,
  handleReservation,
  findTicketsPaneltext,
  showPopup,
  showReservationPanel,
  closeReservationPanel,
  updateQuantity,
  updateQuantitySub,
  maxHeightImage,
  topMarginImage,
  imageContainerSize,
  imageBorderRadius,
  adjustPriceContent,
  redirectToWhatsapp,
  redirectToFacebook,
  redirectToTelegram,
  redirectToTwitter,
  updateTotalValue,
  openReservationPanel,
  findTicketsRef,
  handleFindTickets,
  togglePopup,
  width,
  height,
}) => {
  return (
    <div
      className="home_layout_2_main"
      style={{
        width: width,
        height: height,
      }}
    >
      <div
        className="image_container_layout_2"
        style={{
          maxHeight: maxHeightImage,
        }}
      >
        <img className="image_content_layout_2" src={image}></img>
        <div className="text_image_layout_2">
          <span>Adquira já!</span>
        </div>
      </div>

      <div className="title_container_layout_2">
        <div className="title_container_content_1_layout_2"></div>
        <div className="title_container_content_2_layout_2">
          <p>{title}</p>
        </div>
        <div className="title_container_content_3_layout_2">
          <p>
            Imagina ganhar {title} por apenas R$ {formatNumber(price)}?
          </p>
        </div>
      </div>
      <div className="price_container_layout_2">
        <div className="price_content_container_layout_2">
          <span>POR APENAS</span>
          <div className="price_value_container_layout_2">
            <p>R$ {formatNumber(price)}</p>
          </div>
        </div>
      </div>
      <div className="date_and_social_media_container_layout_2">
        <div className="date_content_container_layout_2">
          <span>Sorteio</span>
          {definedDate && (
            <div className="date_value_container_layout_2">
              <p>{date}</p>
            </div>
          )}
        </div>
        <div className="social_media-container_layout_2">
          <div className="social_media_content_1_layout_2">
            <button
              onClick={() => redirectToFacebook()}
              disabled={showFindTicketsPopup}
            >
              <FaFacebook className="facebook_icon_layout_2" />
            </button>
          </div>
          <div className="social_media_content_2_layout_2">
            <button
              onClick={() => redirectToTelegram()}
              disabled={showFindTicketsPopup}
            >
              <FaTelegram className="telegram_icon_layout_2" />
            </button>
          </div>
          <div className="social_media_content_3_layout_2">
            <button
              onClick={() => redirectToTwitter()}
              disabled={showFindTicketsPopup}
            >
              <FaTwitter className="twitter_icon_layout_2" />
            </button>
          </div>
          <div className="social_media_content_4_layout_2">
            <button
              onClick={() => redirectToWhatsapp()}
              disabled={showFindTicketsPopup}
            >
              <FaWhatsapp className="whatsapp_icon_layout_2" />
            </button>
          </div>
        </div>
      </div>
      {campaignEnded && (
        <div className="final-date_layout_2">
          <p className="final-date-text_layout_2">
            {campaignEndedText} {date}
          </p>
        </div>
      )}
      {!campaignEnded && (
        <div className="tickets_info_container_layout_2">
          <div className="text_info_container_layout_2">
            <div className="lightning_icon_container_layout_2">
              <BsLightningChargeFill className="lightning_icon_layout_2" />
            </div>
            <h3>Cotas</h3>
            <p>Escolha sua sorte</p>
          </div>
          <div className="view_tickets_container_layout_2">
            <button
              className="view_tickets_button_layout_2"
              onClick={togglePopup}
              disabled={showFindTicketsPopup}
            >
              <HiOutlineShoppingCart className="shopping_cart_icon_layout_2" />{" "}
              Ver meus números
            </button>
          </div>
        </div>
      )}
      {!campaignEnded && (
        <div className="tickets_select_quantity_container_layout_2">
          <div className="tickets_select_title_container_layout_2">
            <p>Selecione a quantidade de números</p>
          </div>
          <div className="tickets-options_grid_layout_2">
            <button
              onClick={() => updateQuantity(5)}
              disabled={showFindTicketsPopup}
            >
              <p className="ticket-options_grid_sum_p_layout_2">
                <span>+</span>05
              </p>
              <p className="ticket-options_grid_select_text_layout_2">
                SELECIONAR
              </p>
            </button>
            <button
              onClick={() => updateQuantity(10)}
              disabled={showFindTicketsPopup}
            >
              <div className="most_popular_container_layout_2">
                <p>Mais popular</p>
              </div>
              <p className="ticket-options_grid_sum_p_layout_2">
                <span>+</span>10
              </p>
              <p className="ticket-options_grid_select_text_layout_2">
                SELECIONAR
              </p>
            </button>
            <button
              onClick={() => updateQuantity(50)}
              disabled={showFindTicketsPopup}
            >
              <p className="ticket-options_grid_sum_p_layout_2">
                <span>+</span>50
              </p>
              <p className="ticket-options_grid_select_text_layout_2">
                SELECIONAR
              </p>
            </button>
            <button
              onClick={() => updateQuantity(100)}
              disabled={showFindTicketsPopup}
            >
              <p className="ticket-options_grid_sum_p_layout_2">
                <span>+</span>100
              </p>
              <p className="ticket-options_grid_select_text_layout_2">
                SELECIONAR
              </p>
            </button>
          </div>
          <div className="tickets_value_container_layout_2">
            <div>
              <button
                onClick={() => updateQuantitySub(1)}
                disabled={showFindTicketsPopup}
              >
                <span>-</span>
              </button>
            </div>
            <div>
              <input
                className="tickets_input_layout_2"
                type="number"
                value={quantity}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (!isNaN(value)) {
                    // Verifica se é um número válido
                    setQuantity(Math.max(value, 0)); // Limita o mínimo valor a 0
                  }
                }}
              ></input>
            </div>
            <div>
              <button
                onClick={() => updateQuantity(1)}
                disabled={showFindTicketsPopup}
              >
                <span>+</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {!campaignEnded && (
        <div className="participate_in_the_raffle_container_layout_2">
          <button
            onClick={openReservationPanel}
            disabled={showFindTicketsPopup}
          >
            <IoMdCheckmarkCircleOutline className="checked_icon_layout_2" />
            <p> Participar do sorteio</p>
            <p>
              <span>R$</span> {updateTotalValue()}
            </p>
          </button>
        </div>
      )}
      {!campaignEnded && (
        <div className="description_container_layout_2">
          <textarea
            className="description_text_area_layout_2"
            value={description}
            readOnly
          ></textarea>
        </div>
      )}
      <div className="bottom_space"></div>

      {showFindTicketsPopup && (
        <div className="main-find-tickets-popup-error">
          <div className="sub-main-find-tickets-popup-error">
            <h2>
              {findTicketsPaneltext.includes("\n")
                ? findTicketsPaneltext.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < findTicketsPaneltext.split("\n").length - 1 && (
                        <br />
                      )}{" "}
                      {/* Adiciona quebra de linha apenas se houver outro texto abaixo */}
                    </React.Fragment>
                  ))
                : findTicketsPaneltext}
            </h2>
          </div>
        </div>
      )}

      {showPopup && (
        <div className="main-find-tickets-popup">
          <div className="find-tickets-popup" ref={findTicketsRef}>
            <div className="find-tickets-popup-top">
              <h3 className="find-tickets-popup-title">Buscar bilhetes</h3>
            </div>
            <div className="find-tickets-popup-middle">
              <div className="find-tickets-popup-middle-top">
                <p>Telefone</p>
              </div>
              <div className="find-tickets-popup-middle-down">
                <input
                  type="text"
                  placeholder="Telefone / WhatsApp"
                  className="find-tickets-input-number"
                  value={formattedFindTicketsPhone}
                  onChange={handlePhoneFindTickets}
                  maxLength={11}
                ></input>
              </div>
            </div>
            <div className="find-tickets-popup-down">
              <button
                onClick={() => handleFindTickets()}
                disabled={showFindTicketsPopup}
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      )}

      {showReservationPanel && (
        <div className="main-reservation-panel">
          <div className="reservation-panel" ref={reservationPanelRef}>
            <div className="panel-content">
              <h3>Reservar Bilhetes</h3>
              <label>
                <input
                  type="text"
                  value={fullName}
                  placeholder="Nome completo"
                  onChange={(e) => setFullName(e.target.value)}
                />
              </label>
              <label>
                <input
                  type="text"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <label>
                <input
                  type="text"
                  value={formattedPhone}
                  placeholder="Telefone"
                  onChange={handlePhoneChange}
                  maxLength={11}
                />
              </label>
              <label className="check-box-terms-label">
                <input
                  className="check-box-terms"
                  type="checkbox"
                  checked={termsAgreed}
                  onChange={() => setTermsAgreed(!termsAgreed)}
                />
                Li e concordo com os termos e condições de uso
              </label>
              <div className="panel-buttons">
                <button className="cancel-btn" onClick={closeReservationPanel}>
                  Cancelar
                </button>
                <button
                  className="confirm-btn"
                  onClick={handleReservation}
                  disabled={showFindTicketsPopup}
                >
                  Reservar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeLayout2;
