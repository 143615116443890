import React, { useState, useEffect } from "react";
import "./BuyerHistory.css";
import BuyerCampaignesDetails from "./BuyerCampaignesDetails";
import { FaFilter, FaEye, FaEyeSlash, FaRegSadCry } from "react-icons/fa";
import config from "../../config";
import { useLocation } from "react-router-dom";
import FilterPanel from "../../components/FilterPanel";
import ErrorPanel from "../../components/utils/ErrorPanel";

const BuyerHistory = () => {
  const [isLoadingBuyer, setLoadingBuyer] = useState(true);
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [disablePagesButtons, setDisablePagesButtons] = useState(false);
  const itemsPerPage = 2;
  const [buyers, setBuyers] = useState([]);
  const location = useLocation();

  const [searchValue, setSearchValue] = useState("");
  const [loadingText, setLoadingText] = useState("Selecione uma campanha");

  const [isError, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showData, setShowData] = useState(true);

  const [filters, setFilters] = useState({
    paid: true,
    pending: true,
    expired: true,
    canceled: true,
  });

  /* const filteredBuyers = buyers.filter((buyer) => {
    // Se nenhum filtro estiver selecionado, exibe todos os compradores
    if (
      !filters.paid &&
      !filters.pending &&
      !filters.expired &&
      !filters.canceled
    ) {
      return true;
    }
    // Verifica se o comprador corresponde a pelo menos um filtro selecionado
    return (
      (filters.paid && buyer.purchase.status === 1) ||
      (filters.pending && buyer.purchase.status === 0) ||
      (filters.expired && buyer.purchase.status === 2) ||
      (filters.canceled && buyer.purchase.status === 3)
    );
  }); */

  // CronJob
  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendDomain = config.backendDomain;
        const url = `${backendDomain}/backend/src/view/utils/execCronJob.php`;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
          throw new Error("Falha ao chamar o endpoint do cronjob");
        }
      } catch (error) {
        console.error("Erro ao chamar o endpoint do cronjob", error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000); // 60 segundos

    return () => clearInterval(interval);
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
    paginate(1);
  };

  const [showFilterPanel, setShowFilterPanel] = useState(false);

  const handleFilterChange = (filterName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  const applyFilters = () => {
    setShowFilterPanel(false);
    // Lógica para aplicar os filtros
  };

  const resetFilters = () => {
    setFilters({
      paid: true,
      pending: true,
      expired: true,
      canceled: true,
    });
    // Lógica para redefinir os filtros
  };

  useEffect(() => {
    // Renderizar novamente os detalhes dos compradores quando os dados forem atualizados
    // (quando `buyers` mudar)
    console.log("Compradores atualizados:", buyers.length);
    /* console.log(selectedCampaign);
    console.log(currentPage);
    console.log(totalPages); */
  }, [buyers]);

  useEffect(() => {
    // Função para buscar campanhas do backend
    const fetchCampaigns = async () => {
      try {
        const response = await fetch(
          `${config.backendDomain}/backend/src/view/home/campaing/listCampaings.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Não foi possível obter as campanhas");
        }
        const data = await response.json();
        setCampaigns(data.value);
        setIsLoadingCampaigns(false);
      } catch (error) {
        console.error("Erro ao buscar campanhas:", error);
      }
    };

    fetchCampaigns(); // Chame a função fetchCampaigns quando o componente montar
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const campaignId = params.get("id");
    const page = params.get("pagina");

    setSelectedCampaign(campaignId || "");
    setCurrentPage(parseInt(page) || 1);
  }, [location.search]);

  useEffect(() => {
    // Buscar o número total de páginas para a campanha selecionada
    const fetchTotalPages = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const formData = new URLSearchParams();
        formData.append("token", token);
        formData.append("campaing_id", selectedCampaign);

        const response = await fetch(
          `${config.backendDomain}/backend/src/view/dashboard/buyerHistory/getNumberPages.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
          }
        );

        if (!response.ok) {
          throw new Error("Não foi possível obter o número de páginas");
        }
        const data = await response.json();
        setTotalPages(data.pages);
      } catch (error) {
        console.error("Erro ao buscar o número de páginas:", error);
      }
    };

    if (selectedCampaign) {
      fetchTotalPages();
    } else {
      setLoadingBuyer(false);
    }
  }, [selectedCampaign, currentPage]);

  // Buscar lista de compradores para a campanha selecionada e a página atual
  useEffect(() => {
    const fetchBuyers = async () => {
      const token = localStorage.getItem("accessToken");
      // Lógica para buscar os compradores
      try {
        const formData = new URLSearchParams();
        formData.append("token", token);
        formData.append("campaing_id", selectedCampaign);
        formData.append("page", currentPage);
        formData.append("input", searchValue);
        formData.append("paid", filters.paid);
        formData.append("expired", filters.expired);
        formData.append("pending", filters.pending);
        formData.append("canceled", filters.canceled);

        const response = await fetch(
          `${config.backendDomain}/backend/src/view/dashboard/buyerHistory/getElementsPage.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
          }
        );

        if (!response.ok) {
          throw new Error("Não foi possível obter os compradores");
        }
        const data = await response.json();

        if (data.status === "success") {
          setBuyers(data.value);
          setLoadingText("Selecione uma campanha");
        } else {
          setBuyers([]);
          setLoadingText("Não encontramos nada");

          switch (data.code) {
            case "0x0002":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setTimeout(() => {
                setError(false);
              }, 2000);
              break;
            case "0x0003":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setTimeout(() => {
                setError(false);
              }, 2000);
              break;
            /* case "0x0006":
              setErrorText("Erro 0x0006");
              setError(true);
              setTimeout(() => {
                setError(false);
              }, 2000);
              break; */
          }
        }
        setLoadingBuyer(false);
      } catch (error) {
        setLoadingBuyer(false);
        setLoadingText("Não encontramos nada");
        setErrorText("Erro ao buscar compradores");
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000); // 4 segundos
        console.error("Erro ao buscar compradores:", error);
      }
    };

    // Busca os compradores quando a campanha ou a página atual mudam
    if (selectedCampaign) {
      fetchBuyers();
    }
  }, [selectedCampaign, currentPage, filters, searchValue]);

  useEffect(() => {
    paginate(1);
  }, [filters]);

  const handleCampaignChange = (event) => {
    const campaignId = event.target.value;
    setSelectedCampaign(campaignId);
    setCurrentPage(1); // Resetar a página para 1 quando a campanha mudar
    const url = buildURL(campaignId, 1);
    window.history.pushState({}, "", url); // Atualizar a URL no histórico do navegador
    setLoadingBuyer(campaignId !== ""); // Define isLoadingBuyer como true se a campanha for selecionada
  };

  const buildURL = (campaignId, currentPage) => {
    const baseURL = "/dashboard/historicoDeCompradores";
    const params = new URLSearchParams();
    if (campaignId) {
      params.append("id", campaignId);
    }
    params.append("pagina", currentPage);
    return `${baseURL}?${params.toString()}`;
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const url = buildURL(selectedCampaign, pageNumber);
    window.history.pushState({}, "", url); // Atualizar a URL no histórico do navegador
    setLoadingBuyer(true);
    setTimeout(() => {
      setLoadingBuyer(false);
    }, 1000);
    // setBuyers([]);
    // fetchBuyers();
  };

  const toggleShowData = () => {
    setShowData((prevShowData) => !prevShowData);
  };

  return (
    <div className="main-history">
      <div className="background"></div>
      <div className="sub-main-history">
        {isError && <ErrorPanel errorText={errorText} />}
        <div className="sub-main-content-history">
          {isLoadingCampaigns && !isLoadingBuyer && (
            <div div className="loading-main">
              <div className="loading-circle"></div>
            </div>
          )}
          {!isLoadingCampaigns && (
            <div className="top-content">
              <div className="top-text">
                <h2 className="history-text">Histórico de compradores</h2>
              </div>
              <div className="content_container_buyer_history">
                <div className="select-campaigns">
                  <div className="select-campaigns-list">
                    <label>Selecione uma campanha</label>
                    <select
                      className="selection"
                      onChange={handleCampaignChange}
                      value={selectedCampaign}
                    >
                      <option value="">Escolha uma opção</option>
                      {campaigns.map((campaign) => (
                        <option key={campaign.id} value={campaign.id}>
                          {campaign.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="data">
                    <button onClick={() => toggleShowData()}>
                      {showData ? <FaEye /> : <FaEyeSlash />}
                      Dados
                    </button>
                  </div>
                  <div className="filters">
                    <button
                      className="filters-button"
                      onClick={() => setShowFilterPanel(true)}
                    >
                      <FaFilter /> Filtros
                    </button>
                    {/* Renderização condicional do painel de filtros */}
                    {showFilterPanel && (
                      <FilterPanel
                        filters={filters}
                        onChange={handleFilterChange}
                        onApply={applyFilters}
                        onReset={resetFilters}
                        onClose={() => setShowFilterPanel(false)}
                      />
                    )}
                  </div>
                </div>
                {selectedCampaign && (
                  <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Pesquisar"
                      value={searchValue}
                      onChange={handleSearchInputChange}
                    />
                    <button>Pesquisar</button>
                  </div>
                )}
                {selectedCampaign && buyers.length !== 0 && (
                  <div className="pages-count">
                    <div className="pages-count-content">
                      <button
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Anterior
                      </button>
                      {/* Botões de página */}
                      {[...Array(totalPages)].map((_, index) => {
                        const pageNumber = index + 1;
                        return (
                          <button
                            key={pageNumber}
                            className="pageNumbers"
                            onClick={() => paginate(pageNumber)}
                            disabled={currentPage === pageNumber}
                          >
                            {pageNumber}
                          </button>
                        );
                      })}
                      <button
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        Próxima
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="campaigns-information-history">
            {isLoadingBuyer && (
              <div className="loading-main-buyer">
                <div className="loading-circle-buyer"></div>
              </div>
            )}
            {buyers.length === 0 && !isLoadingCampaigns && !isLoadingBuyer && (
              <div className="info-buyers">
                {" "}
                <FaRegSadCry className="search-not-found-icon" /> {loadingText}{" "}
              </div>
            )}
            {selectedCampaign &&
              !isLoadingBuyer &&
              Array.isArray(buyers) && // Verifica se 'buyers' é um array
              buyers &&
              buyers.length > 0 && // Verifica se 'buyers' possui compradores
              buyers.map((buyer, index) => (
                <BuyerCampaignesDetails
                  key={index}
                  campaign={selectedCampaign}
                  buyers={buyers}
                  buyer={buyer}
                  filters={filters}
                  showData={showData}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerHistory;
