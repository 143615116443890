import React, { useState } from "react";
import "./HomeNavbarLayout2.css";
import { FcSearch } from "react-icons/fc";
import { TbShoppingCartCheck } from "react-icons/tb";
import { GiHamburgerMenu, GiCancel } from "react-icons/gi";
import Logo from "./Sol_E_Mar_Logo.webp"

const HomeNavbarLayout1 = ({showPopupNav}) => {

    return (
        <div className="home-top-bar-main_layout_2">
            <div className="home-top-bar-left_layout_2">
                <img className="logo-img-bar-left_layout_2" src={Logo}></img>
                {/*<h4>Logo do cliente</h4> */}
            </div>
            <div className="home-top-bar-right_layout_2">
                <button 
                className="home-tickets_layout_2"
                onClick={showPopupNav}
                >
                    <TbShoppingCartCheck className="home-tickets_icon_layout_2" />
                </button>
                <button className="home-gi-menu_layout_2">
                    <GiHamburgerMenu className="home-gi-menu_icon_layout_2"/>
                </button>
            </div>
        </div>
    )
};

export default HomeNavbarLayout1;