import React, { useRef, useEffect } from "react";
import "./FindTicketsFilterPanel.css";

const FindTicketsFilterPanel = ({ filters, onChange, onApply, onReset, onClose }) => {
  const panelRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div ref={panelRef} className="filter-panel-find-tickets">
      {/* Opções de filtro */}
      <label>
        <input
          type="checkbox"
          checked={filters.paid}
          onChange={() => onChange("paid")}
        />
        Pago
      </label>
      <label>
        <input
          type="checkbox"
          checked={filters.pending}
          onChange={() => onChange("pending")}
        />
        Pendente
      </label>
      <label>
        <input
          type="checkbox"
          checked={filters.expired}
          onChange={() => onChange("expired")}
        />
        Expirado
      </label>
      <label>
        <input
          type="checkbox"
          checked={filters.canceled}
          onChange={() => onChange("canceled")}
        />
        Cancelado
      </label>
      {/* Outras opções de filtro aqui */}

      {/* Botões de ação */}
      <div className="filter-buttons-find-tickets">
        {/* <button onClick={onApply}>Aplicar</button> */}
        <button onClick={onReset}>Redefinir</button>
        <button onClick={onClose}>Fechar</button>
      </div>
    </div>
  );
};

export default FindTicketsFilterPanel;