import React, { useState, useEffect, useRef} from "react";
import "./BuyerCampaignesDetails.css";
import { formatNumber } from "../../components/utils/Utils";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { HiTicket } from "react-icons/hi2";
import { FaCalendarDays } from "react-icons/fa6";
import { MdSettingsEthernet } from "react-icons/md";
import config from "../../config";
import CustomPanel from "../../components/CustomPanel";

const getBuyerStateClass = (status) => {
  switch (status) {
    case 0:
      return "pending"; // Classe CSS para o estado "Pendente"
    case 1:
      return "approved"; // Classe CSS para o estado "Aprovado"
    case 2:
      return "expired"; // Classe CSS para o estado "Expirado"
    case 3:
      return "canceled"; // Classe CSS para o estado "Recusado"
    default:
      return ""; // Classe padrão caso o status seja desconhecido
  }
};

const BuyerCampaignesDetails = ({
  campaign,
  buyer,
  buyers,
  filters,
  startIndex,
  endIndex,
  itemsPerPage,
  showData
}) => {

  const [openPanel1, setOpenPanel1] = useState(false);
  const [openPanel2, setOpenPanel2] = useState(false);

  const handleOpenPanel1 = () => {
    setOpenPanel1(true);
    setOpenPanel2(false);
    setPopupOpen(false);
  }

  const handleOpenPanel2 = () => {
    setOpenPanel1(false);
    setOpenPanel2(true);
    setPopupOpen(false);
  }

  const handleCancelPanel1 = () => {
    setOpenPanel1(false);
  }

  const handleCancelPanel2 = () => {
    setOpenPanel2(false);
  }

  const handleConfirmPanel1 = () => {
    markPaid();
    setOpenPanel1(false);
  }

  const handleConfirmPanel2 = () => {
    cancelPurchase();
    setOpenPanel2(false);
  }

  const markPaid = async () => {
    try {

      const token = localStorage.getItem("accessToken");

      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("purchase_id", buyer.purchase.id);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/buyerHistory/markPaidPurchase.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );    

      if (response.ok) {
        const data = await response.json();

        if (data.status === "success") {
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Erro ao marcar como pago", error);
    }
  }

  const cancelPurchase = async () => {
    try {

      const token = localStorage.getItem("accessToken");

      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("purchase_id", buyer.purchase.id);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/buyerHistory/cancelPurchase.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );    

      if (response.ok) {
        const data = await response.json();

        if (data.status === "success") {
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Erro ao marcar como pago", error);
    }
  }

  const redirectToContactLink = async (link) => {
    link = await getContactLink();
    window.open(link, "_blank");
    console.log(link);
  }

  const getContactLink = async () => {
    try {

      const token = localStorage.getItem("accessToken");

      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("purchase_id", buyer.purchase.id);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/buyerHistory/getWhatsappLinkUser.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );    

      if (response.ok) {
        const data = await response.json();

        if (data.status === "success") {
          return data.value; // WhatsApp link
        }
      }
    } catch (error) {
      console.error("Erro ao buscar link do WhatsApp:", error);
    }
  }

  const [popupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef(null);

  const handlePopupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    if (popupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [popupOpen]);

  // Renderiza os detalhes dos compradores para a página atual
  /* const currentItems = buyers ? buyers.slice(startIndex, endIndex) : [];

    console.log("Dados dos compradores:", currentItems); // Adicionando um console.log para verificar os dados dos compradores */

    const formatPhoneNumber = (phoneNumber) => {
        // Remove caracteres não numéricos do número de telefone
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
        // Aplica a formatação desejada
        const formattedPhoneNumber = cleanedPhoneNumber.replace(
          /(\d{2})(\d{5})(\d{4})/,
          "($1) $2-$3"
        );
        return formattedPhoneNumber;
      };

      if (buyers.length === 0) {
        return <div className="info-buyers">Selecione uma campanha</div>;
      }

      // Filtrar os compradores com base nos filtros selecionados
  /* const filteredBuyers = buyers.filter((buyer) => {
    // Se nenhum filtro estiver selecionado, exibe todos os compradores
    if (
      !filters.paid &&
      !filters.pending &&
      !filters.expired &&
      !filters.canceled
    ) {
      return true;
    }
    // Verifica se o comprador corresponde a pelo menos um filtro selecionado
    return (
      (filters.paid && buyer.purchase.status === 1) ||
      (filters.pending && buyer.purchase.status === 0) ||
      (filters.expired && buyer.purchase.status === 2) ||
      (filters.canceled && buyer.purchase.status === 3)
    );
  }); */

  return (
    <div className="content-buyer-main">
      {/* Renderiza os detalhes dos compradores da página atual */}

      {openPanel1 && (
        <CustomPanel
          topText={"Deseja mesmo marcar como pago?"}
          onCancel={handleCancelPanel1}
          onConfirm={handleConfirmPanel1}
        />
      )}

      {openPanel2 && (
        <CustomPanel
          topText={"Deseja mesmo cancelar a reserva?"}
          onCancel={handleCancelPanel2}
          onConfirm={handleConfirmPanel2}
        />
      )}

        <div className="content-buyer-detail-main">
          {/* Renderiza os detalhes do comprador */}
          <div className="content-1">
            <div className="content-1-">
              <div className="buyerName">{buyer.user.name}</div>
              <div className="buyerState-main">
                <div className={`buyerState ${getBuyerStateClass(buyer.purchase.status)}`}>
                  {(() => {
                    switch (buyer.purchase.status) {
                      case 0:
                        return "Pendente";
                      case 1:
                        return "Aprovado";
                      case 2:
                        return "Expirado";
                      case 3:
                        return "Recusado";
                      default:
                        return "Desconhecido";
                    }
                  })()}
                </div>
              </div>
            </div>
            <div className="main-other-settings">
              <MdSettingsEthernet className="icon"/>
              <button 
              className="other-settings-btn"
              onClick={handlePopupToggle}>

              </button>
            </div>
            {popupOpen && (
              <div >
                {buyer.purchase.status === 0 && (
                  <div className="popup-other-settings" ref={popupRef}>
                    <button className="btn-other-settings"
                    onClick={() => redirectToContactLink(getContactLink())}>
                      Entrar em contato
                    </button>
                    <button className="btn-paid-settings"
                    onClick={() => handleOpenPanel1()}>
                      Marcar como pago
                    </button>
                    <button className="btn-other-settings"
                    onClick={() => handleOpenPanel2()}>
                      Cancelar reserva
                    </button>
                  </div>
                )}
                {buyer.purchase.status === 1 && (
                  <div className="popup-other-settings" ref={popupRef}>
                    <button className="btn-other-settings"
                    onClick={() => redirectToContactLink(getContactLink())}>
                      Entrar em contato
                    </button>
                    <button className="btn-other-settings"
                    onClick={() => handleOpenPanel2()}>
                      Cancelar reserva
                    </button>
                  </div>
                )}
                {buyer.purchase.status === 2 && (
                  <div className="popup-other-settings" ref={popupRef}>
                    <button className="btn-other-settings"
                    onClick={() => redirectToContactLink(getContactLink())}>
                      Entrar em contato
                    </button>
                  </div>
                )}
                {buyer.purchase.status === 3 && (
                  <div className="popup-other-settings" ref={popupRef}>
                    <button className="btn-other-settings"
                    onClick={() => redirectToContactLink(getContactLink())}>
                      Entrar em contato
                    </button>
                    {/*
                    <button className="btn-paid-settings"
                    onClick={() => handleOpenPanel1()}>
                      Marcar como pago
                    </button>
                    <button className="btn-other-settings">
                      Cancelar reserva
                    </button> */}
                  </div>
                )}
              </div>
          )}
          </div>
          <div className="content-2">
            <div className={`content-2- ${showData ? '' : ' content-2-blur-details'}`}>
              <p>
                <MdEmail /> Email {buyer.user.email}
              </p>
              <p>
                <FaPhone /> Telefone {formatPhoneNumber(buyer.user.phone_number)}
              </p>
              <p>
                <RiMoneyDollarCircleFill /> Valor R$ {formatNumber(buyer.purchase.price)}
              </p>
              <p>
                <HiTicket /> Bilhetes {buyer.purchase.tickets}
              </p>
              <p>
                <FaCalendarDays /> Criado em {buyer.purchase.purchase_date}
              </p>
            </div>
          </div>
          <div className="content-3">
            <div className="ticket-container">
            <div className="ticket">
                {buyer.tickets.map((ticket, index) => (
                    <div key={index} className="ticket-item">
                        {ticket}
                    </div>
                ))}
            </div>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default BuyerCampaignesDetails;
