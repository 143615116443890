import React, { useEffect } from "react";
import "./CustomPanel.css"; // Estilos CSS para o componente

const CustomPanel = ({
  backgroundColor = "#464646",
  defaultColor = "#ffffff",
  topText = "Default Top Text",
  cancelButtonText = "Cancelar",
  cancelButtonColor = "#ffffff",
  cancelButtonBgColor = "#ff0000",
  confirmButtonText = "Confirmar",
  confirmButtonColor = "#ffffff",
  confirmButtonBgColor = "#008000",
  onCancel,
  onConfirm,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".custom-panel")) {
        onCancel(); // Chama a função de cancelamento quando o clique ocorre fora do painel
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCancel]);

  return (
    <div className="main-custom-panel">
    <div className="custom-panel" style={{ backgroundColor }}>
      <div className="title-top-text">
        <h3 style={{ color: defaultColor }}>{topText}</h3>
      </div>
      <div className="buttons">
        <button
          className="cancel-button-panel"
          style={{ color: cancelButtonColor }}
          onClick={onCancel}
        >
          {cancelButtonText}
        </button>
        <button
          className="confirm-button-panel"
          style={{ color: confirmButtonColor }}
          onClick={onConfirm}
        >
          {confirmButtonText}
        </button>
      </div>
    </div>
    </div>
  );
};

export default CustomPanel;
