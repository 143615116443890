import React from "react";
import "./FilterPanel.css"

const FilterPanel = ({ filters, onChange, onApply, onReset, onClose }) => {
  return (
    <div className="filter-panel">
      {/* Opções de filtro */}
      <label>
        <input
          type="checkbox"
          checked={filters.paid}
          onChange={() => onChange("paid")}
        />
        Pago
      </label>
      <label>
        <input
          type="checkbox"
          checked={filters.pending}
          onChange={() => onChange("pending")}
        />
        Pendente
      </label>
      <label>
        <input
          type="checkbox"
          checked={filters.expired}
          onChange={() => onChange("expired")}
        />
        Expirado
      </label>
      <label>
        <input
          type="checkbox"
          checked={filters.canceled}
          onChange={() => onChange("canceled")}
        />
        Cancelado
      </label>
      {/* Outras opções de filtro aqui */}

      {/* Botões de ação */}
      <div className="filter-buttons">
        {/* <button onClick={onApply}>Aplicar</button> */}
        <button onClick={onReset}>Redefinir</button>
        <button onClick={onClose}>Fechar</button>
      </div>
    </div>
  );
};

export default FilterPanel;