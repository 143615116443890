import React, { useEffect, useState, useRef } from "react";
import config from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import "./Home.css";
import { formatNumber } from "../../components/utils/Utils";
import logoImage from "./Sol_E_Mar_Logo.webp";
import { Helmet } from "react-helmet";
import HomeLoading from "../../components/utils/HomeLoading";
import ErrorPanel from "../../components/utils/ErrorPanel";
import HomeLayout1 from "./HomeLayout1";
import HomeNavbarLayout1 from "./HomeNavbarLayout1";
import HomeLayout2 from "./HomeLayout2";
import HomeNavbarLayout2 from "./HomeNavBarLayout2";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const idFromUrl = params.get("id");

  const [newIdFromUrl, setNewIdFromUrl] = useState(null);

  const [whatsappLink, setWhatsappLink] = useState("");

  const [image, setImage] = useState(null);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [localLoading, setLocalLoading] = useState(true);
  const [definedDate, setDefinedDate] = useState(false);
  const [campaignEnded, setCampaignEnded] = useState(false);
  const [endedText, setEndedText] = useState("");
  const [campaignLayout, setCampaignLayout] = useState(null);

  // CronJob
  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendDomain = config.backendDomain;
        const url = `${backendDomain}/backend/src/view/utils/execCronJob.php`;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
          throw new Error("Falha ao chamar o endpoint do cronjob");
        }
      } catch (error) {
        console.error("Erro ao chamar o endpoint do cronjob", error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000); // 60 segundos

    return () => clearInterval(interval);
  }, []);

  const renderLayout = (layout) => {
    switch (layout) {
      case 0:
        return (
          <HomeLayout1
            logoImage={logoImage}
            image={image}
            imageLoaded={imageLoaded}
            handleImageLoaded={handleImageLoaded}
            title={campaignDetails.title}
            topMarginTitle={topMarginTitle.topMargin}
            date={campaignDetails.draw_date}
            price={campaignDetails.priceShare}
            description={campaignDetails.description}
            definedDate={definedDate}
            campaignEnded={campaignEnded}
            campaignEndedText={endedText}
            fullName={fullName}
            setFullName={setFullName}
            email={email}
            setEmail={setEmail}
            termsAgreed={termsAgreed}
            setTermsAgreed={setTermsAgreed}
            quantity={quantity}
            setQuantity={setQuantity}
            formattedFindTicketsPhone={formattedFindTicketsPhone}
            handlePhoneFindTickets={handlePhoneFindTickets}
            formattedPhone={formattedPhone}
            handlePhoneChange={handlePhoneChange}
            reservationPanelRef={reservationPanelRef}
            localLoading={localLoading}
            showFindTicketsPopup={showFindTicketsPopup}
            handleReservation={handleReservation}
            findTicketsPaneltext={findTicketsPaneltext}
            showPopup={showPopup}
            showReservationPanel={showReservationPanel}
            closeReservationPanel={closeReservationPanel}
            updateQuantity={updateQuantity}
            updateQuantitySub={updateQuantitySub}
            maxHeightImage={maxHeightImage.maxHeight}
            topMarginImage={topMarginImage.topMargin}
            imageContainerSize={imageContainerSize.width}
            imageBorderRadius={imageBorderRadius}
            adjustPriceContent={adjustPriceContent}
            redirectToWhatsapp={redirectToWhatsapp}
            redirectToFacebook={redirectToFacebook}
            redirectToTelegram={redirectToTelegram}
            redirectToTwitter={redirectToTwitter}
            updateTotalValue={updateTotalValue}
            openReservationPanel={openReservationPanel}
            findTicketsRef={findTicketsRef}
            handleFindTickets={handleFindTickets}
            width={divSize.width}
            height={divSize.height}
          />
        );
        break;
      case 1:
        return (
          <HomeLayout2
            logoImage={logoImage}
            image={image}
            imageLoaded={imageLoaded}
            handleImageLoaded={handleImageLoaded}
            title={campaignDetails.title}
            topMarginTitle={topMarginTitle.topMargin}
            date={campaignDetails.draw_date}
            price={campaignDetails.priceShare}
            description={campaignDetails.description}
            definedDate={definedDate}
            campaignEnded={campaignEnded}
            campaignEndedText={endedText}
            fullName={fullName}
            setFullName={setFullName}
            email={email}
            setEmail={setEmail}
            termsAgreed={termsAgreed}
            setTermsAgreed={setTermsAgreed}
            quantity={quantity}
            setQuantity={setQuantity}
            formattedFindTicketsPhone={formattedFindTicketsPhone}
            handlePhoneFindTickets={handlePhoneFindTickets}
            formattedPhone={formattedPhone}
            handlePhoneChange={handlePhoneChange}
            reservationPanelRef={reservationPanelRef}
            localLoading={localLoading}
            showFindTicketsPopup={showFindTicketsPopup}
            handleReservation={handleReservation}
            findTicketsPaneltext={findTicketsPaneltext}
            showPopup={showPopup}
            showReservationPanel={showReservationPanel}
            closeReservationPanel={closeReservationPanel}
            updateQuantity={updateQuantity}
            updateQuantitySub={updateQuantitySub}
            maxHeightImage={maxHeightImage.maxHeight}
            topMarginImage={topMarginImage.topMargin}
            imageContainerSize={imageContainerSize.width}
            imageBorderRadius={imageBorderRadius}
            adjustPriceContent={adjustPriceContent}
            redirectToWhatsapp={redirectToWhatsapp}
            redirectToFacebook={redirectToFacebook}
            redirectToTelegram={redirectToTelegram}
            redirectToTwitter={redirectToTwitter}
            updateTotalValue={updateTotalValue}
            openReservationPanel={openReservationPanel}
            findTicketsRef={findTicketsRef}
            handleFindTickets={handleFindTickets}
            togglePopup={togglePopup}
            width={divSize.width}
            height={divSize.height}
          />
        );
        break;
      default:
        return (
          <HomeLayout1
            logoImage={logoImage}
            image={image}
            imageLoaded={imageLoaded}
            handleImageLoaded={handleImageLoaded}
            title={campaignDetails.title}
            topMarginTitle={topMarginTitle.topMargin}
            date={campaignDetails.draw_date}
            price={campaignDetails.priceShare}
            description={campaignDetails.description}
            definedDate={definedDate}
            campaignEnded={campaignEnded}
            campaignEndedText={endedText}
            fullName={fullName}
            setFullName={setFullName}
            email={email}
            setEmail={setEmail}
            termsAgreed={termsAgreed}
            setTermsAgreed={setTermsAgreed}
            quantity={quantity}
            setQuantity={setQuantity}
            formattedFindTicketsPhone={formattedFindTicketsPhone}
            handlePhoneFindTickets={handlePhoneFindTickets}
            formattedPhone={formattedPhone}
            handlePhoneChange={handlePhoneChange}
            reservationPanelRef={reservationPanelRef}
            localLoading={localLoading}
            showFindTicketsPopup={showFindTicketsPopup}
            handleReservation={handleReservation}
            findTicketsPaneltext={findTicketsPaneltext}
            showPopup={showPopup}
            showReservationPanel={showReservationPanel}
            closeReservationPanel={closeReservationPanel}
            updateQuantity={updateQuantity}
            updateQuantitySub={updateQuantitySub}
            maxHeightImage={maxHeightImage.maxHeight}
            topMarginImage={topMarginImage.topMargin}
            imageContainerSize={imageContainerSize.width}
            imageBorderRadius={imageBorderRadius}
            adjustPriceContent={adjustPriceContent}
            redirectToWhatsapp={redirectToWhatsapp}
            redirectToFacebook={redirectToFacebook}
            redirectToTelegram={redirectToTelegram}
            redirectToTwitter={redirectToTwitter}
            updateTotalValue={updateTotalValue}
            openReservationPanel={openReservationPanel}
            findTicketsRef={findTicketsRef}
            handleFindTickets={handleFindTickets}
            width={divSize.width}
            height={divSize.height}
          />
        );
    }
  };

  const renderNavbarLayout = (navbarLayout) => {
    switch (navbarLayout) {
      case 0:
        return <HomeNavbarLayout1 showPopupNav={togglePopup} />;
        break;
      case 1:
        return <HomeNavbarLayout2 showPopupNav={togglePopup} />;
        break;
      default:
        return <HomeNavbarLayout1 showPopupNav={togglePopup} />;
    }
  };

  // Função para lidar com o carregamento da imagem
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const getWhatsappLink = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/utils/getWhatsappLink.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do WhatsApp");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        return data.value;
      } else {
        setFindTicketsPaneltext("Whatsapp indisponivel");
        setShowFindTicketsPopup(true);
        setTimeout(() => {
          setShowFindTicketsPopup(false);
        }, 2000); // 2 segundos
        throw new Error("Link do WhatsApp não disponível");
      }
    } catch (error) {
      setFindTicketsPaneltext("Whatsapp indisponivel");
      setShowFindTicketsPopup(true);
      setTimeout(() => {
        setShowFindTicketsPopup(false);
      }, 2000); // 2 segundos
      console.error("Erro ao obter o link do WhatsApp:", error);
      return null;
    }
  };

  const redirectToWhatsapp = async () => {
    try {
      const whatsappLink = await getWhatsappLink();
      if (whatsappLink) {
        window.open(whatsappLink, "_blank");
      } else {
        console.error("Link do WhatsApp não disponível");
      }
    } catch (error) {
      console.error("Erro ao redirecionar para o WhatsApp:", error);
    }
  };

  const getFacebookLink = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/utils/getFacebookLink.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do Facebook");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        return data.value;
      } else {
        setFindTicketsPaneltext("Facebook indisponivel");
        setShowFindTicketsPopup(true);
        setTimeout(() => {
          setShowFindTicketsPopup(false);
        }, 2000); // 2 segundos
        throw new Error("Link do Facebook não disponível");
      }
    } catch (error) {
      setFindTicketsPaneltext("Facebook indisponivel");
      setShowFindTicketsPopup(true);
      setTimeout(() => {
        setShowFindTicketsPopup(false);
      }, 2000); // 2 segundos
      console.error("Erro ao obter o link do Facebook:", error);
      return null;
    }
  };

  const redirectToFacebook = async () => {
    try {
      const facebookLink = await getFacebookLink();
      if (facebookLink) {
        window.open(facebookLink, "_blank");
      } else {
        console.error("Link do Facebook não disponível");
      }
    } catch (error) {
      console.error("Erro ao redirecionar para o Facebook:", error);
    }
  };

  const getTelegramLink = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/utils/getTelegramLink.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do Telegram");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        return data.value;
      } else {
        setFindTicketsPaneltext("Telegram indisponivel");
        setShowFindTicketsPopup(true);
        setTimeout(() => {
          setShowFindTicketsPopup(false);
        }, 2000); // 2 segundos
        throw new Error("Link do Telegram não disponível");
      }
    } catch (error) {
      setFindTicketsPaneltext("Telegram indisponivel");
      setShowFindTicketsPopup(true);
      setTimeout(() => {
        setShowFindTicketsPopup(false);
      }, 2000); // 2 segundos
      console.error("Erro ao obter o link do Telegram:", error);
      return null;
    }
  };

  const redirectToTelegram = async () => {
    try {
      const telegramLink = await getTelegramLink();
      if (telegramLink) {
        window.open(telegramLink, "_blank");
      } else {
        console.error("Link do Telegram não disponível");
      }
    } catch (error) {
      console.error("Erro ao redirecionar para o Telegram:", error);
    }
  };

  const getTwitterLink = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/utils/getTwitterLink.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do Twitter");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        return data.value;
      } else {
        setFindTicketsPaneltext("Twitter indisponivel");
        setShowFindTicketsPopup(true);
        setTimeout(() => {
          setShowFindTicketsPopup(false);
        }, 2000); // 2 segundos
        throw new Error("Link do Twitter não disponível");
      }
    } catch (error) {
      setFindTicketsPaneltext("Twitter indisponivel");
      setShowFindTicketsPopup(true);
      setTimeout(() => {
        setShowFindTicketsPopup(false);
      }, 2000); // 2 segundos
      console.error("Erro ao obter o link do Twitter:", error);
      return null;
    }
  };

  const redirectToTwitter = async () => {
    try {
      const twitterLink = await getTwitterLink();
      if (twitterLink) {
        window.open(twitterLink, "_blank");
      } else {
        console.error("Link do Twitter não disponível");
      }
    } catch (error) {
      console.error("Erro ao redirecionar para o Twitter:", error);
    }
  };

  //

  let maxHeightHeight;

  const [showFindTicketsPopup, setShowFindTicketsPopup] = useState(false);

  const [findTicketsPaneltext, setFindTicketsPaneltext] = useState("");

  const handleFindTickets = async () => {
    const validatePhoneNumber = async () => {
      try {
        const formData = new URLSearchParams();

        formData.append("phone_number", findTicketsPhone);

        const response = await fetch(
          `${config.backendDomain}/backend/src/view/home/purchase/checkExistsPurchases.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.status === "success") {
            console.log(data.value.status);
            return data.value.status;
          } else {
            console.log("erro na validação do telefone");

            setShowFindTicketsPopup(true);
            switch (data.code) {
              case "0x0001":
                setFindTicketsPaneltext("Insira um número de telefone");
                break;
              case "0x0002":
                setFindTicketsPaneltext("Tag é muito grande");
                break;
              case "0x0003":
                setFindTicketsPaneltext("Compra pendente não encontrada");
                break;
              default:
                setFindTicketsPaneltext("Número de telefone não encontrado");
                break;
            }
            // console.log("Compra bem-sucedida:", data.value);
            // Lidar com erros específicos, se necessário
          }
        } else {
          setShowFindTicketsPopup(true);
          console.error("Erro na solicitação:", response.status);
          setFindTicketsPaneltext("Erro na solicitação");
          setShowFindTicketsPopup(true);
          // Lidar com erros de rede ou outros erros relacionados à solicitação
        }
      } catch (error) {
        setFindTicketsPaneltext("Erro na solicitação");
        setShowFindTicketsPopup(true);
        console.error(error);
      }
    };

    const isValidPhoneNumber = await validatePhoneNumber();

    if (isValidPhoneNumber) {
      console.log("valid phone number");
      navigate(
        `/buscaDeBilhetes/?telefone=${findTicketsPhone}&pagina=${1}/?lastId=${idFromUrl}`
      );
    }

    if (findTicketsPhone !== "" && isValidPhoneNumber == false) {
      console.log("invalid phone number");
      setShowFindTicketsPopup(true);
    }

    setTimeout(() => {
      setShowFindTicketsPopup(false);
    }, 2000); // 2 segundos
  };

  //

  const [campaignDetails, setCampaignDetails] = useState({
    title: "",
    description: "",
    shares: "",
    priceShare: "",
    draw_date: "",
  });

  const [minTickets, setMinTickets] = useState(null);
  const [maxTickets, setMaxTickets] = useState(null);

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      try {
        const backendDomain = config.backendDomain;

        const urlCheckCampaign2 = `${backendDomain}/backend/src/view/home/campaing/getCampaingFromHome.php`;

        const requestOptionsCheckCampaign2 = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const responseCheckCampaign2 = await fetch(
          urlCheckCampaign2,
          requestOptionsCheckCampaign2
        );

        if (responseCheckCampaign2.ok) {
          const dataCheckCampaign2 = await responseCheckCampaign2.json();
          const n = dataCheckCampaign2.id;
          setNewIdFromUrl(n);
          console.log("new id", n);
          console.log(newIdFromUrl);
          if (!idFromUrl && location.pathname === "/") {
            // navigate(`/?id=${newIdFromUrl}`);
          }
          // navigate(`/?id=${newIdFromUrl}`);
        }

        const urlCheckCampaign = `${backendDomain}/backend/src/view/home/campaing/checkCampaingExists.php`;

        const requestOptionsCheckCampaign = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `campaing_id=${idFromUrl}`,
        };

        const responseCheckCampaign = await fetch(
          urlCheckCampaign,
          requestOptionsCheckCampaign
        );

        if (responseCheckCampaign.ok) {
          const dataCheckCampaign = await responseCheckCampaign.json();

          if ((!idFromUrl && location.pathname === "/") || idFromUrl === 0) {
            //navigate(`/?id=${newIdFromUrl}`);
          }

          if (!idFromUrl) {
            if (newIdFromUrl != null) navigate(`/?id=${newIdFromUrl}`);
            console.log("idFromUrl", newIdFromUrl);
          } else {
            if (dataCheckCampaign.value.status === "false") {
              navigate("/campanhaNaoEncontrada");
            }
          }
          // navigate("campanhaNaoEncontrada");
        }

        const url = `${backendDomain}/backend/src/view/home/campaing/getCampaing.php`;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `campaing_id=${idFromUrl}`, // Corpo da requisição no formato application/x-www-form-urlencoded
        };

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
          throw new Error("Falha ao obter detalhes do servidor");
        }

        const data = await response.json();
        console.log("data json" + data.value.description);

        setMinTickets(data.value.min_tickets);
        setMaxTickets(data.value.max_tickets);

        // Analisar os dados como application/x-www-form-urlencoded
        /* const formDataObject = new URLSearchParams(formData);
        const data = {};
        formDataObject.forEach((value, key) => {
          data[key] = value;
        }); */

        setCampaignDetails({
          title:
            data.value.title || "title não carregado, valor indefinido ou nulo",
          description:
            data.value.description ||
            "description não carregado, valor indefinido ou nulo",
          shares:
            data.value.shares ||
            "shares não carregado, valor indefinido ou nulo",
          priceShare: data.value.priceShare / 100 || 0,
          draw_date:
            data.value.finish_date ||
            "draw_date não carregado, valor indefinido ou nulo",
        });

        setCampaignLayout(data.value.layout_id);

        if (data.value.status == 0) {
          setCampaignEnded(false);
          setEndedText("ENCERRA EM");
        } else if (data.value.status == 1) {
          setCampaignEnded(true);
          setEndedText("ENCERRADO EM");
        }

        if (data.value.date_defined == 0) setDefinedDate(false);
        else if (data.value.date_defined == 1) setDefinedDate(true);

        setLocalLoading(false);

        const formData = new URLSearchParams();

        formData.append("campaing_id", idFromUrl);
        formData.append("image_id", 1);
        const imageResponse = await fetch(
          `${config.backendDomain}/backend/src/view/home/campaing/getCampaingImage.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
          }
        );

        if (imageResponse.ok) {
          const imageData = await imageResponse.blob();
          const imageUrl = URL.createObjectURL(imageData);
          console.log("URL: " + imageUrl);
          setImage(imageUrl);
          // return { imageUrl };
        } else {
          console.error(
            `Erro ao carregar imagem para a campanha com ID ${idFromUrl}`
          );
        }

        console.log("Status do Endpoint:", data.status);
      } catch (error) {
        console.error("Erro ao acessar o endpoint do backend:", error.message);
      }
    };

    //if (!idFromUrl) {
    //navigate(`/?id=${newIdFromUrl}`);
    //} else if (idFromUrl) {
    fetchCampaignDetails();
    //}
  }, [idFromUrl, newIdFromUrl]);
  //

  const [divSize, setDivSize] = useState({
    width: "100%",
    height: "100%",
  });

  const [imageContainerSize, setImageContainerSize] = useState({
    width: "100%",
    height: "100%",
  });

  const [maxHeightImage, setMaxHeightImage] = useState({
    maxHeight: "50vw",
  });

  const [topMarginImage, setTopMarginImage] = useState({
    topMargin: "0px",
  });

  const [adjustPriceContent, setAdjustPriceContent] = useState({
    left: "0",
    marginLeft: "0",
    bottom: "0",
    marginBottom: "-20px",
    width: "85%",
  });

  const [topMarginTitle, setTopMarginTitle] = useState({
    topMargin: "",
  });

  const [imageBorderRadius, setImageBorderRadius] = useState(0);

  //pixel
  const [pixelId, setPixelId] = useState("");

  const getPixelId = async () => {
    try {
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/home/utils/getPixelId.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        setPixelId(data.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const adjustDivSize = () => {
      const isDesktop = window.innerWidth > 925; // default - 768
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      // Definindo uma proporção máxima para a altura em relação à largura
      const maxAspectRatio = 1.5; // Ajuste conforme necessário

      // Definindo o limite máximo para a altura da imagem
      const maxHeightLimit = 600; // Ajuste conforme necessário

      // Calculando a altura máxima com base na largura e altura da tela
      let maxHeightWidth = windowWidth * maxAspectRatio;
      let maxHeightHeight;

      if (windowWidth < 475) {
        maxHeightHeight = windowHeight * 0.3; // Porcentagem da altura da tela
        setTopMarginImage({
          topMargin: "0px",
        });
      } else {
        maxHeightHeight = windowHeight * 0.8; // Porcentagem da altura da tela
      }

      if (isDesktop) {
        setDivSize({
          width: "950px", // default - 400px
          height: "100%",
        });
        setImageContainerSize({
          width: "calc(88% + 20px)",
        });
        setTopMarginImage({
          topMargin: "74px", // default - 32px
        });
        setAdjustPriceContent({
          left: "0",
          marginLeft: "60px",
          bottom: "0",
          marginBottom: "15px",
          width: "250px",
        });
        setTopMarginTitle({
          topMargin: "0px",
        });
        setImageBorderRadius("16px");
        maxHeightHeight = windowHeight * 0.8; // Porcentagem da altura da tela
      } else {
        setDivSize({
          width: "100%",
          height: "100%",
        });
        setImageContainerSize({
          width: "100%",
        });
        setTopMarginImage({
          topMargin: "60px",
        });
        setAdjustPriceContent({
          left: "",
          marginLeft: "",
          bottom: "",
          marginBottom: "-20px",
          width: "85%",
        });
        setTopMarginTitle({
          topMargin: "16px",
        });
        setImageBorderRadius("0px");
      }

      // Escolhendo o menor valor entre a altura máxima calculada com base na largura e na altura
      let maxHeight = Math.min(maxHeightWidth, maxHeightHeight, maxHeightLimit);

      // Definindo o tamanho da imagem com base na altura máxima calculada
      setMaxHeightImage({
        maxHeight: `${maxHeight}px`,
      });
    };

    window.addEventListener("resize", adjustDivSize);

    adjustDivSize();
    getPixelId();
    console.log("pixel test" + pixelId);
    return () => {
      window.removeEventListener("resize", adjustDivSize);
    };
  }, []);

  //

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [campanhas, setCampanhas] = useState([]);
  const [loading, setLoading] = useState(true);

  const [quantity, setQuantity] = useState(1);
  const quotaValue = campaignDetails.priceShare;

  useEffect(() => {
    if (quantity >= maxTickets) {
      setQuantity(maxTickets);
    }
    if (quantity <= 1) {
      setQuantity(1);
    }
  }, [quantity]);

  const updateQuantity = (value) => {
    if (quantity < maxTickets) {
      const newQuantity = Math.max(quantity + value, 0);
      setQuantity(newQuantity);
    }

    if (quantity >= maxTickets - 1 && !showErrorPanel) {
      setFindTicketsPaneltext(
        `O limite de bilhetes por cliente é ${maxTickets}`
      );
      setShowFindTicketsPopup(true);
      setTimeout(() => {
        setShowFindTicketsPopup(false);
      }, 1500);
    }
  };

  const updateQuantitySub = (value) => {
    if (quantity > 1) {
      const newQuantity = Math.max(quantity - value, 0);
      setQuantity(newQuantity);
    }
  };

  const updateTotalValue = () => {
    /* return (quantity * quotaValue).toFixed(1);*/
    return formatNumber(quantity * quotaValue);
  };

  const reserveTickets = () => {
    // Implemente a lógica para reservar os bilhetes, se necessário
    alert(
      `Bilhetes reservados: ${quantity}\nValor total: R$${updateTotalValue()}`
    );
  };

  // Recupera o estado do painel de reserva do armazenamento local
  const storedConfirmationPanelState = sessionStorage.getItem(
    "confirmationPanelState"
  );
  const initialConfirmationPanelState = storedConfirmationPanelState
    ? JSON.parse(storedConfirmationPanelState)
    : false;
  const [showReservationPanel, setShowReservationPanel] = useState(false);
  const [showMinTicketsPanel, setShowMinTicketsPanel] = useState(false);
  const [showErrorPanel, setShowErrorPanel] = useState(false);
  const [errorPanelText, setErrorPanelText] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [showConfirmationPanel, setShowConfirmationPanel] = useState(
    initialConfirmationPanelState
  );
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [findTicketsPhone, setFindTicketsPhone] = useState("");
  // Estado para armazenar o número de telefone formatado
  const [formattedPhone, setFormattedPhone] = useState("");
  const [formattedFindTicketsPhone, setFormattedFindTicketsPhone] =
    useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);

  const formatPhoneNumber = (phoneNumber) => {
    // Remove caracteres não numéricos do número de telefone
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    // Aplica a formatação desejada
    const formattedPhoneNumber = cleanedPhoneNumber.replace(
      /(\d{2})(\d{5})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  // Manipulador de eventos para formatar o número de telefone enquanto o usuário digita
  const handlePhoneChange = (e) => {
    let inputPhone = e.target.value;

    // Verifica se há dados colados
    const clipboardData = e.clipboardData || window.clipboardData;
    if (clipboardData && clipboardData.getData) {
      const pastedText = clipboardData.getData("Text");
      // Concatena o texto colado ao valor atual do número de telefone
      inputPhone += pastedText;
    }

    // Remove caracteres não numéricos do número de telefone
    const cleanedPhoneNumber = inputPhone.replace(/\D/g, "");
    // Aplica a formatação desejada
    const formattedPhoneNumber = formatPhoneNumber(cleanedPhoneNumber);

    // Atualiza o estado com o número de telefone formatado
    setFormattedPhone(formattedPhoneNumber);
    // Atualiza o estado com o número de telefone não formatado
    setPhone(cleanedPhoneNumber);
  };

  const handlePhoneFindTickets = (e) => {
    const inputPhone = e.target.value;
    const formatted = formatPhoneNumber(inputPhone);
    setFormattedFindTicketsPhone(formatted);
    setFindTicketsPhone(inputPhone);
  };

  const [paymentTimer, setPaymentTimer] = useState(1800);

  const reservationPanelRef = useRef(null);
  const findTicketsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        reservationPanelRef.current &&
        !reservationPanelRef.current.contains(event.target)
      ) {
        // Fecha o painel de reserva se o clique foi fora da área do painel
        setShowReservationPanel(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [reservationPanelRef]);

  useEffect(() => {
    const handleClickOutsideFindTickets = (event) => {
      if (
        findTicketsRef.current &&
        !findTicketsRef.current.contains(event.target)
      ) {
        // Fecha o painel
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideFindTickets);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFindTickets);
    };
  }, [findTicketsRef]);

  /*useEffect(() => {
    // Contador de tempo
    const timer = setInterval(() => {
      setPaymentTimer((prevTimer) => {
        const newTimer = prevTimer - 1;
        localStorage.setItem("paymentTimer", newTimer);
        return newTimer;
      });
    }, 1000);

    // Atualizações quando o tempo se esgota
    if (paymentTimer === 0) {
      clearInterval(timer);
      // Adicione lógica para o que deseja fazer quando o tempo se esgotar
    }

    return () => clearInterval(timer);
  }, [paymentTimer]); */

  const openReservationPanel = () => {
    // Verifica se o número mínimo de bilhetes é atendido
    if (quantity >= minTickets) {
      setShowReservationPanel(true);
      closeMinTicketsPanel();

      //pixel InitiateCheckout
      const scriptElement = document.createElement("script");
      scriptElement.textContent = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${pixelId});
        fbq('track', 'InitiateCheckout', {
          content_ids: ['${idFromUrl}'],
          content_type: 'product',
          value: ['${campaignDetails.priceShare * quantity}'],
          currency: 'BRL',
          num_items: ${quantity}
        });
      `;
      document.body.appendChild(scriptElement);
    } else {
      // Se não atender, mostra o painel alternativo por 3 segundos
      setFindTicketsPaneltext(
        `O número mínimo de bilhetes precisa ser ${minTickets}`
      );
      setShowFindTicketsPopup(true);
      setTimeout(() => {
        setShowFindTicketsPopup(false);
      }, 2000); // 2 segundos
    }
  };

  const closeReservationPanel = () => {
    setShowReservationPanel(false);
  };

  const closeMinTicketsPanel = () => {
    setShowMinTicketsPanel(false);
  };

  const handleReservation = async () => {
    // Validar os campos antes de processar a reserva

    const errors = validateFields();

    if (Object.keys(errors).length > 0) {
      let errorMessage = "";

      if (Object.keys(errors).length === 4) {
        errorMessage = "Preencha todos os campos corretamente";
        if (!showFindTicketsPopup) {
          setShowFindTicketsPopup(true);
          setTimeout(() => {
            setShowFindTicketsPopup(false);
          }, 2000); // 2 segundos
        }
      } else {
        if (errors.fullName) {
          errorMessage += `${errors.fullName}`;
        }
        if (errors.phone) {
          errorMessage += `${errors.phone}`;
        }
        if (errors.email) {
          errorMessage += `${errors.email}`;
        }
        if (errors.termsAgreed) {
          errorMessage += `${errors.termsAgreed}`;
        }

        if (!showFindTicketsPopup) {
          setShowFindTicketsPopup(true);
          setTimeout(() => {
            setShowFindTicketsPopup(false);
          }, 4000); // 4 segundos
        }
      }

      console.log(errorMessage); // Verificar se a mensagem de erro está correta
      setFindTicketsPaneltext(errorMessage);
      return;
    }

    if (!showFindTicketsPopup) {
      setFindTicketsPaneltext("Reservando bilhetes...");
      setShowFindTicketsPopup(true);
    }

    /*if (validateFields()) { */
    //pixel AddPaymentInfo
    const scriptElement = document.createElement("script");
    scriptElement.textContent = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${pixelId});
        fbq('track', 'AddPaymentInfo', {
          content_ids: ['${idFromUrl}'],
          content_category: 'product',
          value: ['${campaignDetails.priceShare * quantity}'],
          currency: 'BRL'
        });
      `;
    document.body.appendChild(scriptElement);

    // localStorage.setItem("confirmationPanelState", JSON.stringify(true));
    // Implemente a lógica para processar a reserva aqui
    // Pode ser uma chamada para o backend, por exemplo
    console.log("Reservando bilhetes...");
    // Após a reserva, você pode redefinir os campos e fechar o painel
    /* setFullName("");
      setPhone("");
      setTermsAgreed(false); */
    // Exibir painel de confirmação
    // setShowConfirmationPanel(true);
    closeReservationPanel();

    try {
      const formData = new URLSearchParams();

      formData.append("campaing_id", idFromUrl);
      formData.append("user_name", fullName);
      formData.append("phone_number", phone);
      formData.append("shares", quantity);
      formData.append("email", email);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/purchase/purchaseCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.status === "success") {
          setErrorCode(data.code);
          console.log("error code", errorCode);
          //pixel AddToCart
          const scriptElement = document.createElement("script");
          scriptElement.textContent = `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${pixelId});
              fbq('track', 'AddToCart', {
                content_type: 'produto',
                contents: [
                  { id: ${idFromUrl}, quantity: ${quantity}}
                ],
                currency: 'BRL'
              });
            `;
          document.body.appendChild(scriptElement);
          console.log("Compra bem-sucedida:", data.value.free);

          // Se é uma campanha gratuita ir direto para pagina de pagamento concluido
          if (data.value.free) {
            navigate("/pagamentos/pagamentoConcluido");
          } else {
            navigate(`/pagamentos/?tag=${data.value.tag}&lastId=${idFromUrl}`);
          }
        } else {
          console.log("Erro ao processar compra:", data.code);
          setErrorCode(data.code);
          console.log("error code", errorCode);
          setShowFindTicketsPopup(true);

          switch (data.code) {
            case "0x0001":
              setFindTicketsPaneltext("O id da campanha é nulo");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0002":
              setFindTicketsPaneltext("O id da campanha é muito grande");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0003":
              setFindTicketsPaneltext("O nome do usuario é nulo");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0004":
              setFindTicketsPaneltext("O nome do usuario é muito grande");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0005":
              setFindTicketsPaneltext("O número de telefone é nulo");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0006":
              setFindTicketsPaneltext("O número de telefone é muito grande");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0007":
              setFindTicketsPaneltext("Bilhetes esgotados"); // O número de bilhetes é nulo
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0008":
              setFindTicketsPaneltext("O número de bilhetes é muito grande");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0009":
              setFindTicketsPaneltext("Erro interno no servidor");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0010":
              setFindTicketsPaneltext("O email é nulo");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0011":
              setFindTicketsPaneltext("O email é muito grande");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0012":
              setFindTicketsPaneltext("Campanha inexistente");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0013":
              setFindTicketsPaneltext("Campanha finalizada");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0014":
              setFindTicketsPaneltext("Quantidade de bilhetes excedida");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0015":
              setFindTicketsPaneltext(
                "Quantidade de bilhetes abaixo do mínimo"
              );
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "0x0016":
              setFindTicketsPaneltext(
                "Campanha limitada a 1 número de telefone"
              );
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            case "1x0017":
              setFindTicketsPaneltext("Preço da campanha indefinido");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
            default:
              setFindTicketsPaneltext("Erro desconhecido");
              setShowFindTicketsPopup(true);
              setTimeout(() => {
                setShowFindTicketsPopup(false);
              }, 1500); // 2 segundos
              break;
          }

          // console.log("Compra bem-sucedida:", data.value);
          // Lidar com erros específicos, se necessário
        }
      } else {
        console.error("Erro na solicitação:", response.status);
        setFindTicketsPaneltext("Erro ao solicitar compra");
        setShowFindTicketsPopup(true);
        setTimeout(() => {
          setShowFindTicketsPopup(false);
          setShowErrorPanel(false);
        }, 2000); // 2 segundos
        // Lidar com erros de rede ou outros erros relacionados à solicitação
      }
    } catch (error) {
      console.error("Erro ao solicitar compra:", error);
      setFindTicketsPaneltext("Erro ao solicitar compra");
      setShowFindTicketsPopup(true);
      setTimeout(() => {
        setShowFindTicketsPopup(false);
        setShowErrorPanel(false);
      }, 2000); // 2 segundos
    }
    /* } else {
      // Se os campos não forem válidos, mostra o painel de erro por 2 segundos
      setErrorPanelText("Por favor preencha todos os campos corretamente");
      setShowErrorPanel(true);
      setTimeout(() => {
        setShowErrorPanel(false);
      }, 2000); // 2 segundos
    } */
  };

  // Função para validar um endereço de e-mail
  const validateEmail = (email) => {
    // Regex para validar o formato do e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    // Validar campos (adapte conforme necessário)
    /* const isValidFullName = fullName.length >= 3;
    const isValidPhone = /^\d{2}\d{9}$/.test(phone); // Verifica se o número possui 11 dígitos
    const isValidEmail = validateEmail(email); // Validar e-mail
    const isValidTerms = termsAgreed;

    return isValidFullName && isValidPhone && isValidEmail && isValidTerms; */
    const errors = {};

    // Validar campos (adapte conforme necessário)
    if (fullName.length < 3) {
      errors.fullName = "O nome completo deve ter pelo menos 3 caracteres\n";
    }

    if (!/^\d{2}\d{9}$/.test(phone)) {
      errors.phone = "O número de telefone deve conter 11 dígitos\n";
    }

    if (!validateEmail(email)) {
      errors.email = "O endereço de e-mail é inválido\n";
    }

    if (!termsAgreed) {
      errors.termsAgreed = "Você deve concordar com os termos\n";
    }

    return errors;
  };

  return (
    <div className="home-main-class">
      {localLoading && <HomeLoading />}

      <div>
        {pixelId && idFromUrl && (
          <Helmet>
            <script>{`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${pixelId});
                fbq('track', 'ViewContent', {
                  content_ids: ['${idFromUrl}'],
                  content_type: 'product',
                  value: ['${campaignDetails.priceShare}'],
                  currency: 'BRL'
                });
              `}</script>
          </Helmet>
        )}
      </div>

      {renderLayout(campaignLayout)}
      {renderNavbarLayout(campaignLayout)}
    </div>
  );
};

export default Home;
