import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaPix, FaClover } from "react-icons/fa6";
import { BsFire } from "react-icons/bs";
import { formatNumber } from "../../components/utils/Utils";
import "./HomeLayout1.css";

const HomeLayout1 = ({
  logoImage,
  image,
  imageLoaded,
  handleImageLoaded,
  title,
  topMarginTitle,
  date,
  price,
  description,
  definedDate,
  campaignEnded,
  campaignEndedText,
  fullName,
  setFullName,
  email,
  setEmail,
  termsAgreed,
  setTermsAgreed,
  quantity,
  setQuantity,
  formattedFindTicketsPhone,
  handlePhoneFindTickets,
  formattedPhone,
  handlePhoneChange,
  reservationPanelRef,
  localLoading,
  showFindTicketsPopup,
  handleReservation,
  findTicketsPaneltext,
  showPopup,
  showReservationPanel,
  closeReservationPanel,
  updateQuantity,
  updateQuantitySub,
  maxHeightImage,
  topMarginImage,
  imageContainerSize,
  imageBorderRadius,
  adjustPriceContent,
  redirectToWhatsapp,
  redirectToFacebook,
  redirectToTelegram,
  redirectToTwitter,
  updateTotalValue,
  openReservationPanel,
  findTicketsRef,
  handleFindTickets,
  width,
  height,
}) => {
  return (
    <div className="home_layout_1_main">
      {!localLoading && (
        <div
          className="Home-class"
          style={{
            width: width,
            height: height,
          }}
        >
          <div className="div-1">
            <div
              className="img-container"
              style={{
                maxHeight: maxHeightImage,
                marginTop: topMarginImage,
                width: imageContainerSize,
              }}
            >
              {!imageLoaded && <div className="loading-circle"></div>}
              <img
                src={image}
                className={imageLoaded ? "image-loaded" : "image-hidden"}
                onLoad={handleImageLoaded}
                style={{ borderRadius: imageBorderRadius }}
              />
            </div>
            <div className="price" style={adjustPriceContent}>
              <span className="price-content-text">Participe por apenas </span>
              <span className="price-value">
                R${" "}
                {price <= 0 || price == NaN
                  ? formatNumber(0)
                  : formatNumber(price)}
              </span>
              <div className="fire-icon-div">
                <BsFire className="fire-icon" />
              </div>
            </div>
          </div>

          <div className="div-2">
            <div className="title-main-home">
              <div className="sub-main-title-home">
                <h3
                  className="title"
                  style={{
                    marginTop: topMarginTitle,
                  }}
                >
                  {title}
                </h3>
                {(definedDate || campaignEnded) && (
                  <div className="final-date">
                    <p className="final-date-text">
                      {campaignEndedText} {date}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="info-home">
              <div className="sub-image-main">
                <img className="sub-image" src={logoImage}></img>
              </div>
              <div className="sub-content">
                <div className="org-main">
                  <div className="org">
                    <h5>Organizado por:</h5>
                  </div>
                  <div className="org-name">
                    <p>Açãozinha Solemar </p>
                  </div>
                </div>
                <div className="suport-main">
                  <button
                    className="whats-btn"
                    onClick={() => redirectToWhatsapp()}
                    disabled={showFindTicketsPopup}
                  >
                    <FaWhatsapp className="whatsapp-icon" />
                    Suporte
                  </button>
                </div>
              </div>
            </div>

            {!campaignEnded && (
              <div className="description-main-home">
                <div className="description-content-title">
                  <h4>DESCRIÇÃO / REGULAMENTO</h4>
                </div>
                <div className="description-content">
                  <p className="description-text">
                    {description.includes("\n")
                      ? description.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                      : description}
                  </p>
                </div>
              </div>
            )}
            <div></div>
          </div>

          {!campaignEnded && (
            <div className="div-3">
              <div className="buy-area">
                <div className="quotas-title">
                  <h4>Selecione a quantidade de bilhetes</h4>
                </div>
                <div className="quotas-buy-main">
                  <div className="quotas-buy">
                    <div className="ticket-options">
                      <button
                        className="btn-1"
                        onClick={() => updateQuantity(1)}
                        disabled={showFindTicketsPopup}
                      >
                        +1
                      </button>
                      <button
                        className="btn-1"
                        onClick={() => updateQuantity(5)}
                        disabled={showFindTicketsPopup}
                      >
                        +5
                      </button>
                      <button
                        className="btn-1"
                        onClick={() => updateQuantity(10)}
                        disabled={showFindTicketsPopup}
                      >
                        +10
                      </button>
                      <button
                        className="btn-1"
                        onClick={() => updateQuantity(100)}
                        disabled={showFindTicketsPopup}
                      >
                        +100
                      </button>
                    </div>

                    <div className="quantity-display">
                      <div className="action-buttons">
                        <button
                          className="btn-sub"
                          onClick={() => updateQuantitySub(1)}
                          disabled={showFindTicketsPopup}
                        >
                          -
                        </button>
                        <input
                          className="quantity-buy"
                          type="number"
                          value={quantity}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (!isNaN(value)) {
                              // Verifica se é um número válido
                              setQuantity(Math.max(value, 0)); // Limita o mínimo valor a 0
                            }
                          }}
                        ></input>
                        <button
                          className="btn-sub"
                          onClick={() => updateQuantity(1)}
                          disabled={showFindTicketsPopup}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    <div id="final-value-main">
                      <div className="final-value">Valor final</div>
                      <div className="value">R$ {updateTotalValue()}</div>
                    </div>

                    <button
                      id="reserve-button"
                      onClick={openReservationPanel}
                      disabled={showFindTicketsPopup}
                    >
                      RESERVAR
                    </button>
                  </div>
                </div>
              </div>
              <div className="main-bottom">
                <div className="bottom-1">
                  <div className="payment-method">
                    <h5>MEIO DE PAGAMENTO</h5>
                  </div>
                  <div className="method">
                    <p>
                      <FaPix className="pix-icon" /> PIX
                    </p>
                  </div>
                </div>
                <div className="bottom-2">
                  <div className="draw">
                    <h5>SORTEIO</h5>
                  </div>
                  <div className="draw-method">
                    <p>
                      <FaClover className="clover-icon" /> LOTERIA FEDERAL
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showFindTicketsPopup && (
            <div className="main-find-tickets-popup-error">
              <div className="sub-main-find-tickets-popup-error">
                <h2>
                  {findTicketsPaneltext.includes("\n")
                    ? findTicketsPaneltext.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          {index <
                            findTicketsPaneltext.split("\n").length - 1 && (
                            <br />
                          )}{" "}
                          {/* Adiciona quebra de linha apenas se houver outro texto abaixo */}
                        </React.Fragment>
                      ))
                    : findTicketsPaneltext}
                </h2>
              </div>
            </div>
          )}

          {showPopup && (
            <div className="main-find-tickets-popup">
              <div className="find-tickets-popup" ref={findTicketsRef}>
                <div className="find-tickets-popup-top">
                  <h3 className="find-tickets-popup-title">Buscar bilhetes</h3>
                </div>
                <div className="find-tickets-popup-middle">
                  <div className="find-tickets-popup-middle-top">
                    <p>Telefone</p>
                  </div>
                  <div className="find-tickets-popup-middle-down">
                    <input
                      type="text"
                      placeholder="Telefone / WhatsApp"
                      className="find-tickets-input-number"
                      value={formattedFindTicketsPhone}
                      onChange={handlePhoneFindTickets}
                      maxLength={11}
                    ></input>
                  </div>
                </div>
                <div className="find-tickets-popup-down">
                  <button
                    onClick={() => handleFindTickets()}
                    disabled={showFindTicketsPopup}
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          )}

          {showReservationPanel && (
            <div className="main-reservation-panel">
              <div className="reservation-panel" ref={reservationPanelRef}>
                <div className="panel-content">
                  <h3>Reservar Bilhetes</h3>
                  <label>
                    <input
                      type="text"
                      value={fullName}
                      placeholder="Nome completo"
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </label>
                  <label>
                    <input
                      type="text"
                      value={email}
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </label>
                  <label>
                    <input
                      type="text"
                      value={formattedPhone}
                      placeholder="Telefone"
                      onChange={handlePhoneChange}
                      maxLength={11}
                    />
                  </label>
                  <label className="check-box-terms-label">
                    <input
                      className="check-box-terms"
                      type="checkbox"
                      checked={termsAgreed}
                      onChange={() => setTermsAgreed(!termsAgreed)}
                    />
                    Li e concordo com os termos e condições de uso
                  </label>
                  <div className="panel-buttons">
                    <button
                      className="cancel-btn"
                      onClick={closeReservationPanel}
                    >
                      Cancelar
                    </button>
                    <button
                      className="confirm-btn"
                      onClick={handleReservation}
                      disabled={showFindTicketsPopup}
                    >
                      Reservar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {showMinTicketsPanel && (
            <div className="min-tickets-panel">
              <p>O número mínimo de bilhetes precisa ser {minTickets}</p>
            </div>
          )}

          {showErrorPanel && (
              <div className="error-panel">
                  <p>
                      {errorPanelText.includes('\n') ? (
                          errorPanelText.split('\n').map((line, index) => (
                              <React.Fragment key={index}>
                                  {line}
                                  {index < errorPanelText.split('\n').length - 1 && <br />} {/* Adiciona quebra de linha apenas se houver outro texto abaixo
                              </React.Fragment>
                          ))
                      ) : (
                          errorPanelText
                      )}
                  </p>
              </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default HomeLayout1;
