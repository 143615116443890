import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import config from "../../config";
import "./PaymentCompleted.css";
import { Helmet } from "react-helmet";

const PaymentCompleted = () => {

  const navigate = useNavigate();

  const [pixelId, setPixelId] = useState("");

  const getValidId = async () => {
    try {
      const backendDomain = config.backendDomain;

      const urlCheckCampaign = `${backendDomain}/backend/src/view/home/campaing/getCampaingFromHome.php`;

      const requestOptionsCheckCampaign = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const responseCheckCampaign = await fetch(
        urlCheckCampaign,
        requestOptionsCheckCampaign
      );  

      if (responseCheckCampaign.ok) {
        const data = await responseCheckCampaign.json();
        // console.log("Id valido", data.value.id);
        return data.id;
      }
    } catch (error) {}
  };

  //pixel
  const getPixelId = async () => {
    try {
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/home/utils/getPixelId.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        setPixelId(data.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRedirect = async () => {
    try {
      const validId = await getValidId();
      console.log(validId);
      navigate(`/?id=${validId}`);
    } catch (error) {
      console.error("Erro ao obter ID válido:", error);
    }
  };

  useEffect(() => {
    getPixelId();
  }, []);
  
  return(
    <div className="main-campaign-not-found">
      <div>
        {pixelId &&(
          <Helmet>
            <script>{`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${pixelId});
              fbq('track', 'Purchase', {
                content_type: 'product',
                currency: 'BRL'
              });
            `}</script>
          </Helmet>
        )}
      </div>
      <div className="sub-main-campaign-not-found">
        <div className="container-campaign-not-found">
          <FaCheckCircle className="error-icon" />
          <h1>Pagamento concluido!</h1>
        </div>
        <div className="go-to">
          <button onClick={() => handleRedirect()}>
            Voltar para outra campanha
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentCompleted;