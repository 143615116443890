import React, { useEffect } from 'react';
import './Campaigns.css'
import FloatingPanel from "../../components/FloatingPanel"
import config from "../../config"; 

const Campaigns = () => {

  // CronJob
  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendDomain = config.backendDomain;
        const url = `${backendDomain}/backend/src/view/utils/execCronJob.php`;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
        throw new Error("Falha ao chamar o endpoint do cronjob");
        }
      } catch (error) {
        console.error('Erro ao chamar o endpoint do cronjob', error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000); // 60 segundos

    return () => clearInterval(interval);
  }, []);

    return (
        <div className="main">
          <div className="background-color">
            
          </div>
            <FloatingPanel />
        </div>
    )
}

export default Campaigns