import React, { useState, useRef, useEffect } from "react";
import { format } from "date-fns";
import "./EditCampaigns.css";
import config from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { FaPen } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import CustomPanel from "../../components/CustomPanel";
import CampaignDetails from "../../components/CampaignDetails";
import { isValid, parseISO } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ImageNotFound from "../../assets/images/Image_not_found.png";
import { formatNumber } from "../../components/utils/Utils";
import ptBR from "date-fns/locale/pt-BR";
import ErrorPanel from "../../components/utils/ErrorPanel";
import image_0 from "../../assets/images/dashboard/EditCampaigns/CampaignLayouts/layout_0.png";
import image_1 from "../../assets/images/dashboard/EditCampaigns/CampaignLayouts/layout_1.png";

registerLocale("pt-BR", ptBR);

Modal.setAppElement("#root");

const EditCampaigns = () => {
  const inputRef = useRef(null);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [isErrorDeleteCampaign, setIsErrorDeleteCampaign] = useState(false);
  const [isErrorFinalizeCampaign, setIsErrorFinalizeCampaign] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isImageLoading, setImageLoading] = useState(true);
  const [pageModeText, setPageModeText] = useState("");
  const [definedFinishDateChecked, setDefinedFinishDateChecked] =
    useState(false);
  const [definedFreeCampaign, setDefinedFreeCampaign] = useState(false);
  const [shares, setShares] = useState(null);
  const [campaignEnded, setCampaignEnded] = useState(false);
  const [endedText, setEndedText] = useState("");
  const [endedFinishDate, setEndedFinishDate] = useState("");
  const [deleteCampaignProperties, setDeleteCampaignProperties] = useState({
    width: "100%",
    height: "40px",
    flex: "",
  });
  const [downButtonsProperties, setDownButtonsProperties] = useState({
    gap: "",
  });

  const [campaignLayout, setCampaignLayout] = useState(0);

  // Dados das imagens
  const images = [
    { id: 0, src: image_0, alt: "Image 0" },
    { id: 1, src: image_1, alt: "Image 1" },
  ];

  const setNewCampaignLayout = async (newCampaignLayout) => {
    try {
      const token = localStorage.getItem("accessToken");
      const url = `${config.backendDomain}/backend/src/view/dashboard/campaing/setCampaingLayout.php`;

      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("layout_id", newCampaignLayout);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const request = await fetch(url, requestOptions);

      const response = await request.json();
      console.log("response", response);
      console.log("blocking", isBlocking);

      if (request.ok) {
        if (response.status === "success") {
          setCampaignLayout(newCampaignLayout);
        } else {
          console.log("blocking", isBlocking);
          setErrorText("Erro ao alterar layout da campanha");
          setError(true);
          setIsBlocking(false);
          setTimeout(() => {
            setError(false);
          }, 2000); // 2 segundos
        }
      }
    } catch (error) {
      setErrorText("Erro ao alterar layout da campanha");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error("Erro ao alterar layout da campanha", error);
    }
  };

  const [temporaryImageLayoutId, setTemporaryImageId] = useState(0);

  const handleImageClick = (id) => {
    setTemporaryImageId(id);
    setCampaignLayout(id);
  };

  // CronJob
  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendDomain = config.backendDomain;
        const url = `${backendDomain}/backend/src/view/utils/execCronJob.php`;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
          throw new Error("Falha ao chamar o endpoint do cronjob");
        }
      } catch (error) {
        console.error("Erro ao chamar o endpoint do cronjob", error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000); // 60 segundos

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const adjust = () => {
      if (campaignEnded) {
        setDeleteCampaignProperties({
          width: "",
          height: "40px",
          flex: "1",
        });
        setDownButtonsProperties({
          gap: "10px",
        });
      } else {
        setDeleteCampaignProperties({
          width: "100px",
          height: "40px",
          flex: "",
        });
        setDownButtonsProperties({
          gap: "0",
        });
      }
    };

    window.addEventListener("resize", adjust);

    adjust();

    return () => {
      window.removeEventListener("resize", adjust);
    };
  }, [campaignEnded]);

  const handleCheckBoxChange = () => {
    const newDefinedFinishDateChecked = !definedFinishDateChecked;

    setDefinedFinishDateChecked(newDefinedFinishDateChecked);
    updateDefinedFinishDate(Number(newDefinedFinishDateChecked));
  };

  const handleFreeCampaignCheckBoxChange = () => {
    const newDefinedFreeCampaignChecked = !definedFreeCampaign;
    setDefinedFreeCampaign(newDefinedFreeCampaignChecked);
    // console.log("Valor da campanha gratuita " + Number(definedFreeCampaign));
    /* if (newDefinedFreeCampaignChecked) {
      updateDefinedFreeCampaign(1);
    } else {
      updateDefinedFreeCampaign(0);
    } */

    /* console.log("price share boolean" + definedFreeCampaign);
    console.log("price share value " + campaignDetails.priceShare); */

    /* if (newDefinedFreeCampaignChecked) {
      updateCampaignPriceShare(0);
      console.log("price share value " + campaignDetails.priceShare);
    } else {
      console.log("price share value not checked" + campaignDetails.priceShare);
    } */
  };

  const redirectToDashboard = (id) => {
    navigate(`/dashboard`);
    window.location.reload(false);
  };

  // Função para verificar se o token é válido
  const isValidToken = async (token) => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/userWorkFlow/login/checkToken.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `token=${token}`,
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        console.log("valid token");
        return responseData.status === "success";
      }

      return false;
    } catch (error) {
      console.error("Erro ao verificar token:", error);
      return false;
    }
  };

  // Segunda função para validar se o token é válido e redirecionando para a página de login
  const verifyToken = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      // Se houver um token salvo, verificar se é válido
      if (token && (await isValidToken(token))) {
        // Token válido
      } else {
        // Token inválido ou não encontrado, redirecionar para a tela de login
        navigate("/login");
        return;
      }
    } catch (error) {
      console.error("Token inválido", error);
    }
  };

  // Verificando se o token é válido no inicio do carregamento da página
  useEffect(() => {
    verifyToken();
    console.log("id da campanha: " + idFromUrl);
    // localStorage.removeItem("mensagemAba");
  }, []);

  // Painel customizado 1 (confirmação de exclusão de campanha)
  const [showPanel, setShowPanel] = useState(false);

  const callExclusionPanel = () => {
    setShowPanel(true);
    setShowPanel2(false);
  };

  const handleConfirmExclusion = () => {
    console.log("Ação confirmada");
    deleteCampaign();
    setLoading(true);
    setShowPanel(false);
    setShowPanel2(false);
    /* const urlDashboardEdit = "/dashboard/edicaoDeCampanhas";
    const urlDashboard = "/dashboard";
    const currentPage = window.location.pathname;

    if (currentPage.includes(urlDashboard)) {

      return;
    }

    if (window.opener) {

      window.close();
      window.opener.location.href = urlDashboard;
    } else {
      window.open(urlDashboard, '_blank');
    }
  } */
  };

  const handleCancelExclusion = () => {
    console.log("Ação cancelada!!");
    setShowPanel(false);
    setShowPanel2(false);
  };

  //

  // Painel customizado 2 (confirmação de finalização de campanha)
  const [showPanel2, setShowPanel2] = useState(false);

  const callFinalizationPanel = () => {
    setShowPanel2(true);
    setShowPanel(false);
  };

  const handleConfirmFinalization = () => {
    finalizeCampaign();
    setLoading(true);
    setShowPanel2(false);
    setShowPanel(false);
  };

  const handleCancelFinalization = () => {
    setShowPanel2(false);
    setShowPanel(false);
  };

  //

  const [editMode, setEditMode] = useState({
    title: false,
    description: false,
    quantity: false,
    price: false,
    finish_date: false,
  });

  const [activeIcon, setActiveIcon] = useState(null);

  const inputRefs = {
    title: useRef(null),
    description: useRef(null),
    quantity: useRef(null),
    price: useRef(null),
    minShares: useRef(null),
    maxShares: useRef(null),
    finish_date: useRef(null),
  };

  const handleEditToggle = (field) => {
    setEditMode((prev) => ({
      ...Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === field ? !prev[key] : false;
        return acc;
      }, {}),
    }));
    setActiveIcon(field);
  };

  const handleBlur = (field) => {
    setEditMode((prev) => ({
      ...prev,
      [field]: false,
    }));
  };

  const handleClickOutside = (event) => {
    Object.keys(inputRefs).forEach((field) => {
      if (
        inputRefs[field].current &&
        !inputRefs[field].current.contains(event.target) &&
        !event.target.classList.contains(`${field}-edit-icon`)
      ) {
        setEditMode((prev) => ({
          ...prev,
          [field]: false,
        }));
      }
    });
  };

  /* useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); */

  const deleteCampaign = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/deleteCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json(); // Converte a resposta para JSON

        if (responseData.status === "success") {
          console.log("Campanha deletada!");
          navigate("/dashboard");
        } else {
          setIsErrorDeleteCampaign(true);
        }
      } else {
        setIsErrorDeleteCampaign(true);
      }
    } catch {
      console.error("Erro ao acessar dados do backend:");
      setIsErrorDeleteCampaign(true);
    }
  };

  const finalizeCampaign = async () => {
    try {
      const token = localStorage.getItem("accessToken");

      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/finalizeCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json(); // Converte a resposta para JSON

        if (responseData.status === "success") {
          console.log("Campanha finalizada!");
          navigate("/dashboard");
        } else {
          setIsErrorFinalizeCampaign(true);
        }
      } else {
        setIsErrorFinalizeCampaign(true);
      }
    } catch {
      console.error("Erro ao acessar dados do backend:");
      setIsErrorFinalizeCampaign(true);
    }
  };

  const updateDefinedFreeCampaign = async (newDefinedFreeCampaign) => {
    const token = localStorage.getItem("accessToken");

    try {
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("state", newDefinedFreeCampaign);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/setFreeCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.status === "success") {
          setIsBlocking(true);
          // console.log("Descrição da campanha alterado com sucesso!");
        } else {
          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("O estado da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText("O estado da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }

          console.error(
            "Erro ao alterar para campanha gratuita",
            response.status
          );
        }
      } else {
        setErrorText(
          "Erro ao chamar o endpoint de alteração para campanha gratuita"
        );
        setError(true);
        setIsBlocking(false);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de alteração para campanha gratuita",
          response.status
        );
      }
    } catch (error) {
      setErrorText("Erro ao atualizar para campanha gratuita");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error("Erro ao atualizar para campanha gratuita:", error.message);
    }
  };

  // envio de dados da campanha para o backend
  const updateCampaignTitle = async (newTitle) => {
    const token = localStorage.getItem("accessToken");

    try {
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("title", newTitle);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/setTitleCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        if (responseData.status === "success") {
          setIsBlocking(true);
          console.log("Título da campanha alterado com sucesso!");
        } else {
          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("O titulo da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText("O titulo da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O titulo da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O titulo da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }

          console.error(
            "Erro ao alterar o título da campanha",
            response.status
          );
        }
      } else {
        setErrorText("Erro ao chamar o endpoint de alteração do título");
        setError(true);
        setIsBlocking(false);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de alteração do título",
          response.status
        );
      }
    } catch (error) {
      setErrorText("Erro ao atualizar o título da campanha");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error("Erro ao atualizar o título da campanha:", error.message);
    }
  };

  const updateCampaignDescription = async (newDescription) => {
    const token = localStorage.getItem("accessToken");

    try {
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("description", newDescription);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/setDescriptionCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.status === "success") {
          setIsBlocking(true);
          console.log("Descrição da campanha alterado com sucesso!");
        } else {
          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("A descrição da campanha é nula");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText("A descrição da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }

          console.error(
            "Erro ao alterar a descrição da campanha",
            response.status
          );
        }
      } else {
        setErrorText("Erro ao chamar o endpoint de alteração da descrição");
        setError(true);
        setIsBlocking(false);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de alteração da descrição",
          response.status
        );
      }
    } catch (error) {
      setErrorText("Erro ao atualizar a descrição da campanha");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error(
        "Erro ao atualizar a descrição da campanha:",
        error.message
      );
    }
  };

  const updateCampaignShares = async (newShares) => {
    const token = localStorage.getItem("accessToken");

    try {
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("shares", newShares);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/setSharesCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("shares data ", responseData);

        if (responseData.status === "success") {
          setIsBlocking(true);
          console.log("cotas da campanha alterado com sucesso!");
        } else {
          setIsBlocking(false);
          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("A quantidade de bilhetes da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText(
                "A quantidade de bilhetes da campanha é muito grande"
              );
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0006":
              setErrorText("A campanha é nula");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0007":
              setErrorText(
                "A quantidade de bilhetes da campanha já estão definidas"
              );
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0008":
              setErrorText("A quantidade de bilhetes foi excedida");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }

          console.error(
            "Erro ao alterar os cotas da campanha",
            response.status
          );
        }
      } else {
        setErrorText("Erro ao chamar o endpoint de alteração de bilhetes");
        setError(true);
        setIsBlocking(false);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de alteração de bilhetes",
          response.status
        );
      }
    } catch (error) {
      setErrorText("Erro ao atualizar os bilhetes da campanha");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error(
        "Erro ao atualizar os bilhetes da campanha:",
        error.message
      );
    }
  };

  const updateCampaignPriceShare = async (newPriceShare) => {
    const token = localStorage.getItem("accessToken");

    try {
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("price_share", newPriceShare * 100);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/setPriceShareCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.status === "success") {
          setIsBlocking(true);
          console.log("cotas da campanha alterado com sucesso!");
        } else {
          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("O preço por bilhetes da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText("O preço por bilhetes da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }

          console.error(
            "Erro ao alterar o preço por bilhete da campanha",
            response.status
          );
        }
      } else {
        setErrorText(
          "Erro ao chamar o endpoint de alteração de preço por bilhete"
        );
        setError(true);
        setIsBlocking(false);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de alteração de preço por bilhete",
          response.status
        );
      }
    } catch (error) {
      setErrorText("Erro ao atualizar as cotas da campanha");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error("Erro ao atualizar as cotas da campanha:", error.message);
    }
  };

  const updateCampaignMinShares = async (newMinShares) => {
    const token = localStorage.getItem("accessToken");

    try {
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("min", newMinShares);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/setMinTicketsCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.status === "success") {
          setIsBlocking(true);
          console.log("cotas da campanha alterado com sucesso!");
        } else {
          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("O minimo de bilhetes da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText("O minimo de bilhetes da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }

          console.error(
            "Erro ao alterar a quantidade minima de bilhetes da campanha",
            response.code
          );
        }
      } else {
        setErrorText(
          "Erro ao chamar o endpoint de alteração de quantidade minima de bilhetes"
        );
        setError(true);
        setIsBlocking(false);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de alteração de quantidade minima de bilhetes",
          response.status
        );
      }
    } catch (error) {
      setErrorText(
        "Erro ao atualizar a quantidade minima de bilhetes da campanha"
      );
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error(
        "Erro ao atualizar a quantidade minima de bilhetes da campanha:",
        error.message
      );
    }
  };

  const updateCampaignMaxShares = async (newMaxShares) => {
    const token = localStorage.getItem("accessToken");

    try {
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("max", newMaxShares);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/setMaxTicketsCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.status === "success") {
          setIsBlocking(true);
          console.log("cotas da campanha alterado com sucesso!");
        } else {
          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("O maximo de bilhetes da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText("O maximo de bilhetes da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0006":
              setErrorText("A campanha é nula");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }

          console.error(
            "Erro ao alterar as quantidade maxima de bilhetes da campanha",
            responseData.code
          );
        }
      } else {
        setErrorText(
          "Erro ao chamar o endpoint de alteração de quantidade maxima de bilhetes da campanha"
        );
        setError(true);
        setIsBlocking(false);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de alteração de quantidade maxima de bilhetes da campanha",
          response.status
        );
      }
    } catch (error) {
      setErrorText(
        "Erro ao atualizar a quantidade maxima de bilhetes da campanha"
      );
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error(
        "Erro ao atualizar a quantidade maxima de bilhetes da campanha:",
        error.message
      );
    }
  };

  const updateCampaignFinishDate = async (newFinishDate) => {
    const token = localStorage.getItem("accessToken");

    try {
      const date = new Date(newFinishDate);
      const formattedDate = `${("0" + date.getHours()).slice(-2)}:${(
        "0" + date.getMinutes()
      ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)} ${(
        "0" + date.getDate()
      ).slice(-2)}-${("0" + (date.getMonth() + 1)).slice(
        -2
      )}-${date.getFullYear()}`;

      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("finish_date", formattedDate);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/setFinishDateCampaing.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.status === "success") {
          setIsBlocking(true);
          console.log("cotas da campanha alterado com sucesso!");
        } else {
          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("A data de finalização da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText("A data de finalização da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }

          console.error(
            "Erro ao alterar a data de finalização da campanha",
            response.status
          );
        }
      } else {
        setErrorText(
          "Erro ao chamar o endpoint de alteração da data de finalização da campanha"
        );
        setError(true);
        setIsBlocking(false);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de alteração da data de finalização da campanha",
          response.status
        );
      }
    } catch (error) {
      setErrorText("Erro ao atualizar a data de finalização da campanha");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error(
        "Erro ao atualizar a data de finalização da campanha:",
        error.message
      );
    }
  };

  const updateDefinedFinishDate = async (newDefinedFinishDate) => {
    const token = localStorage.getItem("accessToken");

    try {
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("id", idFromUrl);
      formData.append("state", newDefinedFinishDate);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/setDefinedFinishDate.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status === "success") {
          setIsBlocking(true);
          console.log("Valor", newDefinedFinishDate);
          setDefinedFinishDateChecked(newDefinedFinishDate);
        } else {
          setErrorText(
            "Erro ao alterar a data definida de finalização da campanha"
          );
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 2000); // 2 segundos
          console.error(
            "Erro ao alterar a data definida de finalização da campanha",
            response.status
          );
        }
      } else {
        setErrorText(
          "Erro ao chamar o endpoint de alteração da data de finalização da campanha"
        );
        setError(true);
        setIsBlocking(false);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de alteração da data de finalização da campanha",
          response.status
        );
      }
    } catch (error) {
      setErrorText("Erro ao atualizar a data de finalização da campanha");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
      console.error(
        "Erro ao atualizar a data de finalização da campanha:",
        error.message
      );
    }
  };

  // Substituir pelo valor retornado do backend
  const [fakeBackendBoolean, setFakeBackendBoolean] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);

  // Atualiza o preço das cotas caso seja possivel e ainda não tenha sido alterado
  const handleUpdateShares = () => {
    const newDate = new Date(temporaryDetails.finish_date).toISOString();

    if (definedFinishDateChecked) {
      handleInputChange("finish_date", newDate);
    }
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = ""; // Necessário para exibir o aviso padrão do navegador
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setSelectedImageName(selectedImage.name);
      const newImage = URL.createObjectURL(selectedImage);
      setImage(newImage);
      setCampaignDetails((prevDetails) => ({
        ...prevDetails,
        image: newImage,
      }));
    } else {
      setSelectedImageName("Nenhuma imagem selecionada");
      setCampaignDetails((prevDetails) => ({
        ...prevDetails,
        image: null, // Limpa a imagem selecionada
      }));
    }
  };

  const handleImageUpload = async () => {
    if (!image) {
      // Se nenhuma imagem foi selecionada, exibir uma mensagem na tela
      alert("Por favor, selecione uma imagem antes de enviar.");
      return;
    }

    const token = localStorage.getItem("accessToken");

    try {
      const formData = new FormData();
      setImageLoading(true);

      const r = await fetch(image);
      const blob = await r.blob();

      // Cria um novo objeto File com o blob e informações do arquivo original
      const file = new File([blob], campaignDetails.name, {
        type: campaignDetails.type,
      });

      formData.append("image", file, "image.png");
      formData.append("campaing_id", String(idFromUrl));
      formData.append("image_id", String(1));
      formData.append("token", String(token));

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/sendImageCampaing.php`,
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        closeImageModal();

        if (responseData.status === "success") {
          // fetchCampaignDetails();
          setIsBlocking(true);
          setImageLoading(false);
          console.log("Imagem da campanha enviada com sucesso!");
        } else {
          console.error("Erro ao enviar imagem da campanha", response.status);
          setImageLoading(false);

          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O id da imagem é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O id da imagem é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0006":
              setErrorText("Erro ao enviar imagem");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            default:
              setErrorText("Erro desconhecido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }
        }
      } else {
        setErrorText("Erro ao chamar o endpoint de envio de imagem");
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de envio de imagem",
          response.status
        );
        setImageLoading(false);
      }
    } catch (error) {
      console.error("Erro ao enviar a imagem da campanha:", error.message);
      setImageLoading(false);
      setErrorText("Erro ao enviar imagem");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
    } finally {
      setImageModalOpen(false);
    }
  };

  const [isImageModalOpen, setImageModalOpen] = useState(false);

  const openImageModal = () => {
    setImageModalOpen(true);
    setSelectedImageName("Selecione uma imagem");
    // setSelectedImageName("Selecione uma imagem");
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    setSelectedImageName("Selecione uma imagem");
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      image: null, // Limpa a imagem selecionada
    }));
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const idFromUrl = params.get("id");

  const redirectWithIdForEdit = (id) => {
    // Redirecionar para a página padrão com o ID
    navigate(`/dashboard/edicaoDeCampanhas/?id=${id}`);
  };

  const [image, setImage] = useState(null);

  const unsavedChangesRef = useRef(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [campaignDetails, setCampaignDetails] = useState({
    image: "",
    title: "",
    description: "",
    shares: "",
    priceShare: "",
    minShares: "",
    maxShares: "",
    finish_date: "",
  });

  const [temporaryDetails, setTemporaryDetails] = useState(campaignDetails);

  // Efeito para ouvir alterações na URL
  useEffect(() => {
    // Compare os detalhes atuais da campanha com os detalhes iniciais
    compareCampaignDetails();
  }, [location.pathname]);

  // Efeito para bloquear a navegação se houver alterações não salvas
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Para suporte em navegadores antigos
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChangesRef.current]);

  // Função para comparar os detalhes da campanha
  const compareCampaignDetails = () => {
    const areEqual =
      JSON.stringify(campaignDetails) === JSON.stringify(temporaryDetails);
    unsavedChangesRef.current = !areEqual;
    setUnsavedChanges(!areEqual);
  };

  const handleInputChange = (field, value) => {
    console.log(field, value);
    setTemporaryDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));

    /* setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    })); */

    /* if (field === "title") {
      updateCampaignTitle(value);
    }

    if (field === "description") {
      updateCampaignDescription(value);
    }

    if (field === "shares") {
      updateCampaignShares(value);
      setShares(value);
    }

    if (field === "priceShare") {
      updateCampaignPriceShare(value);
    }

    if (field === "minShares" && value > 0) {
      updateCampaignMinShares(value);
    }

    if (field === "maxShares" && value > 0) {
      updateCampaignMaxShares(value);
    }

    if (field === "finish_date") {
      updateCampaignFinishDate(value);
      console.log(
        "Valor de campaignDetails.finish_date:",
        campaignDetails.finish_date
      );
    } */
  };

  const handleSave = async () => {
    // console.log("blocking", isBlocking);
    // Atualizar os valores específicos
    await updateCampaignTitle(temporaryDetails.title);
    await updateCampaignDescription(temporaryDetails.description);
    await handleImageUpload();
    await updateCampaignPriceShare(temporaryDetails.priceShare);
    await updateCampaignMinShares(temporaryDetails.minShares);
    await updateCampaignMaxShares(temporaryDetails.maxShares);
    await updateDefinedFreeCampaign(Number(definedFreeCampaign));
    await updateCampaignFinishDate(temporaryDetails.finish_date);
    console.log("temporary finish date" + temporaryDetails.finish_date);
    await setNewCampaignLayout(temporaryImageLayoutId);
    setShares(temporaryDetails.shares);
    console.log("SHARES " + shares + " " + typeof shares);
    console.log(
      "SHARES DETAILS" +
        temporaryDetails.shares +
        " " +
        typeof temporaryDetails.shares
    );
    handleUpdateShares();
    console.log("SHARES " + shares + " " + typeof shares);
    console.log(
      "SHARES DETAILS" +
        temporaryDetails.shares +
        " " +
        typeof temporaryDetails.shares
    );
    if (fakeBackendBoolean) {
      console.log("SHARES " + shares + " " + typeof shares);
      console.log(
        "SHARES DETAILS" +
          temporaryDetails.shares +
          " " +
          typeof temporaryDetails.shares
      );
      await updateCampaignShares(temporaryDetails.shares);
    }
    // console.log("blocking", isBlocking);

    // window.removeEventListener("beforeunload", handleBeforeUnload);
    // console.log("blocking", isBlocking);

    if (!isBlocking) {
      // console.log("blocking", isBlocking);
      if (errorText === "") {
        setErrorText("Parece que um erro inesperado aconteceu");
      }
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000); // 4 segundos
    } else {
      // console.log("blocking", isBlocking);
      redirectFor();
    }
  };
  const redirectFor = () => {
    console.log("blocking", isBlocking);
    if (isBlocking && !isError) {
      navigate("/dashboard");
    }
  };
  const fetchCampaignDetails = async () => {
    try {
      // verifyToken();

      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/home/campaing/getCampaing.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `campaing_id=${idFromUrl}`, // Corpo da requisição no formato application/x-www-form-urlencoded
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      // Analisar os dados como application/x-www-form-urlencoded
      /* const formDataObject = new URLSearchParams(formData);
      const data = {};
      formDataObject.forEach((value, key) => {
        data[key] = value;
      }); */

      const createDateFromPattern = (dateString, pattern) => {
        const patternRegex = /(HH|mm|ss|DD|MM|YYYY)/g;
        const dateComponents = {};

        // Extract components from the pattern
        pattern.match(patternRegex).forEach((component) => {
          const componentIndex = pattern.indexOf(component);
          dateComponents[component] = dateString.substr(
            componentIndex,
            component.length
          );
        });

        const year = dateComponents["YYYY"]
          ? parseInt(dateComponents["YYYY"], 10)
          : NaN;
        const month = dateComponents["MM"]
          ? parseInt(dateComponents["MM"], 10) - 1
          : NaN; // Month is zero-indexed in JS Date
        const day = dateComponents["DD"]
          ? parseInt(dateComponents["DD"], 10)
          : NaN;
        const hours = dateComponents["HH"]
          ? parseInt(dateComponents["HH"], 10)
          : 0;
        const minutes = dateComponents["mm"]
          ? parseInt(dateComponents["mm"], 10)
          : 0;
        const seconds = dateComponents["ss"]
          ? parseInt(dateComponents["ss"], 10)
          : 0;

        return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
      };

      // Assuming data.value.finish_date is available and in the format "HH:mm:ss DD-MM-YYYY"
      const dateString = data.value.finish_date; // Example: "11:25:39 29-06-2024"
      const pattern = "HH:mm:ss DD-MM-YYYY";
      const date = createDateFromPattern(dateString, pattern);

      // Check if the date is valid
      const isValidDate = (date) => {
        return (
          Object.prototype.toString.call(date) === "[object Date]" &&
          !isNaN(date.getTime())
        );
      };

      const currentDate = new Date();
      // If the date is not valid, set to the current date
      const finalDate = isValidDate(date) ? date : currentDate;

      console.log(dateString);
      console.log(typeof dateString);

      console.log("DATE " + finalDate.toString() + " " + dateString.toString());

      setCampaignDetails({
        title:
          data.value.title || "title não carregado, valor indefinido ou nulo",
        description:
          data.value.description ||
          "description não carregado, valor indefinido ou nulo",
        shares:
          data.value.shares || "shares não carregado, valor indefinido ou nulo",
        priceShare:
          data.value.priceShare / 100 ||
          "priceShare não carregado, valor indefinido ou nulo",
        minShares:
          data.value.min_tickets ||
          "minShares não encontrado, valor indefinido ou nulo",
        maxShares:
          data.value.max_tickets ||
          "maxShares não encontrado, valor indefinido ou nulo",
        finish_date: finalDate,
      });

      setTemporaryDetails({
        title:
          data.value.title || "title não carregado, valor indefinido ou nulo",
        description:
          data.value.description ||
          "description não carregado, valor indefinido ou nulo",
        shares:
          data.value.shares || "shares não carregado, valor indefinido ou nulo",
        priceShare:
          data.value.priceShare / 100 ||
          "priceShare não carregado, valor indefinido ou nulo",
        minShares:
          data.value.min_tickets ||
          "minShares não encontrado, valor indefinido ou nulo",
        maxShares:
          data.value.max_tickets ||
          "maxShares não encontrado, valor indefinido ou nulo",
        finish_date: finalDate,
      });

      setCampaignLayout(data.value.layout_id);
      setTemporaryImageId(data.value.layout_id);
      setEndedFinishDate(data.value.finish_date);

      if (data.value.status == 0) {
        setCampaignEnded(false);
        setEndedText("ENCERRA EM");
      } else if (data.value.status == 1) {
        setCampaignEnded(true);
        setEndedText("ENCERRADO EM");
      }

      if (data.value.shares !== -1) {
        setFakeBackendBoolean(false);
      } else {
        setFakeBackendBoolean(true);
      }

      const isFree = data.value.free === 1;
      setDefinedFreeCampaign(isFree);

      // Estado do checkbox
      setDefinedFinishDateChecked(Boolean(data.value.date_defined));
      setLoading(false);
      // Obter o valor de "pageMode" do localStorage
      const pageMode = localStorage.getItem("pageMode");

      // Verificar o valor de "pageMode" e definir o texto correspondente
      if (pageMode === "edit") {
        setPageModeText(`Editando a campanha - ${data.value.title}`);
        console.log("Titulo" + campaignDetails.title);
      } else if (pageMode === "create") {
        setPageModeText("Criando uma campanha");
      }

      // console.log(data.value.finish_date);

      // imagem
      const formData = new URLSearchParams();

      formData.append("campaing_id", idFromUrl);
      formData.append("image_id", 1);
      const imageResponse = await fetch(
        `${config.backendDomain}/backend/src/view/home/campaing/getCampaingImage.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (imageResponse.ok) {
        const imageData = await imageResponse.blob();
        const imageUrl = URL.createObjectURL(imageData);
        console.log("URL: " + imageUrl);
        setImage(imageUrl);
        setImageLoading(false);
        // return { imageUrl };
      } else {
        console.error(
          `Erro ao carregar imagem para a campanha com ID ${idFromUrl}`
        );
        setImage(ImageNotFound);
        setImageLoading(false);
      }

      console.log("Status do Endpoint:", data.status);
    } catch (error) {
      console.error("Erro ao acessar o endpoint do backend:", error.message);
    }
  };

  // carregando a campanha do backend
  useEffect(() => {
    if (!idFromUrl && location.pathname === "/") {
      navigate("/?id=1");
    } else if (idFromUrl) {
      fetchCampaignDetails();
    }

    fetchCampaignDetails();

    console.log("new price share" + typeof campaignDetails.priceShare);
  }, [idFromUrl]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="main-container-">
      <div className="background-"></div>
      {isLoading && !isErrorDeleteCampaign && (
        <div className="loading-main">
          <div className="loading-circle"></div>
        </div>
      )}

      {isError && <ErrorPanel errorText={errorText} />}

      {isErrorDeleteCampaign && (
        <div className="loading-main">
          <BiSolidError className="error-icon-dashboard" />
          <h1 className="error-create-campaign"> Erro ao deletar campanha! </h1>
          <button
            className="back-to-dashboard"
            onClick={() => redirectToDashboard()}
          >
            Voltar para o dashboard
          </button>
        </div>
      )}

      {isErrorFinalizeCampaign && (
        <div className="loading-main">
          <BiSolidError className="error-icon-dashboard" />
          <h1 className="error-create-campaign">
            {" "}
            Erro ao finalizar campanha!{" "}
          </h1>
          <button
            className="back-to-dashboard"
            onClick={() => redirectToDashboard()}
          >
            Voltar para o dashboard
          </button>
        </div>
      )}

      {showPanel && (
        <div className="overlay">
          <CustomPanel
            topText={"Deseja mesmo excluir a campanha?"}
            onCancel={handleCancelExclusion}
            onConfirm={handleConfirmExclusion}
          />
        </div>
      )}
      {showPanel2 && (
        <div className="overlay">
          <CustomPanel
            topText={"Deseja mesmo finalizar a campanha?"}
            onCancel={handleCancelFinalization}
            onConfirm={handleConfirmFinalization}
          />
        </div>
      )}
      <Modal
        isOpen={isImageModalOpen}
        onRequestClose={closeImageModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000, // Ajuste a ordem z conforme necessário
          },
          content: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "6px",
            color: "white",
            backgroundColor: "rgba(32, 32, 32, 1)",
            width: "425px",
            height: "75px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "16px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "20px",
            zIndex: 1001, // Ajuste a ordem z conforme necessário (deve ser maior que a overlay)
          },
        }}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          ref={inputRef} // Para acessar o input programaticamente
        />
        <button
          className="select-img"
          onClick={() => inputRef.current.click()} // Aciona o clique do input
        >
          Escolher imagem
        </button>
        <h4>{selectedImageName}</h4>
        <button className="upload-img" onClick={() => setImageModalOpen(false)}>
          Enviar Imagem
        </button>
      </Modal>

      {!isLoading && !isErrorDeleteCampaign && !isErrorFinalizeCampaign && (
        <div className="sub-main-container">
          <div className="title-text-main">
            <h2 className="title-text">{pageModeText}</h2>
          </div>

          {campaignEnded && (
            <div className="final-date-edit-campaigns">
              <p className="final-date-text-edit-campaigns">
                {endedText} {endedFinishDate}
              </p>
            </div>
          )}

          <div className="content-container">
            <div className="title-main-">
              <label>Título</label>
              <input
                type="text"
                onChange={(e) => handleInputChange("title", e.target.value)}
                value={temporaryDetails.title}
              ></input>
            </div>
            <div className="description-main-">
              <label>Descrição</label>
              <textarea
                type="text"
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
                value={temporaryDetails.description}
              ></textarea>
            </div>
            <div className="image-main-">
              <label>Imagem</label>
              <div className="image-container">
                {isImageLoading ? (
                  <div className="loading-image-circle"></div>
                ) : (
                  <>
                    <img src={image} className="campaign-img" />
                    {!campaignEnded && (
                      <div className="pen-img-icon">
                        <FaPen className="pen-icon" onClick={openImageModal} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="quotas-content-1-main-">
              <div className="quotas-quantity-main-">
                <label>Quantidade de cotas</label>

                <input
                  type="number"
                  onChange={(e) => handleInputChange("shares", e.target.value)}
                  value={temporaryDetails.shares}
                  disabled={!fakeBackendBoolean || campaignEnded}
                ></input>
              </div>
              {!definedFreeCampaign && (
                <div className="quotas-price-main-">
                  <label>Preço por cota</label>

                  <input
                    type="number"
                    onChange={(e) =>
                      handleInputChange("priceShare", Number(e.target.value))
                    }
                    value={Number(temporaryDetails.priceShare)}
                    disabled={campaignEnded}
                  ></input>
                </div>
              )}
            </div>
            <div className="quotas-content-2-main-">
              {!definedFreeCampaign && (
                <div className="quotas-min-price-main-">
                  <label>Quantidade mínima de bilhetes</label>

                  <input
                    type="number"
                    onChange={(e) =>
                      handleInputChange("minShares", e.target.value)
                    }
                    value={temporaryDetails.minShares}
                    disabled={campaignEnded}
                  ></input>
                </div>
              )}
              {!definedFreeCampaign && (
                <div className="quotas-max-price-main-">
                  <label>Quantidade máxima de bilhetes por compra</label>

                  <input
                    type="number"
                    onChange={(e) =>
                      handleInputChange(
                        "maxShares",
                        Math.min(e.target.value, 5000)
                      )
                    }
                    value={temporaryDetails.maxShares}
                    disabled={campaignEnded}
                  ></input>
                </div>
              )}
            </div>
            {!campaignEnded && (
              <div>
                <input
                  type="checkbox"
                  checked={definedFreeCampaign}
                  onChange={handleFreeCampaignCheckBoxChange}
                ></input>
                <label>Campanha gratuita</label>
              </div>
            )}

            {!campaignEnded && (
              <div className="finish-date-main-">
                {definedFinishDateChecked ? (
                  <label>Data de sorteio</label>
                ) : (
                  <></>
                )}

                <div className="finish_date-calendar-">
                  {definedFinishDateChecked ? (
                    <DatePicker
                      selected={temporaryDetails.finish_date}
                      onChange={(date) =>
                        handleInputChange("finish_date", date)
                      }
                      dateFormat="dd-MM-yyyy"
                      inline
                      locale="pt-BR"
                    />
                  ) : (
                    <></>
                  )}
                </div>

                <div className="date-check-box-">
                  <input
                    type="checkbox"
                    checked={definedFinishDateChecked}
                    onChange={handleCheckBoxChange}
                  ></input>

                  <label>Informar data</label>
                </div>
              </div>
            )}

            <div>
              <label>Layout da campanha</label>
              <div style={{ display: "flex", gap: "10px", marginTop: "6px" }}>
                {images.map((image) => (
                  <div key={image.id} style={{ position: "relative" }}>
                    <img
                      className="layout_image_edit_campaigns"
                      src={image.src}
                      alt={image.alt}
                      onClick={() => handleImageClick(image.id)}
                      style={{
                        border:
                          image.id === campaignLayout
                            ? "5px solid #4caf4c"
                            : "5px solid transparent",
                        borderRadius: "12px",
                        cursor: "pointer",
                        transition: "border 0.3s ease, opacity 0.5s ease",
                        opacity: image.id === campaignLayout ? 1 : 0.5,
                      }}
                    />
                    {image.id === campaignLayout && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          transition: "opacity 0.5s ease",
                          opacity: 1,
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="down-buttons-" style={downButtonsProperties}>
              <div className="left-button-container-">
                <button
                  className="delete-campaign-button-"
                  onClick={() => callExclusionPanel()}
                  style={deleteCampaignProperties}
                >
                  Excluir
                </button>

                {/* {campaignEnded && (
                  <button
                    className="save-button-2"
                    onClick={() => handleUpdateShares()}
                  >
                    Salvar
                  </button>
                )} */}
              </div>

              <div className="right-buttons-container-">
                {!campaignEnded && (
                  <button
                    className="finalize-campaign-button-"
                    onClick={() => callFinalizationPanel()}
                  >
                    Finalizar campanha
                  </button>
                )}
                <button className="save-button-" onClick={() => handleSave()}>
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default EditCampaigns;
