import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { GiHamburgerMenu, GiCancel } from "react-icons/gi";
import Home from "./pages/home/Home";
import Campaigns from "./pages/campaigns/Campaigns";
import Login from "./pages/authentication/Login";
import EditCampaigns from "./pages/campaigns/EditCampaigns";
import Payment from "./pages/payments/Payment";
import Customization from "./pages/campaigns/settings/Customization";
import Account from "./pages/campaigns/settings/Account";
import Social from "./pages/campaigns/settings/Social";
import BuyerHistory from "./pages/campaigns/BuyerHistory";
import CampaignNotFound from "./pages/error/CampaignNotFound";
import Error404page from "./pages/error/Error404Page";
import FindTickets from "./pages/home/findTickets/FindTickets";
import PaymentCompleted from "./pages/payments/PaymentCompleted";

function App() {
  const [showNav, setShowNav] = useState(false);
  const location = useLocation();
  // Verifique se a rota atual é /campaignes
  const isCampaignsPage = location.pathname === "/";
  const isPaymentPage = location.pathname === "/pagamentos";
  const isPaymentPage2 = location.pathname === "/pagamentos/";
  const isLoginPage = location.pathname === "/login";
  const isError404Page = location.pathname === "*";
  const isCampaignNotFoundPage = location.pathname === "/campanhaNaoEncontrada";
  const isFindTicketsPage = location.pathname === "/buscaDeBilhetes";
  const isPayment = location.pathname === "/pagamentos/pagamentoConcluido";
  const hasPhone = location.search.includes("telefone=");
  const hasTagQueryParam = location.search.includes("tag=");
  const [showHam, setShowHam] = useState(false);
  const [sideBarProperties, setSideBarProperties] = useState({
    width: "",
    marginLeft: "",
  });

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const closeNav = () => {
    setShowNav(false);
  };

  const openNav = () => {
    setShowNav(true);
  };

  /* useEffect(() => {
    const html = document.documentElement;
    const body = document.body;

    if (showNav) {
      html.classList.add("scroll-lock");
      body.classList.add("scroll-lock");
    } else {
      html.classList.remove("scroll-lock");
      body.classList.remove("scroll-lock");
    }
  }, [showNav]); */

  useEffect(() => {
    const adjust = () => {
      const isDesktop = window.innerWidth > 725; // default - 768
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      // Definindo uma proporção máxima para a altura em relação à largura
      const maxAspectRatio = 1.5; // Ajuste conforme necessário

      // Definindo o limite máximo para a altura da imagem
      const maxHeightLimit = 600; // Ajuste conforme necessário

      // Calculando a altura máxima com base na largura e altura da tela
      let maxHeightWidth = windowWidth * maxAspectRatio;
      let maxHeightHeight;

      if (windowWidth < 475) {
        maxHeightHeight = windowHeight * 0.3; // Porcentagem da altura da tela
      } else {
        maxHeightHeight = windowHeight * 0.8; // Porcentagem da altura da tela
      }

      if (isDesktop) {
        setShowHam(false);
        openNav();
        setSideBarProperties({
          width: "216px",
          marginLeft: "0%",
        });
        maxHeightHeight = windowHeight * 0.8; // Porcentagem da altura da tela
      } else {
        setSideBarProperties({
          width: "100%",
          marginLeft: "",
        });
        setShowHam(true);
        closeNav();
      }

      // Escolhendo o menor valor entre a altura máxima calculada com base na largura e na altura
      let maxHeight = Math.min(maxHeightWidth, maxHeightHeight, maxHeightLimit);
    };

    window.addEventListener("resize", adjust);

    adjust();

    return () => {
      window.removeEventListener("resize", adjust);
    };
  }, []);

  useEffect(() => {
    const body = document.body;

    // Verifique se a rota atual é /campaignes e aplique o estilo de fundo desejado
    if (isCampaignsPage) {
      body.style.backgroundColor = "#f9fafb"; // slategray
    } else {
      body.style.backgroundColor = "white";
    }
  }, [isCampaignsPage]);

  return (
    <div className="app-container">
      {showHam &&
        !isCampaignsPage &&
        !isPaymentPage &&
        !isPaymentPage2 &&
        !hasTagQueryParam &&
        !isLoginPage &&
        !isCampaignNotFoundPage &&
        !isFindTicketsPage &&
        !hasPhone &&
        !isPayment && (
          <div className="ham" onClick={toggleNav}>
            {showNav ? (
              <GiCancel className="ham-icon" />
            ) : (
              <GiHamburgerMenu className="ham-icon" />
            )}
          </div>
        )}

      {/* <Navbar /> */}
      {!isCampaignsPage &&
        !isPaymentPage &&
        !isPaymentPage2 &&
        !hasTagQueryParam &&
        !isLoginPage &&
        !isCampaignNotFoundPage &&
        !isFindTicketsPage &&
        !hasPhone &&
        !isPayment && (
          <Sidebar
            show={showNav}
            setShow={closeNav}
            properties={sideBarProperties}
          />
        )}

      <div className="main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Campaigns />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard/configuracoes/personalizacao"
            element={<Customization />}
          />
          <Route path="/dashboard/configuracoes/conta" element={<Account />} />
          <Route path="/dashboard/configuracoes/social" element={<Social />} />
          <Route
            path="/dashboard/historicoDeCompradores"
            element={<BuyerHistory />}
          />
          <Route
            path="/dashboard/edicaoDeCampanhas"
            element={<EditCampaigns />}
          />
          <Route path="/campanhaNaoEncontrada" element={<CampaignNotFound />} />
          <Route path="/buscaDeBilhetes" element={<FindTickets />} />
          <Route path="*" element={<Error404page />} />
          <Route
            path="/pagamentos/pagamentoConcluido"
            element={<PaymentCompleted />}
          />
          <Route path="/pagamentos" element={<Payment />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
