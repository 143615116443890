import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import logo from "./logo.jpg";
import { FiHome } from "react-icons/fi";
import { MdDashboard, MdOutlineSpaceDashboard } from "react-icons/md";
import { MdManageHistory } from "react-icons/md";
import { MdAccountCircle } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { FaArrowDown, FaArrowRight, FaArrowUp } from "react-icons/fa";
import "./Sidebar.css";
import CustomPanel from "./CustomPanel";

const Sidebar = ({ show, setShow, properties }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [activeSetting, setActiveSetting] = useState(null);
  const sidebarRef = useRef(null);

  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);

  const toggleSettingsDropdown = () => {
    handleButtonClick("config");
    setSettingsDropdownOpen(!settingsDropdownOpen);
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);

    if (activeButton !== "config") {
      if (activeButton === "campaignes" || activeButton === "historic") {
        setActiveSetting("");
        setSettingsDropdownOpen(false);
        setShow(false);
      }
    }
  };

  const handleSettingClick = (settingName) => {
    setActiveSetting(settingName); // atualiza o estado quando uma opção de configuração é selecionada
    if (
      activeSetting === "account" ||
      activeSetting === "social" ||
      activeSetting === "customization"
    ) {
      setShow(false);
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      // Clicou fora da área da barra lateral, fecha a barra lateral
      setShow(false);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/dashboard" &&
      activeButton !== "home" &&
      activeButton !== "historic" &&
      activeButton !== "config"
    ) {
      handleButtonClick("campaignes");
    }

    if (
      location.pathname === "/dashboard/edicaoDeCampanhas/" &&
      activeButton !== "home" &&
      activeButton !== "config"
    ) {
      handleButtonClick("");
      setActiveButton("");
    }

    if (
      location.pathname === "/dashboard/historicoDeCompradores" &&
      activeButton !== "home" &&
      activeButton !== "config"
    ) {
      handleButtonClick("historic");
      console.log("Historic");
    }

    if (location.pathname === "/dashboard/configuracoes/conta") {
      setSettingsDropdownOpen(true);
      setActiveSetting("account");
      handleButtonClick("config");
    }

    if (location.pathname === "/dashboard/configuracoes/social") {
      setSettingsDropdownOpen(true);
      setActiveSetting("social");
      handleButtonClick("config");
    }

    if (location.pathname === "/dashboard/configuracoes/personalizacao") {
      setSettingsDropdownOpen(true);
      setActiveSetting("customization");
      handleButtonClick("config");
    }
  }, [location, activeButton]);

  useEffect(() => {
    /* if (
      location.pathname !== "/dashboard" &&
      location.pathname !== "/edicaoDeCampanhas" &&
      && location.pathname !== "/dashboard/edicaoDeCampanhas"
      activeButton !== "home"
    ) {
      handleButtonClick("");
    } */
  }, [location, activeButton]);

  /* useEffect(() => {
    // Adiciona o evento de clique global para fechar a barra lateral
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove o evento ao desmontar o componente
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); */

  // painel customizado 1
  const [showPanel, setShowPanel] = useState(false);
  const [showCampaignsPanel, setShowCampaignsPanel] = useState(false);
  const [showHistoricPanel, setShowHistoricPanel] = useState(false);

  const openCampaignsPanel = () => {
    if (location.pathname === "/dashboard/edicaoDeCampanhas/") {
      handleButtonClick("campaignes");
      setShowCampaignsPanel(true);
    } else {
      handleButtonClick("campaignes");
      handleConfirmCampaignsPanel();
      // console.log("else");
    }
  };

  const handleCancelCampaignsPanel = () => {
    setShowCampaignsPanel(false);
  };

  const handleConfirmCampaignsPanel = () => {
    setShowCampaignsPanel(false);
    navigate("/dashboard");
  };

  const openHistoricPanel = () => {
    if (location.pathname === "/dashboard/edicaoDeCampanhas/") {
      handleButtonClick("historic");
      setShowHistoricPanel(true);
    } else {
      handleButtonClick("historic");
      handleConfirmHistoricPanel();
      // console.log("else");
    }
  };

  const handleCancelHistoricPanel = () => {
    setShowHistoricPanel(false);
  };

  const handleConfirmHistoricPanel = () => {
    setShowHistoricPanel(false);
    navigate("/dashboard/historicoDeCompradores");
  };

  const handleCancel = () => {
    console.log("Ação cancelada");
    if (location.pathname === "/dashboard") {
      handleButtonClick("campaignes");
    } else if (location.pathname === "/dashboard/historicoDeCompradores") {
      handleButtonClick("historic");
    } else if (
      location.pathname === "/dashboard/configuracoes/conta" ||
      location.pathname === "/dashboard/configuracoes/social" ||
      location.pathname === "/dashboard/configuracoes/personalizacao"
    ) {
      handleButtonClick("config");
    } else {
      handleButtonClick("");
    }
    setShowPanel(false);
  };

  const exitFromDashboard = () => {
    handleButtonClick("home");

    setShowPanel(true);
  };

  const handleConfirm = () => {
    console.log("Ação confirmada");
    setShowPanel(false);
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  //

  return (
    <div className="sidebar-header">
      {showPanel && (
        <CustomPanel
          topText={"Deseja mesmo sair do dashboard?"}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}

      {showCampaignsPanel && (
        <CustomPanel
          topText={
            "Talvez existam alterações não salvas, ir para as campanhas mesmo assim?"
          }
          onCancel={handleCancelCampaignsPanel}
          onConfirm={handleConfirmCampaignsPanel}
        />
      )}

      {showHistoricPanel && (
        <CustomPanel
          topText={
            "Talvez existam alterações não salvas, ir para o histórico de compradores mesmo assim?"
          }
          onCancel={handleCancelHistoricPanel}
          onConfirm={handleConfirmHistoricPanel}
        />
      )}
      <div
        ref={sidebarRef}
        className={`sidebar ${show ? "active" : ""}`}
        style={properties}
      >
        {/* <img src={logo} alt="Logo" className="logo" /> */}
        <ul className="sidebarr">
          <li>
            <button
              onClick={() => openCampaignsPanel()}
              className={activeButton === "campaignes" ? "active" : ""}
            >
              <MdOutlineSpaceDashboard />
              Campanhas
            </button>
          </li>
          <li>
            <button
              onClick={() => openHistoricPanel()}
              className={activeButton === "historic" ? "active" : ""}
            >
              <MdManageHistory />
              Histórico de compradores
            </button>
          </li>
          <li>
            <button
              onClick={() => toggleSettingsDropdown()}
              className={activeButton === "config" ? "active" : ""}
            >
              <IoSettingsOutline />
              Configurações
              {settingsDropdownOpen ? (
                <FaArrowDown
                  className={`arrow_down_icon ${
                    settingsDropdownOpen ? "rotate-down" : ""
                  }`}
                />
              ) : (
                <FaArrowUp
                  className={`arrow_down_icon ${
                    !settingsDropdownOpen ? "rotate-up" : ""
                  }`}
                />
              )}
            </button>
            {settingsDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <Link
                    className={activeSetting === "account" ? "active" : ""}
                    to="/dashboard/configuracoes/conta"
                    onClick={() => handleSettingClick("account")}
                  >
                    {" "}
                    <MdAccountCircle /> Conta
                  </Link>
                </li>
                <li>
                  <Link
                    className={activeSetting === "social" ? "active" : ""}
                    to="/dashboard/configuracoes/social"
                    onClick={() => handleSettingClick("social")}
                  >
                    <IoShareSocialOutline /> Redes sociais
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      activeSetting === "customization" ? "active" : ""
                    }
                    to="/dashboard/configuracoes/personalizacao"
                    onClick={() => handleSettingClick("customization")}
                  >
                    {" "}
                    <MdOutlineDashboardCustomize /> Personalização
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <button
              // to="/"
              onClick={() => exitFromDashboard()}
              className={activeButton === "home" ? "active" : ""}
            >
              <FiHome />
              Sair
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
