import React from "react";
import "./ErrorPanel.css";

const ErrorPanel = ({ errorText, backgroundColor = "#464646" }) => {
  // Painel para tratar erros. Usar esse componente para mostrar paineis de erro!

  return (
    <div className="main-container-error-panel" style={{ backgroundColor }}>
      <p>{errorText}</p>
    </div>
  );
};

export default ErrorPanel;
