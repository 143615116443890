import { React, useEffect, useState } from "react";
import "./Social.css";
import config from "../../../config";
import ErrorPanel from "../../../components/utils/ErrorPanel";

const Social = () => {
  const [whatsappLink, setWhatsappLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [telegramLink, setTelegramLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");

  const [changeWhatsappLinkSuccess, setChangeWhatsappLinkSuccess] =
    useState(false);
  const [whatsappLinkError, setWhatsappLinkError] = useState(false);
  const [whatsappLinkErrorText, setWhatsappLinkErrorText] = useState("");

  const [changeFacebookLinkSuccess, setChangeFacebookLinkSuccess] =
    useState(false);
  const [facebookLinkError, setFacebookLinkError] = useState(false);
  const [facebookLinkErrorText, setFacebookLinkErrorText] = useState("");

  const [changeTelegramLinkSuccess, setChangeTelegramLinkSuccess] =
    useState(false);
  const [telegramLinkError, setTelegramLinkError] = useState(false);
  const [telegramLinkErrorText, setTelegramLinkErrorText] = useState("");

  const [changeTwitterLinkSuccess, setChangeTwitterLinkSuccess] =
    useState(false);
  const [twitterLinkError, setTwitterLinkError] = useState(false);
  const [twitterLinkErrorText, setTwitterLinkErrorText] = useState("");

  const [changeInstagramLinkSuccess, setChangeInstagramLinkSuccess] =
    useState(false);
  const [instagramLinkError, setInstagramLinkError] = useState(false);
  const [instagramLinkErrorText, setInstagramLinkErrorText] = useState("");

  const getWhatsappLink = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/utils/getWhatsappLink.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do WhatsApp");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        setWhatsappLink(data.value);
        // return data.value;
      } else {
        setWhatsappLinkErrorText("Erro ao obter link do Whatsapp");
        setWhatsappLinkError(true);
        setTimeout(() => {
          setWhatsappLinkError(false);
        }, 6000);
        throw new Error("Link do WhatsApp não disponível");
      }
    } catch (error) {
      setWhatsappLinkErrorText("Erro ao obter link do Whatsapp");
      setWhatsappLinkError(true);
      setTimeout(() => {
        setWhatsappLinkError(false);
      }, 6000);
      console.error("Erro ao obter o link do WhatsApp:", error);
      // return null;
    }
  };

  const updateWhatsappNumber = async (newWhatsappLink) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("number", newWhatsappLink);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/setWhatsappNumber.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log(newWhatsappLink);
        console.log(whatsappLink);
        setWhatsappLink(newWhatsappLink);
        setChangeWhatsappLinkSuccess(true);
        setTimeout(() => {
          setChangeWhatsappLinkSuccess(false);
        }, 6000);
      } else {
        setWhatsappLinkErrorText("Erro ao alterar link do Whatsapp");
        setWhatsappLinkError(true);
        setTimeout(() => {
          setWhatsappLinkError(false);
        }, 6000);
      }
    } catch (error) {
      setWhatsappLinkErrorText("Erro ao alterar link do Whatsapp");
      setWhatsappLinkError(true);
      setTimeout(() => {
        setWhatsappLinkError(false);
      }, 2000);
      console.error(error);
    }
  };
  const getWhatsappNumber = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/getWhatsappNumber.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do WhatsApp");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        setWhatsappLink(data.value);
        // return data.value;
      } else {
        setWhatsappLinkErrorText("Erro ao obter link do Whatsapp");
        setWhatsappLinkError(true);
        setTimeout(() => {
          setWhatsappLinkError(false);
        }, 6000);
        throw new Error("Link do WhatsApp não disponível");
      }
    } catch (error) {
      setWhatsappLinkErrorText("Erro ao obter link do Whatsapp");
      setWhatsappLinkError(true);
      setTimeout(() => {
        setWhatsappLinkError(false);
      }, 6000);
      console.error("Erro ao obter o link do WhatsApp:", error);
      // return null;
    }
  };

  const updateWhatsappLink = async (newWhatsappLink) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("number", newWhatsappLink);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/setWhatsappNumber.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log(newWhatsappLink);
        console.log(whatsappLink);
        setWhatsappLink(newWhatsappLink);
        setChangeWhatsappLinkSuccess(true);
        setTimeout(() => {
          setChangeWhatsappLinkSuccess(false);
        }, 6000);
      } else {
        setWhatsappLinkErrorText("Erro ao alterar link do Whatsapp");
        setWhatsappLinkError(true);
        setTimeout(() => {
          setWhatsappLinkError(false);
        }, 6000);
      }
    } catch (error) {
      setWhatsappLinkErrorText("Erro ao alterar link do Whatsapp");
      setWhatsappLinkError(true);
      setTimeout(() => {
        setWhatsappLinkError(false);
      }, 2000);
      console.error(error);
    }
  };

  const getFacebookLink = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/utils/getFacebookLink.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do Facebook");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        setFacebookLink(data.value);
        // return data.value;
      } else {
        setFacebookLinkErrorText("Erro ao obter link do Facebook");
        setFacebookLinkError(true);
        setTimeout(() => {
          setFacebookLinkError(false);
        }, 4000);
        throw new Error("Link do Facebook não disponível");
      }
    } catch (error) {
      setFacebookLinkErrorText("Erro ao obter link do Facebook");
      setFacebookLinkError(true);
      setTimeout(() => {
        setFacebookLinkError(false);
      }, 4000);
      console.error("Erro ao obter o link do Facebook:", error);
      // return null;
    }
  };

  const updateFacebookLink = async (newFacebookLink) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("link", newFacebookLink);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/setFacebookLink.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log(newFacebookLink);
        console.log(newFacebookLink);
        setFacebookLink(newFacebookLink);
        setChangeFacebookLinkSuccess(true);
        setTimeout(() => {
          setChangeFacebookLinkSuccess(false);
        }, 4000);
      } else {
        setFacebookLinkErrorText("Erro ao alterar link do Facebook");
        setFacebookLinkError(true);
        setTimeout(() => {
          setFacebookLinkError(false);
        }, 4000);
      }
    } catch (error) {
      setFacebookLinkErrorText("Erro ao alterar link do Facebook");
      setFacebookLinkError(true);
      setTimeout(() => {
        setFacebookLinkError(false);
      }, 4000);
      console.error(error);
    }
  };

  const getTelegramLink = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/utils/getTelegramLink.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do Telegram");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        setTelegramLink(data.value);
        // return data.value;
      } else {
        setTelegramLinkErrorText("Erro ao obter link do Telegram");
        setTelegramLinkError(true);
        setTimeout(() => {
          setTelegramLinkError(false);
        }, 4000);
        throw new Error("Link do Telegram não disponível");
      }
    } catch (error) {
      setTelegramLinkErrorText("Erro ao obter link do Telegram");
      setTelegramLinkError(true);
      setTimeout(() => {
        setTelegramLinkError(false);
      }, 4000);
      console.error("Erro ao obter o link do Telegram:", error);
      // return null;
    }
  };

  const updateTelegramLink = async (newTelegramLink) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("link", newTelegramLink);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/setTelegramLink.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log(newTelegramLink);
        console.log(newTelegramLink);
        setTelegramLink(newTelegramLink);
        setChangeTelegramLinkSuccess(true);
        setTimeout(() => {
          setChangeTelegramLinkSuccess(false);
        }, 3500);
      } else {
        setTelegramLinkErrorText("Erro ao alterar link do Telegram");
        setTelegramLinkError(true);
        setTimeout(() => {
          setTelegramLinkError(false);
        }, 3500);
      }
    } catch (error) {
      setTelegramLinkErrorText("Erro ao alterar link do Telegram");
      setTelegramLinkError(true);
      setTimeout(() => {
        setTelegramLinkError(false);
      }, 3500);
      console.error(error);
    }
  };

  const getTwitterLink = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/utils/getTwitterLink.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do Twitter");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        setTwitterLink(data.value);
        // return data.value;
      } else {
        setTwitterLinkErrorText("Erro ao obter link do Twitter");
        setTwitterLinkError(true);
        setTimeout(() => {
          setTwitterLinkError(false);
        }, 4000);
        throw new Error("Link do Twitter não disponível");
      }
    } catch (error) {
      setTwitterLinkErrorText("Erro ao obter link do Twitter");
      setTwitterLinkError(true);
      setTimeout(() => {
        setTwitterLinkError(false);
      }, 4000);
      console.error("Erro ao obter o link do Twitter:", error);
      // return null;
    }
  };

  const updateTwitterLink = async (newTwitterLink) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("link", newTwitterLink);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/setTwitterLink.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log(newTwitterLink);
        console.log(newTwitterLink);
        setTwitterLink(newTwitterLink);
        setChangeTwitterLinkSuccess(true);
        setTimeout(() => {
          setChangeTwitterLinkSuccess(false);
        }, 3500);
      } else {
        setTwitterLinkErrorText("Erro ao alterar link do Twitter");
        setTwitterLinkError(true);
        setTimeout(() => {
          setTwitterLinkError(false);
        }, 3500);
      }
    } catch (error) {
      setTwitterLinkErrorText("Erro ao alterar link do Twitter");
      setTwitterLinkError(true);
      setTimeout(() => {
        setTwitterLinkError(false);
      }, 3500);
      console.error(error);
    }
  };

  const getInstagramLink = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/utils/getInstagramLink.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o link do Instagram");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        setInstagramLink(data.value);
        // return data.value;
      } else {
        setInstagramLinkErrorText("Erro ao obter link do Instagram");
        setInstagramLinkError(true);
        setTimeout(() => {
          setInstagramLinkError(false);
        }, 2000);
        throw new Error("Link do Instagram não disponível");
      }
    } catch (error) {
      setInstagramLinkErrorText("Erro ao obter link do Instagram");
      setInstagramLinkError(true);
      setTimeout(() => {
        setInstagramLinkError(false);
      }, 2000);
      console.error("Erro ao obter o link do Instagram:", error);
      // return null;
    }
  };

  const updateInstagramLink = async (newInstagramLink) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("link", newInstagramLink);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/setInstagramLink.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log(newInstagramLink);
        console.log(newInstagramLink);
        setInstagramLink(newInstagramLink);
        setChangeInstagramLinkSuccess(true);
        setTimeout(() => {
          setChangeInstagramLinkSuccess(false);
        }, 2000);
      } else {
        setInstagramLinkErrorText("Erro ao alterar link do Instagram");
        setInstagramLinkError(true);
        setTimeout(() => {
          setInstagramLinkError(false);
        }, 2000);
      }
    } catch (error) {
      setInstagramLinkErrorText("Erro ao alterar link do Instagram");
      setInstagramLinkError(true);
      setTimeout(() => {
        setInstagramLinkError(false);
      }, 3500);
      console.error(error);
    }
  };

  const handleSave = async () => {
    await updateWhatsappNumber(whatsappLink);
    // await updateFacebookLink(facebookLink);
    // await updateTelegramLink(telegramLink);
    // await updateTwitterLihk(twitterLink);
    await updateInstagramLink(instagramLink);
  };

  useEffect(() => {
    getWhatsappNumber();
    // getFacebookLink();
    // getTelegramLink();
    // getTwitterLink();
    getInstagramLink();
  }, []);

  return (
    <div className="main-social">
      {changeWhatsappLinkSuccess && (
        <ErrorPanel
          errorText={"Número do Whatsapp alterado com sucesso!"}
          backgroundColor={"#4caf08"}
        />
      )}

      {changeFacebookLinkSuccess && (
        <ErrorPanel
          errorText={"Link do Facebook alterado com sucesso!"}
          backgroundColor={"#4caf08"}
        />
      )}
      {changeTelegramLinkSuccess && (
        <ErrorPanel
          errorText={"Link do Telegram alterado com sucesso!"}
          backgroundColor={"#4caf08"}
        />
      )}
      {changeTwitterLinkSuccess && (
        <ErrorPanel
          errorText={"Link do Twitter alterado com sucesso!"}
          backgroundColor={"#4caf08"}
        />
      )}
      {changeInstagramLinkSuccess && (
        <ErrorPanel
          errorText={"Link do Instagram alterado com sucesso!"}
          backgroundColor={"#4caf08"}
        />
      )}

      {whatsappLinkError && (
        <ErrorPanel errorText={whatsappLinkErrorText} backgroundColor={"red"} />
      )}

      {facebookLinkError && (
        <ErrorPanel errorText={facebookLinkErrorText} backgroundColor={"red"} />
      )}
      {telegramLinkError && (
        <ErrorPanel errorText={telegramLinkErrorText} backgroundColor={"red"} />
      )}
      {twitterLinkError && (
        <ErrorPanel errorText={twitterLinkErrorText} backgroundColor={"red"} />
      )}
      {instagramLinkError && (
        <ErrorPanel
          errorText={instagramLinkErrorText}
          backgroundColor={"red"}
        />
      )}

      <div className="title_text_main_social">
        <h1>Redes sociais</h1>
      </div>
      <div className="main_container_social">
        <div className="sub_container_1_social">
          <label>Número para contato (Whatsapp)</label>
          <input
            value={whatsappLink}
            onChange={(e) => setWhatsappLink(e.target.value)}
          ></input>
        </div>
        {/* <div className="sub_container_2_social">
          <label>Grupo do Facebook</label>
          <input
            value={facebookLink}
            onChange={(e) => setFacebookLink(e.target.value)}
          ></input>
        </div>
        {/*<div className="sub_container_3_social">
          <label>Grupo do Telegram</label>
          <input
            value={telegramLink}
            onChange={(e) => setTelegramLink(e.target.value)}
          ></input>
        </div>
        <div className="sub_container_4_social">
          <label>Perfil do Twitter</label>
          <input
            value={twitterLink}
            onChange={(e) => setTwitterLink(e.target.value)}
          ></input>
        </div> */}
        <div className="sub_container_5_social">
          <label>Perfil do Instagram</label>
          <input
            value={instagramLink}
            onChange={(e) => setInstagramLink(e.target.value)}
          ></input>
        </div>
        <div className="save_button_container">
          <button onClick={() => handleSave()}>salvar</button>
        </div>
      </div>
    </div>
  );
};

export default Social;
