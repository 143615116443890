import React, { useState } from "react";
import "./HomeNavbarLayout1.css";
import { FcSearch } from "react-icons/fc";
import Logo from "./Sol_E_Mar_Logo.webp"

const HomeNavbarLayout1 = ({showPopupNav}) => {

    return (
        <div className="home-top-bar-main_layout_1">
            <div className="home-top-bar-left_layout_1">
                <img className="logo-img-bar-left_layout_1" src={Logo}></img>
                {/*<h4>Logo do cliente</h4> */}
            </div>
            <div className="home-top-bar-right_layout_1">
                <button 
                className="home-tickets_layout_1"
                onClick={showPopupNav}
                >
                    <FcSearch /> 
                    Meus bilhetes
                </button>
            </div>
        </div>
    )
};

export default HomeNavbarLayout1;