import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config";
import "./Payment.css";
import { formatNumber } from "../../components/utils/Utils";
import copy from "clipboard-copy";
import { IoIosCopy } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegSadCry } from "react-icons/fa";

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const tagFromUrl = params.get("tag");
  const idFromUrl = params.get("lastId");

  const [timeLeft, setTimeLeft] = useState();
  const [fullName, setFullName] = useState("");
  const [campaignTitle, setCampaignTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [copyAndPaste, setCopyAndpaste] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isError, setError] = useState(false);

  // CronJob
  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendDomain = config.backendDomain;
        const url = `${backendDomain}/backend/src/view/utils/execCronJob.php`;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
        throw new Error("Falha ao chamar o endpoint do cronjob");
        }
      } catch (error) {
        console.error('Erro ao chamar o endpoint do cronjob', error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000); // 60 segundos

    return () => clearInterval(interval);
  }, []);

  const fetchInitialTime = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append("tag", tagFromUrl);
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/purchase/getTimeExpirePurchase.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );
      const data = await response.json();

      if (response.ok) {
        setTimeLeft(data.value.time_expire);
        console.log(data.value.time_expire);

        if (data.value.status === 1) {
          navigate("/pagamentos/pagamentoConcluido");
        } else if (data.value.status === 2) {
          setErrorText("Pagamento expirado");
          setError(true);
        }
      }
    } catch (error) {
      console.error("Erro ao buscar tempo do endpoint", error);
      setErrorText("Algo de errado aconteceu ao carregar seu pagamento");
      setError(true);
    }
  };

  const getValidId = async () => {
    try {
      const backendDomain = config.backendDomain;

      const urlCheckCampaign = `${backendDomain}/backend/src/view/home/campaing/getCampaingFromHome.php`;

      const requestOptionsCheckCampaign = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const responseCheckCampaign = await fetch(
        urlCheckCampaign,
        requestOptionsCheckCampaign
      );

      if (responseCheckCampaign.ok) {
        const data = await responseCheckCampaign.json();
        // console.log("Id valido", data.value.id);
        return data.id;
      }
    } catch (error) {}
  };

  /* const handleRedirect = async () => {
    try {
      const validId = await getValidId();
      console.log(validId);
      navigate(`/?id=${validId}`);
    } catch (error) {
      console.error("Erro ao obter ID válido:", error);
    }
  }; */

  const handleRedirect = () => {
    navigate(`/?id=${idFromUrl}`);
  };

  /* useEffect(() => {
    fetchInitialTime();
  }, []); */

  useEffect(() => {
    // Chama a função para buscar o tempo do endpoint a cada 1 minuto e 5 segundos
    const intervalId = setInterval(() => {
      fetchInitialTime();
    }, 65000); // 1 minuto e 5 segundos em milissegundos

    // Chama a função para buscar o tempo do endpoint imediatamente ao montar o componente
    fetchInitialTime();

    console.log("atualizou");
    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    if (minutes <= 0 || (minutes <= 0 && seconds <= 0)) {
      setErrorText("Pagamento expirado");
      setError(true);
    }

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const [divSize, setDivSize] = useState({
    width: "100%",
    height: "100%",
  });

  const [borderColor, setBorderColor] = useState({
    color: "1px solid #c7c7c7",
  });

  const [infoContentSize, setInfoContentSize] = useState({
    width: "",
  });

  const copyContent = (link) => {
    const linkToCopy = link;

    copy(linkToCopy)
      .then(() => {
        alert("Código copiado para a área de transferência");
      })
      .catch((err) => console.error("Erro ao copiar link: ", err));
  };

  const loadPaymentInfo = async () => {
    try {
      const formData = new URLSearchParams();

      formData.append("tag", tagFromUrl);

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/purchase/findPendingPurchase.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setQrCodeLink(data.value.qr_code);
        setCopyAndpaste(data.value.copy_and_paste);
        setCampaignTitle(data.value.campaing_title);
        setFullName(data.value.name);
        setPhone(data.value.phone_number);
        setEmail(data.value.email);
        setQuantity(data.value.shares);
        setPrice(formatNumber(data.value.price_of_payment));
        console.log("qr code gerado!" + data.value.qr_code);
      }
    } catch (error) {
      console.error("Erro ao solicitar compra", error);
      setErrorText("Algo de errado aconteceu ao carregar seu pagamento");
      setError(true);
    }
  };

  useEffect(() => {
    loadPaymentInfo();
  }, []);

  useEffect(() => {
    const adjustDivSize = () => {
      const isDesktop = window.innerWidth > 525; // default 768

      if (isDesktop) {
        setDivSize({
          width: "450px",
          height: "100vh",
        });
        setBorderColor({
          color: "1px solid #dddddd000",
        });
      } else {
        setDivSize({
          width: "100%",
          height: "100vh",
        });
        setBorderColor({
          color: "1px solid rgba(0, 0, 0, 0)",
        });
      }

      if (window.innerWidth > 600) {
        setInfoContentSize({
          width: "40%",
        });
      } else {
        setInfoContentSize({
          width: "100%",
        });
      }
    };

    window.addEventListener("resize", adjustDivSize);

    adjustDivSize();

    // redirecionando caso não exista uma tag
    if (!tagFromUrl) {
      navigate("/");
    }

    return () => {
      window.removeEventListener("resize", adjustDivSize);
    };
  }, [tagFromUrl]);

  return (
    <div className="main-payment">
      <div
        className="main-container"
        style={{
          width: divSize.width,
          height: divSize.height,
          border: borderColor.color,
        }}
      >
        {!isError && (
          <div className="sub-main-container-payment">
            <div className="sub-main-div-2-payment">
              <div className="timer-container">
                <div className="timer-text">
                  <h2>
                    Pague em até <br />{" "}
                    {timeLeft > 0
                      ? minutes > 0
                        ? `${minutes} minutos ${seconds} segundos`
                        : `${seconds} segundos`
                      : "Tempo expirado"}
                  </h2>
                </div>
              </div>
            </div>
            <div className="sub-main-div-1-payment">
              <div className="qr-code-container">
                {qrCodeLink && (
                  <img
                    className="qr-code-img"
                    src={`data:image/png;base64, ${qrCodeLink}`}
                    alt="QR Code"
                  />
                )}
              </div>
              <div
                className="copy-and-paste"
                onClick={() => copyContent(copyAndPaste)}
              >
                <p className="copy-and-paste-code">{copyAndPaste} </p>
                <button className="copy-btn">
                  Copiar código
                  {/* <IoIosCopy className="copy-icon" /> */}
                </button>
              </div>
            </div>

            <div className="sub-main-div-3-payment">
              <div className="sub-main-info-content-payment">
                <div className="info-content-1">
                  <div className="info-content-1-1">
                    <p>CAMPANHA</p>
                  </div>
                  <div className="info-content-1-2">
                    <p>{campaignTitle}</p>
                  </div>
                </div>
                <div className="info-content-2">
                  <div className="info-content-2-1">
                    <p>NOME COMPLETO</p>
                  </div>
                  <div className="info-content-2-2">
                    <p>{fullName}</p>
                  </div>
                </div>
                <div className="info-content-3">
                  <div className="info-content-3-1">
                    <p>EMAIL</p>
                  </div>
                  <div className="info-content-3-2">
                    <p>{email}</p>
                  </div>
                </div>
                <div className="info-content-4">
                  <div className="info-content-4-1">
                    <p>TELEFONE</p>
                  </div>
                  <div className="info-content-4-2">
                    <p>{phone}</p>
                  </div>
                </div>
                <div className="info-content-5">
                  <div className="info-content-5-1">
                    <p>BILHETES</p>
                  </div>
                  <div className="info-content-5-2">
                    <p>{quantity}</p>
                  </div>
                </div>
                <div className="info-content-6">
                  <div className="info-content-6-1">
                    <p>TOTAL</p>
                  </div>
                  <div className="info-content-6-2">
                    <p>R$ {price}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isError && (
          <div className="main-campaign-not-found">
            <div></div>
            <div className="sub-main-campaign-not-found">
              <div className="container-campaign-not-found">
                <FaRegSadCry className="error-icon" />
                <h1>{errorText}</h1>
              </div>
              <div className="go-to">
                <button onClick={() => handleRedirect()}>
                  Voltar para campanha
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Payment;
