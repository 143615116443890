import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Config from "../../config";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();

  // login
  const [showLogin, setShowLogin] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");
  const [inputToken, setInputToken] = useState("");

  const handleTokenChange = (e) => {
    setInputToken(e.target.value);
  };

  const handleLogin = async (token) => {
    try {
      // Supondo que você tenha uma senha disponível
      // const pass = "12345678";

      // Crie um objeto URLSearchParams para serializar os dados
      const formData = new URLSearchParams();
      formData.append("password", token);

      const response = await fetch(
        `${Config.backendDomain}/backend/src/view/dashboard/userWorkFlow/login/loginUserMaster.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(), // Converte o objeto URLSearchParams em uma string
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const endpointToken = responseData.value.token;

        // Salvar o token em localStorage
        localStorage.setItem("accessToken", endpointToken);
        console.log(endpointToken);

        if ((await responseData.status) === "success") {
          // Redirecionar para a página /campaignes
          navigate("/dashboard");
          setLoginMessage("Login feito com sucesso.");
          // setErrorMessage("Login feito com successo");
        } else {
          setLoginMessage("Erro ao fazer login, token inválido.");
          // setErrorMessage(
          //  "Erro ao fazer login, token inválido" + response.status
          //);
        }
      } else {
        setLoginMessage("Erro ao fazer login.");
        // setErrorMessage(
        //  "Erro ao fazer login, token inválido" + response.status
        // );
      }
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      setLoginMessage("Chave inválida");
      // setErrorMessage("Erro ao fazer login, token inválido");
    }
  };

  const handleLoginClick = () => {
    handleLogin(inputToken);
  };

  const handleBack = () => {
    navigate("/");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(inputToken);
    }
  };

  // Função para verificar se o token é válido (segundo endpoint)
  const isValidToken = async (token) => {
    try {
      const response = await fetch(
        `${Config.backendDomain}/backend/src/view/dashboard/userWorkFlow/login/checkToken.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `token=${token}`,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("valid token");
        return responseData.status === "success";
      }

      return false;
    } catch (error) {
      console.error("Erro ao verificar token:", error);
      return false;
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      // Verificar se há um token salvo no localStorage
      const savedToken = localStorage.getItem("accessToken");

      // Se houver um token salvo, verificar se é válido
      if (savedToken && (await isValidToken(savedToken))) {
        // Token válido, pode prosseguir com a lógica da aplicação
        navigate("/dashboard");
        setShowLogin(false);
      } else {
        // Token inválido ou não encontrado, exibir tela de login
        setShowLogin(true);
        navigate("/login");
      }
    };

    // Executar a verificação ao carregar a página
    checkToken();
  }, []); // O array vazio como dependência garante que este efeito seja executado apenas na montagem

  return (
    <div className="login-layout">
      <div className="login-layout-back">
        <h2>Login</h2>
        <label className="label-token-access" htmlFor="tokenInput">
          Chave de Acesso
        </label>
        <input
          className="token-input-access"
          placeholder="Insira a chave de acesso"
          type="password"
          id="tokenInput"
          value={inputToken}
          onChange={handleTokenChange}
          onKeyDown={handleKeyDown}
        />
        <div>
          <button className="login-access" onClick={handleLoginClick}>
            Login
          </button>
          <button className="login-access" onClick={handleBack}>
            Voltar
          </button>
        </div>
        {loginMessage && <div className="login-message">{loginMessage}</div>}
      </div>
    </div>
  );
};

export default Login;
