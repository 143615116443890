import React from "react";
import "./FindTicketsDetails.css";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { HiTicket } from "react-icons/hi2";
import { FaCalendarDays } from "react-icons/fa6";
import { formatNumber } from "../../../components/utils/Utils";

const getBuyerStateClass = (status) => {
  switch (status) {
    case 0:
      return "pending"; // Classe CSS para o estado "Pendente"
    case 1:
      return "approved"; // Classe CSS para o estado "Aprovado"
    case 2:
      return "expired"; // Classe CSS para o estado "Expirado"
    case 3:
      return "canceled"; // Classe CSS para o estado "Recusado"
    default:
      return ""; // Classe padrão caso o status seja desconhecido
  }
};

const FindTicketsDetails = ({
  campaign,
  buyers,
  buyer,
  filters,
  startIndex,
  endIndex,
  itemsPerPage,
  phoneNumber,
  showData
}) => {
  // Renderiza os detalhes dos compradores para a página atual
  /* const currentItems = buyers ? buyers.slice(startIndex, endIndex) : [];

    console.log("Dados dos compradores:", currentItems); // Adicionando um console.log para verificar os dados dos compradores */

  const formatPhoneNumber = (phoneNumber) => {
    // Remove caracteres não numéricos do número de telefone
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    // Aplica a formatação desejada
    const formattedPhoneNumber = cleanedPhoneNumber.replace(
      /(\d{2})(\d{5})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  if (!buyers || buyers.length === 0) {
    return <div className="info-buyers-find-tickets">Selecione uma campanha</div>;
  }

  // Filtrar os compradores com base nos filtros selecionados
    const filteredBuyers = buyers.filter((buyer) => {
    // Se nenhum filtro estiver selecionado, exibe todos os compradores
    if (
      !filters.paid &&
      !filters.pending &&
      !filters.expired &&
      !filters.canceled
    ) {
      return true;
    }
    // Verifica se o comprador corresponde a pelo menos um filtro selecionado
    return (
      (filters.paid && buyer.purchase.status === 1) ||
      (filters.pending && buyer.purchase.status === 0) ||
      (filters.expired && buyer.purchase.status === 2) ||
      (filters.canceled && buyer.purchase.status === 3)
    );
  });

  return (
    <div className="content-buyer-main-find-tickets">
      {/* Renderiza os detalhes dos compradores da página atual */}
      {filteredBuyers.map((buyer, index) => (
        <div key={index} className="content-buyer-detail-main-find-tickets">
          {/* Renderiza os detalhes do comprador */}
          <div className="content-1-find-tickets">
            <div className="content-1--find-tickets">
              {/* <div className="buyerName-find-tickets">
                {/* {buyer.purchase.price}
              </div> */}
              <div className="buyerState-main-find-tickets">
                <div className={`buyerState-find-tickets ${getBuyerStateClass(buyer.purchase.status)}`}>
                  {(() => {
                    switch (buyer.purchase.status) {
                      case 0:
                        return "Pendente";
                      case 1:
                        return "Aprovado";
                      case 2:
                        return "Expirado";
                      case 3:
                        return "Recusado";
                      default:
                        return "Desconhecido";
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
          <div className="content-2-find-tickets">
            <div className={`content-2--find-tickets ${showData ? '' : ' content-2-find-tickets-blur-details'}`}>
              {/* <p>
                <MdEmail /> Email {buyer.purchase.price}
              </p> */}
              <p>
                <FaPhone className="phone-icon-find-tickets"/> Telefone {formatPhoneNumber(phoneNumber)}
              </p>
              <p>
                <RiMoneyDollarCircleFill /> Valor R${" "}
                {formatNumber(buyer.purchase.price)}
              </p>
              <p>
                <HiTicket /> Bilhetes {buyer.purchase.tickets}
              </p>
              <p>
                <FaCalendarDays /> Comprado em {buyer.purchase.purchase_date}
              </p>
            </div>
          </div>
          <div className="content-3-find-tickets">
            <div className="ticket-container-find-tickets">
              <div className="ticket-find-tickets">
                {buyer.tickets.map((ticket, index) => (
                  <div key={index} className="ticket-item-find-tickets">
                    {ticket}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FindTicketsDetails;
