import React from "react";
import { BiSolidError } from "react-icons/bi";
import "./Error404Page.css"
import config from "../../config";
import { Navigate, useNavigate } from "react-router-dom";

const Error404Page = () => {

    const navigate = useNavigate();

    const getValidId = async () => {
        try {
          const backendDomain = config.backendDomain;
    
          const urlCheckCampaign = `${backendDomain}/backend/src/view/home/campaing/getCampaingFromHome.php`;
    
          const requestOptionsCheckCampaign = {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          };
    
          const responseCheckCampaign = await fetch(
            urlCheckCampaign,
            requestOptionsCheckCampaign
          );  
    
          if (responseCheckCampaign.ok) {
            const data = await responseCheckCampaign.json();
            // console.log("Id valido", data.value.id);
            return data.id;
          }
        } catch (error) {}
      };
    
      const handleRedirect = async () => {
        try {
            const validId = await getValidId();
            console.log(validId);
            navigate(`/?id=${validId}`);
          } catch (error) {
            console.error("Erro ao obter ID válido:", error);
        }
      };
    return (
        <div className="main-campaign-not-found">
          <div className="sub-main-campaign-not-found">
            <div className="container-campaign-not-found">
              <BiSolidError className="error-icon" />
              <h1>Erro 404, Página não encontrada!</h1>
            </div>
            <div className="go-to">
              <button onClick={() => handleRedirect()}>
                Ir para página existente
              </button>
            </div>
          </div>
        </div>
      );
};

export default Error404Page;