import React, { useState, useEffect } from "react";
import CampaignDetails from "./CampaignDetails";
import config from "../config";
import "../pages/campaigns/Campaigns.css";
import { useNavigate } from "react-router-dom";
import defaultImage from "../assets/images/Image_not_found.png";
import { BiSolidError } from "react-icons/bi";

const FloatingPanel = () => {
  const navigate = useNavigate();

  // login
  const [showLogin, setShowLogin] = useState(true);

  // Estados para armazenar dados das campanhas, do formulário e controle de visibilidade
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [campaignPercent, setCampaignPercent] = useState(0);
  // Defina as variáveis minValue, initialQuantity e maxValue
  const minValue = 1;
  const maxValue = 1000000;
  // Definindo o estado com o valor inicial quando a página é carregada
  const [shares, setQuantity] = useState(minValue);
  const [priceShare, setPrice] = useState(0);
  const [finish_date, setFinishDate] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [editingCampaign, setEditingCampaign] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [mainContainerProperties, setMainContainerProperties] = useState({
    width: "",
    marginLeft: "",
  });
  const [topTextProperties, setTopTextProperties] = useState({
    marginLeft: "",
  });

  useEffect(() => {

    const adjust = () => {

      const isDesktop = window.innerWidth > 725; // default - 768
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      // Definindo uma proporção máxima para a altura em relação à largura
      const maxAspectRatio = 1.5; // Ajuste conforme necessário

      // Definindo o limite máximo para a altura da imagem
      const maxHeightLimit = 600; // Ajuste conforme necessário

      // Calculando a altura máxima com base na largura e altura da tela
      let maxHeightWidth = windowWidth * maxAspectRatio;
      let maxHeightHeight;

      if (windowWidth < 475) {
        maxHeightHeight = windowHeight * 0.3; // Porcentagem da altura da tela
 
      } else {
        maxHeightHeight = windowHeight * 0.8; // Porcentagem da altura da tela
      }

      if (isDesktop) {
        setMainContainerProperties({
          width: "calc(100% - 216px)",
          marginLeft: "234px",
        });
        setTopTextProperties({
          marginLeft: "0",
        });
        maxHeightHeight = windowHeight * 0.8; // Porcentagem da altura da tela
      } else {
        setMainContainerProperties({
          width: "100%",
          marginLeft: "0",
        });
        setTopTextProperties({
          marginLeft: "60px",
        });
      }

      // Escolhendo o menor valor entre a altura máxima calculada com base na largura e na altura
      let maxHeight = Math.min(maxHeightWidth, maxHeightHeight, maxHeightLimit);

    }

    window.addEventListener("resize", adjust);

    adjust();

    return () => {
      window.removeEventListener("resize", adjust);
    };
  }, []);

  /* useEffect(() => {
    loadCampaignsInfo();
  }, []); */

  /* const checkTokenValidity = async () => {
    try {
      const token = localStorage.getItem('accessToken');

      if (await isValidToken(token)) {
        setShowLogin(false);
        setLoginMessage('Login feito com sucesso.');
      } else {
        setShowLogin(true);
        setLoginMessage('Erro ao fazer login, token inválido.');
      }
    } catch (error) {
      console.error('Erro ao verificar token:', error);
    }
  }; */

  // Estado para controlar se as campanhas estão sendo carregadas
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(true);
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [isErrorCreateCampaign, setIsErrorCreateCampaign] = useState(false);

  // Estado para controlar se todas as informações da campanha (exceto a imagem) foram carregadas
  const [isCampaignInfoLoaded, setIsCampaignInfoLoaded] = useState(false);
  const [isImageCampaignLoading, setImageCampaignLoading] = useState(true);

  // Função para verificar se o token é válido (segundo endpoint)
  const isValidToken = async (token) => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/userWorkFlow/login/checkToken.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `token=${token}`,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("valid token");
        return responseData.status === "success";
      }

      return false;
    } catch (error) {
      console.error("Erro ao verificar token:", error);
      return false;
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      // Verificar se há um token salvo no localStorage
      const savedToken = localStorage.getItem("accessToken");

      // Se houver um token salvo, verificar se é válido
      if (savedToken && (await isValidToken(savedToken))) {
        // Token válido, pode prosseguir com a lógica da aplicação
        setShowLogin(false);
      } else {
        // Token inválido ou não encontrado, exibir tela de login
        setShowLogin(true);
        navigate("/login");
      }
    };

    // Executar a verificação ao carregar a página
    checkToken();
  }, []); // O array vazio como dependência garante que este efeito seja executado apenas na montagem

  // Novos estados para controle de exclusão
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);

  const redirectToDashboard = (id) => {
    navigate(`/dashboard`);
    window.location.reload(false);
  };

  const redirectWithId = (id) => {
    // Redirecionar para a página padrão com o ID
    // navigate(`/?id=${id}`);
    window.open(`/?id=${id}`, "_blank");
  };

  const redirectWithIdForEdit = (id) => {
    localStorage.setItem("pageMode", "edit");
    // Redirecionar para a página padrão com o ID
    navigate(`/dashboard/edicaoDeCampanhas/?id=${id}`);
    // window.open(`/dashboard/edicaoDeCampanhas/?id=${id}`, "_blank");
    loadCampaignsInfo();
  };

  const loadCampaignsInfo = async () => {
    try {
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/home/campaing/listCampaings.php`
      );
      const data = await response.json();

      if (Array.isArray(data.value)) {
        setCampaigns(data.value);
        setIsCampaignInfoLoaded(true); // Definir como true quando as informações da campanha foram carregadas
        setIsLoadingCampaigns(false);
      } else {
        setCampaigns([]);
        console.error(
          "A resposta do backend não contém um array de campanhas."
        );
      }
    } catch (error) {
      console.error("Erro ao obter campanhas do backend:", error);
    }
  };

  useEffect(() => {
    // Executar a carga das informações da campanha
    loadCampaignsInfo();
  }, []);

  // Efeito para carregar as imagens quando todas as informações da campanha (exceto a imagem) estiverem carregadas
  useEffect(() => {
    if (isCampaignInfoLoaded && isImageCampaignLoading) {
      const loadCampaignImages = async () => {
        try {
          const campaignsWithImages = await Promise.all(
            campaigns.map(async (campaign) => {
              try {
                const formData = new URLSearchParams();

                formData.append("campaing_id", campaign.id);
                formData.append("image_id", 1);
                const imageResponse = await fetch(
                  `${config.backendDomain}/backend/src/view/home/campaing/getCampaingImage.php`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: formData.toString(),
                  }
                );

                if (imageResponse.ok) {
                  const imageData = await imageResponse.blob();
                  const imageUrl = URL.createObjectURL(imageData);
                  console.log("URL: " + imageUrl);

                  return { ...campaign, image: imageUrl };
                } else {
                  console.error(
                    `Erro ao carregar imagem para a campanha com ID ${campaign.id}`
                  );

                  return { ...campaign, image: defaultImage };
                }
              } catch (error) {
                console.error(
                  `Erro ao carregar imagem para a campanha com ID ${campaign.id}:`,
                  error
                );
                return campaign;
              }
            })
          );

          setCampaigns(campaignsWithImages);
          setImageCampaignLoading(false);
        } catch (error) {
          console.error("Erro ao carregar imagens das campanhas:", error);
        }
      };

      // Executar a carga das imagens
      loadCampaignImages();
    }
  }, [isCampaignInfoLoaded, campaigns, isImageCampaignLoading]);

  // Efeito para limpar a mensagem de erro após 3 segundos
  useEffect(() => {
    const initialQuantity = 1;
    setQuantity(Math.max(minValue, Math.min(initialQuantity, maxValue)));
    let timer;
    if (errorMessage) {
      timer = setTimeout(() => {
        setErrorMessage(null);
      }, 3000); // 3 segundos
    }
    return () => clearTimeout(timer);
  }, [errorMessage]);

  // Manipuladores de eventos para alterar os estados do formulário
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleQuantityChange = (event) => {
    const inputValue = parseInt(event.target.value, 10);

    if (inputValue <= 0) {
      setQuantity(1);
    }

    if (inputValue >= minValue && inputValue <= maxValue) {
      setQuantity(inputValue);
    }
  };

  const handlePriceChange = (e) => {
    setPrice(parseFloat(e.target.value));
  };

  const handleDrawDateChange = (e) => {
    setFinishDate(e.target.value);
  };

  // Função para validar os campos do formulário antes de criar/editar uma campanha
  const validateCampaignFields = () => {
    if (
      !image ||
      !title ||
      !description ||
      !shares ||
      !priceShare /* ||
      !finish_date */
    ) {
      setErrorMessage("Por favor, preencha todos os campos.");
      return false;
    }
    return true;
  };

  // Função para criar uma nova campanha
  const handleCreateCampaign = async () => {
    // if (validateCampaignFields()) {
    const newCampaign = {
      image /* : URL.createObjectURL(image) */,
      title,
      description,
      shares,
      priceShare,
      finish_date,
      campaignPercent,
    };

    const sendCampaignToBackend = async () => {
      setIsLoadingCampaigns(true);
      setIsCreatingCampaign(true);
      try {
        const token = localStorage.getItem("accessToken");

        const formData = new URLSearchParams();
        formData.append("token", token);
        const response = await fetch(
          `${config.backendDomain}/backend/src/view/dashboard/campaing/createCampaing.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
          }
        );

        if (response.ok) {
          const responseData = await response.json(); // Converte a resposta para JSON

          // Extrai o campo "id" do JSON de resposta
          const campaignId = responseData.value.id;
          console.log(campaignId);
          localStorage.setItem("pageMode", "create");
          navigate(`/dashboard/edicaoDeCampanhas/?id=${campaignId}`, "_blank");
          // redirectWithIdForEdit(campaignId);
        }
      } catch {
        setIsErrorCreateCampaign(true);
        console.error("Erro ao enviar dados para o backend:");
        setErrorMessage("Erro ao enviar dados para o backend");
      }
    };

    // Chama a função para enviar a nova campanha para o backend
    sendCampaignToBackend();
    setCampaigns([...campaigns, newCampaign]);
    loadCampaignsInfo();
    setIsPanelVisible(false);
    clearForm();
    // }
  };

  // Função para atualizar uma campanha existente após edição
  const handleUpdateCampaign = () => {
    if (validateCampaignFields()) {
      const updatedCampaigns = campaigns.map((campaign) => {
        if (campaign === editingCampaign) {
          return {
            ...campaign,
            image,
            title,
            description,
            shares,
            priceShare,
            finish_date,
            campaignPercent,
          };
        }
        return campaign;
      });

      setCampaigns(updatedCampaigns);
      setIsPanelVisible(false);
      setEditingCampaign(null);
      clearForm();
    }
  };

  // Função para cancelar a edição de uma campanha existente
  const handleCancelEdit = () => {
    setEditingCampaign(null);
    setIsPanelVisible(false);
    clearForm();
  };

  // Função para cancelar a criação de uma nova campanha
  const handleCancelCreate = () => {
    setIsPanelVisible(false);
    clearForm();
  };

  // Função para criar ou atualizar uma campanha e fechar o painel
  const handleCreateAndClosePanel = () => {
    if (editingCampaign) {
      handleUpdateCampaign();
    } else {
      handleCreateCampaign();
    }
  };

  // Função para iniciar a exclusão de uma campanha
  const handleDeleteCampaign = (campaign) => {
    handleCancelCreate();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adiciona um efeito suave à rolagem
    });
    setCampaignToDelete(campaign);
    setIsDeleteConfirmationVisible(true);
  };

  // Função para confirmar a exclusão de uma campanha
  const handleConfirmDelete = () => {
    const updatedCampaigns = campaigns.filter(
      (campaign) => campaign !== campaignToDelete
    );
    setCampaigns(updatedCampaigns);
    setIsDeleteConfirmationVisible(false);
    setCampaignToDelete(null);
  };

  // Função para cancelar a exclusão de uma campanha
  const handleCancelDelete = () => {
    setCampaignToDelete(null);
    setIsDeleteConfirmationVisible(false);
  };

  // Função para limpar os campos do formulário
  const handleClearForm = () => {
    clearForm();
  };

  // Função para limpar todos os campos do formulário
  const clearForm = () => {
    setImage(null);
    setTitle("Seu título");
    setDescription("Sua descrição");
    setQuantity(1);
    setPrice(0.1);
    setFinishDate("");
  };

  return (
    <div className="sub-main" style={mainContainerProperties}>
      {!isLoadingCampaigns && !isCreatingCampaign && (
        <div className="top-div-text">
          <h1 className="campaign-top-text" style={topTextProperties}>Campanhas</h1>
          <button
            className="new-campaign-button"
            onClick={handleCreateCampaign}
            disabled={isCreatingCampaign}
          >
            Nova Campanha
          </button>
        </div>
      )}
      {isLoadingCampaigns && (
        <div className="loading-main">
          <div className="loading-circle"></div>
        </div>
      )}

      {isCreatingCampaign && !isErrorCreateCampaign && (
        <div className="loading-main">
          <div className="loading-circle"></div>
          <h1> Criando campanha </h1>
        </div>
      )}

      {isErrorCreateCampaign && (
        <div className="loading-main">
          <BiSolidError className="error-icon-dashboard" />
          <h1 className="error-create-campaign"> Erro ao criar campanha! </h1>
          <button
            className="back-to-dashboard"
            onClick={() => redirectToDashboard()}
          >
            Voltar para o dashboard
          </button>
        </div>
      )}

      <div className="campaign-container">
        {isPanelVisible && (
          <div className="panel">
            <label htmlFor="image">Imagem</label>
            <input
              type="file"
              className="select-campaignImage-button"
              onChange={handleImageChange}
            />
            <label htmlFor="title">Título</label>
            <input
              type="text"
              className="input-field"
              placeholder="Título"
              value={title}
              onChange={handleTitleChange}
            />
            <label htmlFor="description">Descrição</label>
            <textarea
              type="text"
              className="input-field-description"
              placeholder="Descrição"
              value={description}
              onChange={handleDescriptionChange}
            />
            <label htmlFor="quantity">Quantidade de cotas</label>
            <input
              type="number"
              className="input-field"
              placeholder="Quantidade de cotas"
              value={shares}
              onChange={handleQuantityChange}
            ></input>
            <label htmlFor="price">Preço da cota</label>
            <input
              type="number"
              step="0.01"
              className="input-field"
              placeholder="Preço da Cota"
              value={priceShare}
              onChange={handlePriceChange}
            />
            <label htmlFor="drawDate">Data do Sorteio</label>
            <input
              type="date"
              className="input-field"
              placeholder="Data do Sorteio"
              value={finish_date}
              onChange={handleDrawDateChange}
            />

            <div>
              {editingCampaign && (
                <button className="cancel-button" onClick={handleCancelEdit}>
                  Cancelar
                </button>
              )}
              {!editingCampaign && (
                <button className="cancel-button" onClick={handleCancelCreate}>
                  Cancelar
                </button>
              )}
              <button className="clear-button" onClick={handleClearForm}>
                Limpar
              </button>
              <button
                className="conclude-button"
                onClick={handleCreateAndClosePanel}
              >
                {editingCampaign ? "Atualizar" : "Concluir"}
              </button>
            </div>
          </div>
        )}

        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <div className="campaign-list">
          {/*<h2>Campanhas Criadas</h2>*/}
          <div className="campaign-grid">
            {campaigns.map((campaign, index) => (
              <CampaignDetails
                key={index}
                campaign={campaign}
                onEdit={redirectWithIdForEdit}
                onDelete={handleDeleteCampaign}
                onRedirect={redirectWithId}
                imageLoading={isImageCampaignLoading}
                campaignPercent={campaignPercent}
              />
            ))}
          </div>
        </div>

        {isDeleteConfirmationVisible && (
          <div className="confirmation-panel-main">
            <div className="confirmation-panel">
              <p className="confirmation-panel-text">
                Deseja realmente excluir a campanha?
              </p>
              <div>
                <button className="cancel-button" onClick={handleCancelDelete}>
                  Cancelar
                </button>
                <button
                  className="confirm-button"
                  onClick={handleConfirmDelete}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FloatingPanel;