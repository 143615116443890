import { React, useState, useRef, useEffect } from "react";
import config from "../../../config";
import Modal from "react-modal";
import { FaPen } from "react-icons/fa";
import "./Customization.css";
import ErrorPanel from "../../../components/utils/ErrorPanel";

const Customization = () => {
  const [name, setName] = useState("");
  const [changeNameSuccess, setChangeNameSuccess] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [nameErrorText, setNameErrorText] = useState("");

  const getName = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/getSiteName.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      if (!response.ok) {
        throw new Error("Falha ao obter o nome do site");
      }

      const data = await response.json();
      if (data.status === "success" && data.value != null) {
        setName(data.value);
        // return data.value;
      } else {
        setNameErrorText("Erro ao obter nome do site");
        setNameError(true);
        setTimeout(() => {
          setNameError(false);
        }, 2000);
        throw new Error("Nome do site não disponível");
      }
    } catch (error) {
      setNameErrorText("Erro ao obter nome do site");
      setNameError(true);
      setTimeout(() => {
        setNameError(false);
      }, 2000);
      console.error("Erro ao obter o nome do site:", error);
      // return null;
    }
  };

  const updateName = async (newName) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      formData.append("name", newName);
      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/setSiteName.php`;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log(newName);
        console.log(name);
        setName(newName);
        setChangeNameSuccess(true);
        setTimeout(() => {
          setChangeNameSuccess(false);
        }, 2000);
      } else {
        setNameErrorText("Erro ao alterar o nome do site");
        setNameError(true);
        setTimeout(() => {
          setNameError(false);
        }, 2000);
      }
    } catch (error) {
      setNameErrorText("Erro ao alterar o nome do site");
      setNameError(true);
      setTimeout(() => {
        setNameError(false);
      }, 2000);
      console.error(error);
    }
  };

  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [isImageLoading, setImageLoading] = useState(true);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [changeIconSuccess, setChangeIconSuccess] = useState(false);
  const [iconError, setIconError] = useState(false);
  const [iconErrorText, setIconErrorText] = useState("");

  const getIcon = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new URLSearchParams();
      formData.append("token", token);
      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/getSiteIcon.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );
      
      if (response.ok) {
        const imageData = await response.blob();
        const imageUrl = URL.createObjectURL(imageData);
        console.log("URL: " + imageUrl);
        setImage(imageUrl);
        setImageLoading(false);
        // return data.value;
      } else {
        setImageLoading(false);
        setIconErrorText("Erro ao obter icone");
        setIconError(true);
        setTimeout(() => {
          setIconError(false);
        }, 2000);
        throw new Error("icone não disponível");
      }
    } catch (error) {
      setImageLoading(false);
      setIconErrorText("Erro ao obter icone");
      setIconError(true);
      setTimeout(() => {
        setIconError(false);
      }, 2000);
      console.error("Erro ao obter icone:", error);
      // return null;
    }
  };

  const updateIcon = async (newIcon) => {
    try {
      const token = localStorage.getItem("accessToken");
      const formData = new FormData();

      formData.append("token", token);
      // Fetch para obter o blob original do URL temporário
      const r = await fetch(newIcon);
      const blob = await r.blob();

      // Cria um novo objeto File com o blob e informações do arquivo original
      const file = new File([blob], newIcon.name, {
        type: newIcon.type,
      });
      formData.append("icon", file);

      const backendDomain = config.backendDomain;
      const url = `${backendDomain}/backend/src/view/dashboard/userWorkFlow/generic/setSiteIcon.php`;

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error("Falha ao obter detalhes do servidor");
      }

      const data = await response.json();

      if (data.status === "success") {
        console.log(newIcon);
        console.log(image);
        /* const imageData = await data.blob();
        const imageUrl = URL.createObjectURL(imageData);
        console.log("URL: " + imageUrl);
        setImage(imageUrl); */
        setImageLoading(false);
        setChangeIconSuccess(true);
        setTimeout(() => {
          setChangeIconSuccess(false);
        }, 2000);
      } else {
        setIconErrorText("Erro ao alterar icone do site");
        setIconError(true);
        setTimeout(() => {
          setIconError(false);
        }, 2000);
      }
    } catch (error) {
      setIconErrorText("Erro ao alterar icone do site");
      setIconError(true);
      setTimeout(() => {
        setIconError(false);
      }, 2000);
      console.error(error);
    }
  };

  const openImageModal = () => {
    setImageModalOpen(true);
    setSelectedImageName("Selecione uma imagem");
    // setSelectedImageName("Selecione uma imagem");
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    /*setSelectedImageName("Selecione uma imagem");
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      image: null, // Limpa a imagem selecionada
    }));*/
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setSelectedImageName(selectedImage.name);
      setImage(URL.createObjectURL(selectedImage));
      /*setCampaignDetails((prevDetails) => ({
        ...prevDetails,
        image: selectedImage,
      }));*/
    } else {
      setSelectedImageName("Nenhuma imagem selecionada");
      /*setCampaignDetails((prevDetails) => ({
        ...prevDetails,
        image: null, // Limpa a imagem selecionada
      }));*/
      setImage(null);
    }
  };

  const handleImageUpload = async () => {
    closeImageModal();
    /* if (!campaignDetails.image) {
      // Se nenhuma imagem foi selecionada, exibir uma mensagem na tela
      alert("Por favor, selecione uma imagem antes de enviar.");
      return;
    }

    const token = localStorage.getItem("accessToken");

    try {
      const formData = new FormData();
      setImageLoading(true);
      formData.append("image", campaignDetails.image, "image.png");
      formData.append("campaing_id", String(idFromUrl));
      formData.append("image_id", String(1));
      formData.append("token", String(token));

      const response = await fetch(
        `${config.backendDomain}/backend/src/view/dashboard/campaing/sendImageCampaing.php`,
        {
          method: "POST",
          body: formData,
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        closeImageModal();

        if (responseData.status === "success") {
          // fetchCampaignDetails();
          setIsBlocking(true);
          console.log("Imagem da campanha enviada com sucesso!");
        } else {
          console.error("Erro ao enviar imagem da campanha", response.status);
          setImageLoading(false);

          switch (responseData.code) {
            case "0x0001":
              setErrorText("Token invalido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0002":
              setErrorText("O id da campanha é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0003":
              setErrorText("O id da campanha é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0004":
              setErrorText("O id da imagem é nulo");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0005":
              setErrorText("O id da imagem é muito grande");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            case "0x0006":
              setErrorText("Erro ao enviar imagem");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
            default:
              setErrorText("Erro desconhecido");
              setError(true);
              setIsBlocking(false);
              setTimeout(() => {
                setError(false);
              }, 2000); // 2 segundos
              break;
          }
        }
      } else {
        setErrorText("Erro ao chamar o endpoint de envio de imagem");
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 2000); // 2 segundos
        console.error(
          "Erro ao chamar o endpoint de envio de imagem",
          response.status
        );
        setImageLoading(false);
      }
    } catch (error) {
      console.error("Erro ao enviar a imagem da campanha:", error.message);
      setImageLoading(false);
      setErrorText("Erro ao enviar imagem");
      setError(true);
      setIsBlocking(false);
      setTimeout(() => {
        setError(false);
      }, 2000); // 2 segundos
    } finally {
      setImageModalOpen(false);
    } */
  };

  const handleSave = async () => {
    await updateName(name);
    await updateIcon(image);
  };

  useEffect(() => {
    getName();
    getIcon();
  }, []);

  return (
    <div className="main-custom">
      {changeNameSuccess && (
        <ErrorPanel
          errorText={"Nome do site alterado com sucesso!"}
          backgroundColor={"#4caf08"}
        />
      )}
      {changeIconSuccess && (
        <ErrorPanel
          errorText={"Icone alterado com sucesso!"}
          backgroundColor={"#4caf08"}
        />
      )}
      {nameError && (
        <ErrorPanel errorText={nameErrorText} backgroundColor={"red"} />
      )}
      {iconError && (
        <ErrorPanel errorText={iconErrorText} backgroundColor={"red"} />
      )}
      <div className="title_text_main_custom">
        <h1>Personalização</h1>
      </div>
      <div className="main_container_custom">
        <div className="sub_container_1_custom">
          <label>Nome</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
        </div>
        <div className="sub_container_2_custom">
          <Modal
            isOpen={isImageModalOpen}
            onRequestClose={closeImageModal}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000, // Ajuste a ordem z conforme necessário
              },
              content: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px",
                color: "white",
                backgroundColor: "rgba(32, 32, 32, 1)",
                width: "425px",
                height: "75px",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "16px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "20px",
                zIndex: 1001, // Ajuste a ordem z conforme necessário (deve ser maior que a overlay)
              },
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              ref={inputRef} // Para acessar o input programaticamente
            />
            <button
              className="select-img"
              onClick={() => inputRef.current.click()} // Aciona o clique do input
            >
              Escolher imagem
            </button>
            <h4>{selectedImageName}</h4>
            <button className="upload-img" onClick={handleImageUpload}>
              Enviar Imagem
            </button>
          </Modal>
          <div className="image-main-">
            <label>Imagem</label>
            <div className="image-container">
              {isImageLoading ? (
                <div className="loading-image-circle"></div>
              ) : (
                <>
                  <img
                    src={image}
                    className={`campaign-img ${!image ? "no-image" : ""}`}
                  />
                  <div className="pen-img-icon">
                    <FaPen className="pen-icon" onClick={openImageModal} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="save_button_container">
          <button onClick={() => handleSave()}>salvar</button>
        </div>
      </div>
    </div>
  );
};

export default Customization;
